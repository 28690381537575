import UsersPage from '@/pages/users'
import UserPage from '@/pages/user'
export const route = {
    path: '/users',
    meta: {
        permission: 'users'
    },
    children: [
        {
            path: '',
            name: 'usersPage',
            component: UsersPage
        },
        {
            path: ':id',
            name: 'userPage',
            component: UserPage
        }
    ]
}
