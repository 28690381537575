<script setup>
/**
 * BaseCheckBox
 * ------------------------------
 * Description:
 * A checkbox component that can be checked or unchecked.
 *
 * Usage:
 * <BaseCheckBox
 *    :value="true"
 *    :checked="false"
 * />
 *
 * Props:
 * - value: The value of the checkbox (req).
 * - checked: Whether the checkbox is checked or not (req).
 *
 * Events:
 * None
 */
import {defineProps, defineEmits} from 'vue'

const emits = defineEmits(['checked'])

const props = defineProps({
    value: {
        type: [Object, Boolean],
        required: true
    },
    checked: {
        type: Boolean,
        required: true
    },
    design: {
        type: String
    }
})

const check = () => {
    emits('checked', props.value)
}
</script>

<template>
    <div class="check-box" :class="[design, { checked: checked }]" @click.stop="check()"></div>
</template>

<style scoped>
.check-box {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: var(--general-border) solid var(--primary-white);
    position: relative;
    cursor: pointer;
}

.check-box.checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 7px;
    background-image: url('@/shared/assets/checked-arrow.svg');
}

.check-box.checked.fill {
    background-color: var(--primary-white);
}

.check-box.checked.fill::after {
    background-image: none;
    height: 2px;
    background-color: var(--gray-800);
}
</style>
