<script setup>
import {computed, onMounted, shallowRef} from 'vue'

import config from '@/pages/brand/configs/brand.config'

const {tabs} = config.config()

import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseBack} from '@/shared/ui/Actions'
import {BaseTabViewer, BaseTabViewerItem} from '@/shared/ui/Tabs'
import {SinglePageTitle} from '@/shared/ui/Page'

import {ModalModel} from '@/entities/Modal'
import {BrandsModel} from '@/entities/Brands'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {BaseModal} from "@/shared/ui/Modal";
import {BaseButton} from "@/shared/ui/Buttons";

const modalStore = ModalModel.useModalStore()
const store = BrandsModel.useBrandsStore()

const brand = computed(() => BrandsModel.getBrand(store))

const availableActionPartners = ['affiliates', 'paymentPlan', 'restrictions']
const actionAvailable = computed(() => availableActionPartners.includes(activeTab.value.key))
let title

if (brand.value) {
    title = usePageTitle(brand.value['name'].data)
}

const activeTab = shallowRef(tabs[0])
const setActiveTab = (tab) => {
    activeTab.value = tab
}

const makeAction = () => {
    switch (activeTab.value.key) {
        case 'affiliates':
            modalStore.setModal('createAffiliate')
            break
        case 'paymentPlan':
            modalStore.setModal('createPlan')
            break
        case 'restrictions':
            break
        default:
            break
    }
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">
                {{brand ? brand.name.data : ''}}
                <BaseButton v-if="actionAvailable" :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="makeAction" />
            </h4>
        </template>
        <template #content>
            <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab"/>
            <BaseTabViewer>
                <template #content>
                    <BaseTabViewerItem :load="!!brand" :component="activeTab.component"/>
                </template>
            </BaseTabViewer>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.tabs {
    justify-content: space-between;
}

@media (max-width: 1000px) {
    button {
        display: none;
    }
}
</style>
