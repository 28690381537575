import {defineStore} from 'pinia'
import RestService from '@/shared/api/rest.service'
import {OtpModel} from '@/entities/Otp'

import axios from 'axios'
import {SessionModel} from '@/entities/Session'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {UserModel} from '@/entities/User'
import {useAppStore} from '@/app/providers'

export const useAuthorizationStore = defineStore('authorization', {
    state: () => ({
        user: {},
        token: null,
        loginState: false
    }),
    actions: {
        setUserData(data) {
            this.user = data
        },

        loginLogic(res) {
            const userStore = UserModel.useUserStore()
            const optStore = OtpModel.useOtpStore()
            const appStore = useAppStore()
            const permissions = res.data.data.permissions

            this.loginState = true

            if (localStorage.getItem('affiliate_token')) {
                this.token = localStorage.getItem('affiliate_token')
            } else {
                this.token = res.data.data.jwt_token
                localStorage.setItem('token', res.data.data.jwt_token)
                localStorage.setItem('id', res.data.data.id)
            }

            axios.defaults.headers.common['Authorization'] = this.token
            userStore.setUserData(res.data.data)

            appStore.getList('permissions')
            appStore.getList('brands')
            appStore.getList('sources')
            appStore.getList('partners')
            appStore.getList('connectors')
            appStore.getList('users')

            if (permissions && permissions.some(permission => permission === 'statuses')) {
                appStore.getList('statuses')
            }

            if (!res.data.data.otp) {
                optStore.createOtp()
            }

            userStore.getSelf()
            // this.getDeposits()
            //     .catch(() => {})
            // setInterval(() => {
            //     this.getDeposits()
            //         .catch(() => {})
            // }, 60000)
        },

        getDeposits() {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sessionStore = SessionModel.useSessionStore()

            const notificationSound = new Audio('notification-sound.mp3')

            return new Promise((resolve, reject) => {
                RestService.get('deposits')
                    .then(ans => {
                        const depositFound = sessionStore.timeDepositsFound
                        const depositFoundFromServer = ans.data.data.timeDepositFound

                        let unequalCount = 0

                        for (let i = 0; i < depositFound.length; i++) {
                            const item = depositFound[i]
                            const match = depositFoundFromServer.some(itemServer => itemServer === item)
                            if (!match) {
                                unequalCount++
                            }
                        }

                        if (!depositFound.length) {
                            sessionStore.setTimeDepositFound(depositFoundFromServer)
                        }

                        if (unequalCount && depositFound.length) {
                            generalNotificationsStore.showSuccess({
                                message: `New deposit has come in`
                            })
                            notificationSound.play()
                                .catch(error => console.error('Audio playback failed:', error))
                            sessionStore.setTimeDepositFound(depositFoundFromServer)
                        }
                        resolve()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        },

        login(data) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sessionStore = SessionModel.useSessionStore()
            const userStore = UserModel.useUserStore()

            return new Promise((resolve, reject) => {
                RestService.post(`user/login`, data)
                    .then((res) => {
                        if (res) {
                            userStore.setUserData(res.data.data)
                            this.loginLogic(res)
                            resolve(res.data.data)
                        }
                    })
                    .catch((errorObject) => {
                        this.reset()
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
                    .finally(() => {
                        sessionStore.setPageState(true)
                    })
            })
        },

        autoLogin() {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sessionStore = SessionModel.useSessionStore()
            const affToken = localStorage.getItem('affiliate_token')
            sessionStore.setPageState(false)

            return new Promise((resolve, reject) => {
                RestService.post(`user/auth`, localStorage.getItem('token'), {
                    headers: {
                        Authorization: affToken || localStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        if (res) {
                            sessionStore.setPageState(true)
                            this.loginLogic(res)
                            resolve(res.data.data)
                        }
                    })
                    .catch((errorObject) => {
                        this.logout()
                        generalNotificationsStore.showError(errorObject)
                    })
                    .finally(() => {
                        sessionStore.setPageState(true)
                    })
            })
        },

        reset() {
            this.loginState = false
            this.token = null
            this.setUserData({})
        },

        leaveFieldsFromLS() {
            const keepField = ['token', 'id']

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                if (!keepField.includes(key)) {
                    localStorage.removeItem(key)
                }
            }
        },

        logout(fullReset = true) {
            if (fullReset) {
                localStorage.clear()
                this.reset()
            } else {
                this.leaveFieldsFromLS()
                this.reset()
            }
            this.$router.push({name: 'loginPage'})
        }
    }
})
