<script setup>
import { BaseButton } from '@/shared/ui/Buttons'
import { BaseSelectBox } from '@/shared/ui/Inputs'
import { computed, ref } from 'vue'
import { SessionModel } from '@/entities/Session'
import { useAppStore } from '@/app/providers'
import { BaseModal } from '@/shared/ui/Modal'
import { ModalModel } from '@/entities/Modal'

import { SourcesModel } from '@/entities/Sources'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const store = SourcesModel.useSourcesStore()

const source = computed(() => store.source)
const users = computed(() => SessionModel.getUsers(sessionStore))

const userIdCreated = ref(source.value['userIdCreated'] || '')
const type = ref(source.value['type'] || '')

const close = () => {
    modalStore.closeModal()
}

const save = () => {
    appStore
        .update(
            {
                userIdCreated: userIdCreated.value,
                type: type.value
            },
            source.value,
            'sources-table'
        )
        .then(() => {
            generalNotificationsStore.showSuccess({
                message: 'You’ve changed your settings successfully'
            })
        })
}

const cancel = () => {
    close()
}
</script>

<template>
    <BaseModal :title="'Edit Souce'" >
        <template #content>
            <div class="edit-source">
                <div class="item">
                    <BaseSelectBox
                        v-model="userIdCreated"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :placeholder="users[userIdCreated] || 'Select Owner'"
                        :label="'Owner'"
                    />
                </div>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :design="'outline'" @click="cancel()" />
            <BaseButton :text="'Save'" @click="save()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.filters {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    place-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
}

.time-zone .item {
    display: flex;
    align-items: center;
    gap: 30px;
}

.item {
    width: 100%;
}

.activity .item {
    display: grid;
    place-items: center;
    grid-template-columns: 80px repeat(2, max-content);
    gap: 30px;
    margin-bottom: 20px;
}

.activity .item:last-child {
    margin-bottom: 0;
}

.activity .item .day {
    text-align: left;
    width: 100%;
}

.switch {
    display: flex;
    align-items: center;
    gap: 10px;
}

.switch-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-box,
.select {
    width: 100%;
}
</style>
