import { defineStore } from 'pinia'

import { api } from '../api'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: {},
        loginState: false
    }),
    actions: {
        setUserData(data) {
            this.user = data
        },

        getSelf() {
            return new Promise((resolve, reject) => {
                api.getSelf().then((ans) => {
                    this.setUserData(ans.data.data)
                    resolve()
                })
            })
        },

        selfUpdate(id, data) {
            return new Promise((resolve, reject) => {
                api.selfUpdate({
                    id: id,
                    data: data
                }).then((ans) => {
                    this.setUserData(ans.data.data)
                    resolve()
                })
            })
        }
    }
})
