<script setup>
import {ref, onMounted, defineProps, computed} from 'vue'
import Chart from 'chart.js/auto'
import {timeFormat} from "@/shared/lib/utils/Date/format"

const props = defineProps({
    data: {
        type: Object
    },
    config: {
        type: Object
    },
    period: {
        type: String
    },
    offset: {
        type: Number
    }
})

const chartCanvas = ref(null)
const chartInstance = ref(null)
const formatTime = (timestamp) => {
    const date = new Date(timestamp * 1000)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(2)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    if (props.period && (props.period.toLowerCase() === 'today' || props.period.toLowerCase() === 'yesterday')) {
        return `${hours}:${minutes} ${day}/${month}/${year}`
    }

    return `${day}/${month}/${year}`
}

const labels = ref(props.data ? props.data.map((obj) => formatTime(obj?.time) || 0) : [])

const chartData = ref({
    pieData: {
        labels: labels.value,
        datasets: [
            {
                label: 'Leads',
                data: props.data ? props.data.map((obj) => obj.leads) : [0],
                fill: false,
                borderColor: '#7075E7',
                backgroundColor: '#7075E7',
                tension: 0.1
            },
            {
                label: 'FTD’s',
                data: props.data ? props.data.map((obj) => obj.ftds) : [0],
                fill: false,
                borderColor: '#B659FF',
                backgroundColor: '#B659FF',
                tension: 0.1
            }
        ]
    },
    pieConfig: {
        maintainAspectRatio: false,
        interaction: {
            intersect: false
        },
        title: {
            enabled: false
        },
        elements: {
            point:{
                radius: 0
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                displayColors: false,
                backgroundColor: 'rgba(9, 7, 21, 0.5)'
            },
            legend: {
                display: true,
                labels: {
                    color: '#bbbbbb',
                    font: {
                        weight: 'bold',
                        size: 13,
                        textAlign: 'center'
                    },
                    padding: 25
                },
                onHover: (event) => {
                    event.native.target.style.cursor = 'pointer'
                }
            }
        },
        scales: {
            x: {
                stepSize: 3,
                border: {
                    display: true,
                    color: '#434343'
                },
                grid: {
                    display: true,
                    color: '#434343',
                },
                ticks: {
                    maxTicksLimit: 9,
                    maxRotation: 0,
                    minRotation: 0,
                    color: '#BBBBBB',
                    font: {
                        family: 'Montserrat',
                        align: 'start',
                        size: 13,
                        color: '#BBBBBB'
                    }
                }
            },
            y: {
                beginAtZero: true,
                border: {
                    display: true,
                    color: '#434343'
                },
                grid: {
                    display: true,
                    tickLength: 0,
                    tickWidth: 0,
                    color: '#434343'
                },
                ticks: {
                    font: {
                        family: 'Montserrat',
                        size: 13,
                        color: '#BBBBBB'
                    },
                    maxTicksLimit: 8,
                    color: '#BBBBBB',
                    stepSize: 1
                }
            }
        }
    }
})

const plugin = {
    beforeInit(chart) {
        const origFit = chart.legend.fit
        chart.legend.fit = function fit() {
            origFit.bind(chart.legend)()
            this.height += 20
        }
    }
}

onMounted(() => {
    const ctx = chartCanvas.value.getContext('2d')
    chartInstance.value = new Chart(ctx, {
        type: 'line',
        data: chartData.value.pieData,
        options: chartData.value.pieConfig,
        plugins: [plugin]
    })
})
</script>

<template>
    <div class="line-chart">
        <canvas ref="chartCanvas"/>
    </div>
</template>

<style scoped>
.line-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 400px;
    padding: 10px;
    width: 100%;
}
</style>
