import RestService from '@/shared/api/rest.service'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

const getDashboardData = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    // Define the parameters object with the granularity
    const params = { ...data }

    return new Promise((resolve, reject) => {
        RestService.get('reports/dashboard', { params }) // Pass the params object here
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                generalNotificationsStore.showError(error)
                reject(error)
            })
    })
}

export const api = {
    getDashboardData
}
