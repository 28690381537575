import { computed } from 'vue'
import { SessionModel } from '@/entities/Session'
import {countries, countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'deposits-table',
            pathDt: 'leads/dt',
            initOrder: 'created',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Register Date',
                    key: 'created',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Found Date',
                    key: 'found',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Report Date',
                    key: 'report',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Brands',
                    key: 'brandId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Ai',
                    key: 'ai',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Affiliate',
                    key: 'partnerId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Unique ID',
                    key: 'uniqueId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Customer ID',
                    key: 'customerId',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country',
                    key: 'country',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return countriesList()[elData]
                    }
                },
                {
                    label: 'Call Status',
                    key: 'callStatus',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Conversation Date',
                    key: 'conversation',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Call Status Map',
                    key: 'callStatusMapped',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Actions',
                    key: 'action',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Deposit Status',
                    key: 'status',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'timeCreate',
                    placeholder: 'Select Time Range',
                    type: 'time',
                },
                {
                    field: 'country',
                    placeholder: 'Select Countries',
                    type: 'multiselect',
                    data: countriesList()
                },
                {
                    field: 'brandId',
                    placeholder: 'Select Brands',
                    type: 'multiselect',
                    data: sessionStore.brands || []
                },
                {
                    field: 'partnerId',
                    placeholder: 'Select Affiliates',
                    type: 'multiselect',
                    data: sessionStore.partners || []
                },
            ]),
            searchByList: computed(() => [
                {
                    key: 'name',
                    value: 'Name'
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'delete_lead',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [{ key: 'delete_mass_users', label: 'Delete' }]
                }
            ]),
            filterBy: computed(() => ({
                field: 'type',
                data: 1
            }))
        }
    }
}
