import PartnersPage from '@/pages/partners'
export const route = {
    path: '/partners',
    children: [
        {
            path: '',
            name: 'partnersPage',
            component: PartnersPage
        }
    ],
    meta: {
        permission: 'partners'
    }
}
