import {defineStore} from 'pinia'
import {AuthorizationModel} from '@/features/Authorization'

import {api} from '@/entities/Users/api'
import user from "@/pages/user"

export const useUsersStore = defineStore('users', {
    state: () => ({
        user: null
    }),
    actions: {
        setUser(data) {
            this.user = data
        },

        getUser(id) {
            return new Promise((resolve, reject) => {
                api.getUser(id).then((ans) => {
                    this.user = {}
                    for (const item in ans.data.data) {
                        this.user[item] = {}
                        this.user[item].data = ans.data.data[item]
                    }
                    resolve()
                })
            })
        },

        update(id, data) {
            return new Promise((resolve, reject) => {
                api.updateUser({
                    id: id,
                    data: data
                }).then(() => {
                    for (const item in data) {
                        this.user[item].data = data[item]
                    }
                    resolve()
                })
            })
        },

        getTokenByUser(id) {
            const authStore = AuthorizationModel.useAuthorizationStore()
            return new Promise((resolve, reject) => {
                api.getTokenByUser(id).then((ans) => {
                    authStore.logout(false)
                    localStorage.setItem('affiliate_token', ans.data.data.jwt_token)
                    resolve()
                })
            })
        }
    }
})
