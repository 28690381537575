import {defineStore} from 'pinia'
import {api} from '../api'

export const useDashboardStore = defineStore('dashboard', {
    state: () => ({
        dashboardData: {},
        config: null
    }),

    actions: {
        setDashboardData(data) {
            this.dashboardData = data
        },

        setConfig(data) {
            this.config = data
            this.getDashboardData()
        },

        getDashboardData() {
            this.dashboardData = {}
            return new Promise((resolve, reject) => {
                api.getDashboardData(this.config)
                    .then((ans) => {
                        this.setDashboardData(ans.data.data)
                        resolve(ans)
                    })
            })
        }
    }
})
