<script setup>
import usersPageConfig from '@/pages/users/configs/users-config'

import { useRouter } from 'vue-router'

import { ModalModel } from '@/entities/Modal'

import { DataTable } from '@/features/Datatable'
import { PaginationBox, TableActions } from '@/features/Datatable'
import { PageTitle } from '@/shared/ui/Page'
import { UsersModel } from '@/entities/Users'
import { BaseButton } from '@/shared/ui/Buttons'

import { usePageTitle } from '@/shared/lib/use/usePageTitle'

const { cols, searchByList, actionList, tableActions, filters, tableId, pathDt, initOrder } = usersPageConfig.config()

const modalStore = ModalModel.useModalStore()
const usersStore = UsersModel.useUsersStore()

const router = useRouter()
const title = usePageTitle('Users')

const openUser = (item) => {
    usersStore.setUser(item)
    router.push({ name: 'userPage', params: { id: item.id.data } })
}

const create = () => {
    modalStore.setModal('createUser')
}
</script>

<template>
    <div class="leads-page container">
        <div class="content">
            <PageTitle :title="'Users'" :icon="'page-icons/users.svg'">
                <template #custom-action>
                    <BaseButton :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="create()" />
                </template>
            </PageTitle>
            <DataTable
                :fields="cols"
                :is-draggable="true"
                :path="pathDt"
                :table-id="tableId"
                :order="initOrder"
                :option-list="tableActions"
                :action-list="actionList"
                @open="openUser"
            >
                <template #center-right>
                    <TableActions :search-list="searchByList" />
                </template>
                <template #bottom-left>
                    <PaginationBox />
                </template>
            </DataTable>
        </div>
    </div>
</template>

<style scoped></style>
