import SourcesPage from '@/pages/sources'
import SourcePage from '@/pages/source'
import CreateCountrySourcePage from '@/pages/create-country-source'
export const route = {
    path: '/sources',
    meta: {
        permission: 'sources'
    },
    children: [
        {
            path: '',
            name: 'sourcesPage',
            component: SourcesPage
        },
        {
            path: 'create',
            name: 'createCountrySourcePage',
            component: CreateCountrySourcePage
        }
    ]
}
