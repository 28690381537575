import { defineStore } from 'pinia'
import { headerLinks } from './headerLinks'

export const useHeaderStore = defineStore('header', {
    state: () => ({
        headerLinks: headerLinks,
        homeUrlName: 'dashboardPage',
        topBarIsVisible: true
    }),
    actions: {
        setTopBarVisibility(data) {
            this.topBarIsVisible = data
        }
    }
})
