<script setup>
import { computed, ref } from 'vue'
import { BaseButton } from '@/shared/ui/Buttons'
import { BaseTextarea } from '@/shared/ui/Inputs'

import { ModalModel } from '@/entities/Modal'
import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import {BrandsModel} from '@/entities/Brands'

import { useAppStore } from '@/app/providers'
import { BaseSelectBox } from '@/shared/ui/Inputs'
import { BaseModal } from '@/shared/ui/Modal'

const modalStore = ModalModel.useModalStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const appStore = useAppStore()
const brandsStore = BrandsModel.useBrandsStore()

const id = computed(() => BrandsModel.getBrand(brandsStore).id.data)
const partners = computed(() => SessionModel.getPartners(sessionStore))
const requestIsPending = computed(() => appStore.requestIsPending)

const note = ref('')
const partnerId = ref('')
const create = () => {
    if (!partnerId.value) {
        generalNotificationsStore.showError({
            message: 'Select Affiliate'
        })
        return
    }

    appStore.create(
        {
            partnerId: partnerId.value,
            status: 1,
            note: note.value
        },
        `brand/${id.value}/affiliates`
    )
}

const cancel = () => {
    close()
}

const close = () => {
    modalStore.closeModal()
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Create new affiliate</h4>
        </template>
        <template #content>
            <div class="create-affiliate">
                <BaseSelectBox
                    v-model="partnerId"
                    :options="
                        Object.entries(partners).map(([key, value]) => ({
                            value: key,
                            title: value
                        }))
                    "
                    :placeholder="'Select affilate'"
                    :label="'Connector'"
                />
                <BaseTextarea v-model="note" :label="'Note'" :placeholder="'Enter notes'" :limit="100" :height="105" />
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()" />
            <BaseButton :text="'Create'" :width="100" :load="requestIsPending" @click="create()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.create-affiliate {
    width: 100%;
    background-color: var(--background);
    border-radius: var(--general-border-radius);
    display: flex;
    gap: 16px;
    flex-direction: column;
}

h4 {
    padding: 9px 0;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 54px;
    width: 100%;
    gap: 20px;
}

.select {
    width: 100% !important;
}
</style>
