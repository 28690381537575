<script setup>
import { computed, ref } from 'vue'

import { BaseButton } from '@/shared/ui/Buttons'
import { BaseInput } from '@/shared/ui/Inputs'

import { ModalModel } from '@/entities/Modal'
import { useAppStore } from '@/app/providers'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import { BaseModal } from '@/shared/ui/Modal'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const requestIsPending = computed(() => appStore.requestIsPending)

const source = ref('')

const create = () => {
    if (!source.value) {
        generalNotificationsStore.showError({
            message: 'Please enter a source name'
        })
        return
    }

    appStore.create(
        {
            name: source.value,
            type: 1,
            favorite: 0
        },
        'sources'
    )
}

const cancel = () => {
    close()
}

const close = () => {
    modalStore.closeModal()
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Create new source</h4>
        </template>
        <template #content>
            <div class="create-source">
                <BaseInput v-model="source" :placeholder="'Enter source name'" />
            </div>
        </template>

        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()" />
            <BaseButton :text="'Create'" :width="100" :load="requestIsPending" @click="create()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.input-box {
    width: 100%;
}
</style>
