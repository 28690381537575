import {  ignoredColsList, typesList } from '@/shared/data'
import { timeFormat } from './Date/format'
import XLSX from 'xlsx'
import {countriesList} from "@/shared/data/countriesList"

/**
 * Returns the single action type for a given table.
 * @param {string} table - The table identifier.
 * @returns {string|undefined} The single action type.
 */
export const getTableBySingleRequest = (table) => {
    switch (table) {
        case 'brands-table':
            return 'brand'
        case 'leads-table':
            return 'lead'
        case 'sources-table':
            return 'source'
        case 'rows-table':
            return 'row'
        case 'partners-table':
            return 'partner'
        case 'affiliate-tables':
            return 'affiliate'
        case 'partners-brands-table':
            return 'partners-brands'
        case 'users-table':
            return 'user'
        case 'statuses-table':
            return 'statuses'
        default:
            return ''
    }
}

/**
 * Returns the mass action type for a given table.
 * @param {string} table - The table identifier.
 * @returns {string} The mass action type.
 */
export const getTableByRequest = (table) => {
    switch (table) {
        case 'brands-table':
            return 'brands'
        case 'leads-table':
            return 'leads'
        case 'sources-table':
            return 'sources'
        case 'rows-table':
            return 'rows'
        case 'partners-table':
            return 'partners'
        case 'partners-brands-table':
            return 'partners-brands'
        case 'affiliate-tables':
            return 'affiliate'
        case 'users-table':
            return 'users'
        default:
            return
    }
}

/**
 * Returns the field name for storing IDs associated with a given table.
 * @param {string} table - The table identifier.
 * @returns {string|undefined} The field name for IDs.
 */
export const getIdsField = (table) => {
    switch (table) {
        case 'leads':
            return 'leadIds'
        case 'brands':
            return 'brandIds'
        case 'rows':
            return 'rowIds'
        case 'partners':
            return 'partnerIds'
        case 'affiliate':
        case 'partners-brands':
            return 'affiliateIds'
        case 'users':
            return 'userIds'
        default:
            return
    }
}

/**
 * Downloads datatable data as a CSV file.
 * @param {Array} tableData - The datatable data to be downloaded.
 * @param {string} tableId - The identifier for the datatable.
 * @param {string} [name = ''] - Optional name for the downloaded file.
 */
export const downloadDatatable = (tableData, tableId, name = '') => {
    const sheetsData = []

    for (const item in tableData) {
        let col = {},
            hiddenCols = tableData.filter((col) => !col.visible).map((el) => el.key)

        Object.keys(tableData[item]).forEach((el) => {
            if (
                !tableData[item][el] ||
                !tableData[item][el].label ||
                ignoredColsList.includes(el) ||
                hiddenCols.includes(el)
            ) {
                return
            }

            switch (el) {
                case 'countryDetected':
                case 'country':
                    col[tableData[item][el].label] = countriesList()[tableData[item][el].data]
                    break
                case 'type':
                    col[tableData[item][el].label] = typesList[tableData[item][el].data]
                    break
                case 'timeCreate':
                    col[tableData[item][el].label] = tableData[item][el].data
                    ? timeFormat(tableData[item][el].data, parseInt(localStorage.getItem('timeZone') || new Date().getTimezoneOffset()))
                        : ''
                    break
                default:
                    col[tableData[item][el].label] =
                        tableData[item][el].data !== undefined ? tableData[item][el].data : ''
                    break
            }
        })
        sheetsData.push(col)
    }

    const ws = XLSX.utils.json_to_sheet(sheetsData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Template')
    const csvContent = XLSX.write(wb, { bookType: 'csv', type: 'binary', encoding: 'UTF-8' })
    const bomRemovedCsvContent = csvContent.replaceAll('ï»¿', '')
    const blob = new Blob([bomRemovedCsvContent], { type: 'text/csvcharset=UTF-8-BOM' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    const fileName = name ? `${name}.csv` : `${tableId}.csv`

    link.href = url
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(url)
}
