import IntegrationTestPage from "@/pages/intergration-test"

export const route = {
    path: '/test-integration',
    name: 'integrationTest',
    component: IntegrationTestPage,
    meta: {
        permission: 'partners'
    }
}
