export const countries = {
    "BV": {
        "name": "Bouvet Island",
        "emoji": "🇧🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bv.svg",
        "phoneCode": ["+55"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "bouvet-island",
        "languages": ["English"],
        "alpha3": "BVI",
        "code": "BV"
    },
    "EC": {
        "name": "Ecuador",
        "emoji": "🇪🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ec.svg",
        "phoneCode": ["+593"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "ecuador",
        "languages": ["Spanish"],
        "alpha3": "ECU",
        "code": "EC"
    },
    "EG": {
        "name": "Egypt",
        "emoji": "🇪🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/eg.svg",
        "phoneCode": ["+20"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "egypt",
        "languages": ["Arabic"],
        "alpha3": "EGY",
        "code": "EG"
    },
    "SV": {
        "name": "El Salvador",
        "emoji": "🇸🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sv.svg",
        "phoneCode": ["+503"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "el-salvador",
        "languages": ["Spanish"],
        "alpha3": "SLV",
        "code": "SV"
    },
    "GQ": {
        "name": "Equatorial Guinea",
        "emoji": "🇬🇶",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gq.svg",
        "phoneCode": ["+240"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "equatorial-guinea",
        "languages": ["Spanish", "French", "Portuguese"],
        "alpha3": "GNQ",
        "code": "GQ"
    },
    "ER": {
        "name": "Eritrea",
        "emoji": "🇪🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/er.svg",
        "phoneCode": ["+291"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "eritrea",
        "languages": ["Tigrinya", "Arabic", "English"],
        "alpha3": "ERI",
        "code": "ER"
    },
    "EE": {
        "name": "Estonia",
        "emoji": "🇪🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ee.svg",
        "phoneCode": ["+372"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "estonia",
        "languages": ["Estonian"],
        "alpha3": "EST",
        "code": "EE"
    },
    "SZ": {
        "name": "Eswatini",
        "emoji": "🇸🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sz.svg",
        "phoneCode": ["+268"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "eswatini",
        "languages": ["Swazi", "English"],
        "alpha3": "SWZ",
        "code": "SZ"
    },
    "ET": {
        "name": "Ethiopia",
        "emoji": "🇪🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/et.svg",
        "phoneCode": ["+251"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "ethiopia",
        "languages": ["Amharic", "Oromo", "Tigrinya", "Somali", "Arabic", "English"],
        "alpha3": "ETH",
        "code": "ET"
    },
    "FK": {
        "name": "Falkland Islands",
        "emoji": "🇫🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fk.svg",
        "phoneCode": ["+500"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "falkland-islands",
        "languages": ["English"],
        "alpha3": "FLK",
        "code": "FK"
    },
    "FO": {
        "name": "Faroe Islands",
        "emoji": "🇫🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fo.svg",
        "phoneCode": ["+298"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "faroe-islands",
        "languages": ["Faroese", "Danish"],
        "alpha3": "FRO",
        "code": "FO"
    },
    "FJ": {
        "name": "Fiji",
        "emoji": "🇫🇯",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fj.svg",
        "phoneCode": ["+679"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "fiji",
        "languages": ["English", "Fijian", "Hindi"],
        "alpha3": "FJI",
        "code": "FJ"
    },
    "FI": {
        "name": "Finland",
        "emoji": "🇫🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fi.svg",
        "phoneCode": ["+358"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "finland",
        "languages": ["Finnish", "Swedish"],
        "alpha3": "FIN",
        "code": "FI"
    },
    "FR": {
        "name": "France",
        "emoji": "🇫🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fr.svg",
        "phoneCode": ["+33"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "france",
        "languages": ["French"],
        "alpha3": "FRA",
        "code": "FR"
    },
    "GF": {
        "name": "French Guiana",
        "emoji": "🇬🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gf.svg",
        "phoneCode": ["+594"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "french-guiana",
        "languages": ["French"],
        "alpha3": "GUF",
        "code": "GF"
    },
    "PF": {
        "name": "French Polynesia",
        "emoji": "🇵🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pf.svg",
        "phoneCode": ["+689"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "french-polynesia",
        "languages": ["French", "Tahitian"],
        "alpha3": "PYF",
        "code": "PF"
    },
    "TF": {
        "name": "French Southern Territories",
        "emoji": "🇹🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tf.svg",
        "phoneCode": ["+262"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "french-southern-territories",
        "languages": ["French"],
        "alpha3": "ATF",
        "code": "TF"
    },
    "HM": {
        "name": "Heard Island and McDonald Islands",
        "emoji": "🇭🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hm.svg",
        "phoneCode": [""],
        "mask": "",
        "phoneLimit": 0,
        "slug": "heard-island-and-mcdonald-islands",
        "languages": ["English"],
        "alpha3": "HMD",
        "code": "HM"
    },
    "LA": {
        "name": "Laos",
        "emoji": "🇱🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/la.svg",
        "phoneCode": ["+856"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "laos",
        "languages": ["Lao"],
        "alpha3": "LAO",
        "code": "LA"
    },
    "AF": {
        "name": "Afghanistan",
        "code": "AF",
        "emoji": "🇦🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/af.svg",
        "phoneCode": ["+93"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "afghanistan",
        "alpha3": "AFG",
        "languages": ["Pashto", "Dari"]
    },
    "AX": {
        "name": "Åland Islands",
        "code": "AX",
        "emoji": "🇦🇽",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/af.svg",
        "phoneCode": ["+358"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "aland-islands",
        "alpha3": "ALA",
        "languages": ["Swedish"]
    },
    "AL": {
        "name": "Albania",
        "code": "AL",
        "emoji": "🇦🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/al.svg",
        "phoneCode": ["+355"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "albania",
        "alpha3": "ALB",
        "languages": ["Albanian"]
    },
    "DZ": {
        "name": "Algeria",
        "code": "DZ",
        "emoji": "🇩🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/af.svg",
        "phoneCode": ["+213"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "algeria",
        "alpha3": "DZA",
        "languages": ["Arabic"]
    },
    "AS": {
        "name": "American Samoa",
        "code": "AS",
        "emoji": "🇦🇸",
        "image": "https://example.com/flags/as.svg",
        "phoneCode": ["+1684"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "american-samoa",
        "alpha3": "ASM",
        "languages": ["English", "Samoan"]
    },
    "AD": {
        "name": "Andorra",
        "code": "AD",
        "emoji": "🇦🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ad.svg",
        "phoneCode": ["+376"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "andorra",
        "alpha3": "AND",
        "languages": ["Catalan"]
    },
    "AO": {
        "name": "Angola",
        "code": "AO",
        "emoji": "🇦🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ao.svg",
        "phoneCode": ["+244"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "angola",
        "alpha3": "AGO",
        "languages": ["Portuguese"]
    },
    "AI": {
        "name": "Anguilla",
        "code": "AI",
        "emoji": "🇦🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ai.svg",
        "phoneCode": ["+1264"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "anguilla",
        "alpha3": "AIA",
        "languages": ["English"]
    },
    "AQ": {
        "name": "Antarctica",
        "code": "AQ",
        "emoji": "🇦🇶",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aq.svg",
        "phoneCode": ["+672"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "antarctica",
        "alpha3": "ATA",
        "languages": ["English"]
    },
    "AG": {
        "name": "Antigua & Barbuda",
        "code": "AG",
        "emoji": "🇦🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ag.svg",
        "phoneCode": ["+1268"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "antigua-and-barbuda",
        "alpha3": "ATG",
        "languages": ["English"]
    },
    "AR": {
        "name": "Argentina",
        "code": "AR",
        "emoji": "🇦🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ar.svg",
        "phoneCode": ["+54"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "argentina",
        "alpha3": "ARG",
        "languages": ["Spanish"]
    },
    "AM": {
        "name": "Armenia",
        "code": "AM",
        "emoji": "🇦🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/am.svg",
        "phoneCode": ["+374"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "armenia",
        "alpha3": "ARM",
        "languages": ["Armenian"]
    },
    "AW": {
        "name": "Aruba",
        "code": "AW",
        "emoji": "🇦🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aw.svg",
        "phoneCode": ["+297"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "aruba",
        "alpha3": "ABW",
        "languages": ["Dutch", "Papiamento"]
    },
    "AC": {
        "name": "Ascension Island",
        "code": "AC",
        "emoji": "🇦🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ac.svg",
        "mask": "",
        "slug": "ascension-island",
        "alpha3": "ASC",
        "languages": ["English"]
    },
    "AU": {
        "name": "Australia",
        "code": "AU",
        "emoji": "🇦🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/au.svg",
        "phoneCode": ["+61"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "australia",
        "alpha3": "AUS",
        "languages": ["English"]
    },
    "AT": {
        "name": "Austria",
        "code": "AT",
        "emoji": "🇦🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/at.svg",
        "phoneCode": ["+43"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "austria",
        "alpha3": "AUT",
        "languages": ["German"]
    },
    "AZ": {
        "name": "Azerbaijan",
        "code": "AZ",
        "emoji": "🇦🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/az.svg",
        "phoneCode": ["+994"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "azerbaijan",
        "alpha3": "AZE",
        "languages": ["Azerbaijani"]
    },
    "BS": {
        "name": "Bahamas",
        "code": "BS",
        "emoji": "🇧🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bs.svg",
        "phoneCode": ["+1242"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "bahamas",
        "alpha3": "BHS",
        "languages": ["English"]
    },
    "BH": {
        "name": "Bahrain",
        "code": "BH",
        "emoji": "🇧🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bh.svg",
        "phoneCode": ["+973"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "bahrain",
        "alpha3": "BHR",
        "languages": ["Arabic"]
    },
    "BD": {
        "name": "Bangladesh",
        "code": "BD",
        "emoji": "🇧🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bd.svg",
        "phoneCode": ["+880"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "bangladesh",
        "alpha3": "BGD",
        "languages": ["Bengali"]
    },
    "BB": {
        "name": "Barbados",
        "code": "BB",
        "emoji": "🇧🇧",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bb.svg",
        "phoneCode": ["+1246"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "barbados",
        "alpha3": "BRB",
        "languages": ["English"]
    },
    "BY": {
        "name": "Belarus",
        "code": "BY",
        "emoji": "🇧🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/by.svg",
        "phoneCode": ["+375"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "belarus",
        "alpha3": "BLR",
        "languages": ["Belarusian", "Russian"]
    },
    "BE": {
        "name": "Belgium",
        "code": "BE",
        "emoji": "🇧🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/be.svg",
        "phoneCode": ["+32"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "belgium",
        "alpha3": "BEL",
        "languages": ["Dutch", "French", "German"]
    },
    "BZ": {
        "name": "Belize",
        "code": "BZ",
        "emoji": "🇧🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bz.svg",
        "phoneCode": ["+501"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "belize",
        "alpha3": "BLZ",
        "languages": ["English"]
    },
    "BJ": {
        "name": "Benin",
        "code": "BJ",
        "emoji": "🇧🇯",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bj.svg",
        "phoneCode": ["+229"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "benin",
        "alpha3": "BEN",
        "languages": ["French"]
    },
    "BM": {
        "name": "Bermuda",
        "code": "BM",
        "emoji": "🇧🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bm.svg",
        "phoneCode": ["+1441"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "bermuda",
        "alpha3": "BMU",
        "languages": ["English"]
    },
    "BT": {
        "name": "Bhutan",
        "code": "BT",
        "emoji": "🇧🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bt.svg",
        "phoneCode": ["+975"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "bhutan",
        "alpha3": "BTN",
        "languages": ["Dzongkha"]
    },
    "BO": {
        "name": "Bolivia",
        "code": "BO",
        "emoji": "🇧🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bo.svg",
        "phoneCode": ["+591"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "bolivia",
        "alpha3": "BOL",
        "languages": ["Spanish"]
    },
    "BA": {
        "name": "Bosnia & Herzegovina",
        "code": "BA",
        "emoji": "🇧🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ba.svg",
        "phoneCode": ["+387"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "bosnia-and-herzegovina",
        "alpha3": "BIH",
        "languages": ["Bosnian", "Croatian", "Serbian"]
    },
    "BW": {
        "name": "Botswana",
        "code": "BW",
        "emoji": "🇧🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bw.svg",
        "phoneCode": ["+267"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "botswana",
        "alpha3": "BWA",
        "languages": ["English", "Tswana"]
    },
    "BR": {
        "name": "Brazil",
        "code": "BR",
        "emoji": "🇧🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/br.svg",
        "phoneCode": ["+55"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "brazil",
        "alpha3": "BRA",
        "languages": ["Portuguese"]
    },
    "IO": {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "emoji": "🇮🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/io.svg",
        "phoneCode": ["+246"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "british-indian-ocean-territory",
        "alpha3": "IOT",
        "languages": ["English"]
    },
    "VG": {
        "name": "British Virgin Islands",
        "code": "VG",
        "emoji": "🇻🇬",
        "image": "https://example.com/flags/vg.svg",
        "phoneCode": ["+1284"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "british-virgin-islands",
        "alpha3": "VGB",
        "languages": ["English"]
    },
    "BN": {
        "name": "Brunei",
        "code": "BN",
        "emoji": "🇧🇳",
        "image": "https://example.com/flags/bn.svg",
        "phoneCode": ["+673"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "brunei",
        "alpha3": "BRN",
        "languages": ["Malay"]
    },
    "BG": {
        "name": "Bulgaria",
        "code": "BG",
        "emoji": "🇧🇬",
        "image": "https://example.com/flags/bg.svg",
        "phoneCode": ["+359"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "bulgaria",
        "alpha3": "BGR",
        "languages": ["Bulgarian"]
    },
    "BF": {
        "name": "Burkina Faso",
        "code": "BF",
        "emoji": "🇧🇫",
        "image": "https://example.com/flags/bf.svg",
        "phoneCode": ["+226"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "burkina-faso",
        "alpha3": "BFA",
        "languages": ["French"]
    },
    "BI": {
        "name": "Burundi",
        "code": "BI",
        "emoji": "🇧🇮",
        "image": "https://example.com/flags/bi.svg",
        "phoneCode": ["+257"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "burundi",
        "alpha3": "BDI",
        "languages": ["Kirundi", "French"]
    },
    "KH": {
        "name": "Cambodia",
        "code": "KH",
        "emoji": "🇰🇭",
        "image": "https://example.com/flags/kh.svg",
        "phoneCode": ["+855"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "cambodia",
        "alpha3": "KHM",
        "languages": ["Khmer"]
    },
    "CM": {
        "name": "Cameroon",
        "code": "CM",
        "emoji": "🇨🇲",
        "image": "https://example.com/flags/cm.svg",
        "phoneCode": ["+237"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "cameroon",
        "alpha3": "CMR",
        "languages": ["French", "English"]
    },
    "CA": {
        "name": "Canada",
        "code": "CA",
        "emoji": "🇨🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ca.svg",
        "phoneCode": ["+1"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "canada",
        "alpha3": "CAN",
        "languages": ["English", "French"]
    },
    "IC": {
        "name": "Canary Islands",
        "code": "IC",
        "emoji": "🇮🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ic.svg",
        "phoneCode": ["+34"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "canary-islands",
        "alpha3": "ESP",
        "languages": ["Spanish"]
    },
    "CV": {
        "name": "Cape Verde",
        "code": "CV",
        "emoji": "🇨🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cv.svg",
        "phoneCode": ["+238"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "cape-verde",
        "alpha3": "CPV",
        "languages": ["Portuguese"]
    },
    "BQ": {
        "name": "Caribbean Netherlands",
        "code": "BQ",
        "emoji": "🇧🇶",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bq.svg",
        "phoneCode": ["+599"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "caribbean-netherlands",
        "alpha3": "BES",
        "languages": ["Dutch"]
    },
    "KY": {
        "name": "Cayman Islands",
        "code": "KY",
        "emoji": "🇰🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ky.svg",
        "phoneCode": ["+1345"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "cayman-islands",
        "alpha3": "CYM",
        "languages": ["English"]
    },
    "CF": {
        "name": "Central African Republic",
        "code": "CF",
        "emoji": "🇨🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cf.svg",
        "phoneCode": ["+236"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "central-african-republic",
        "alpha3": "CAF",
        "languages": ["French", "Sango"]
    },
    "EA": {
        "name": "Ceuta & Melilla",
        "code": "EA",
        "emoji": "🇪🇦",
        "image": "https://example.com/flags/ea.svg",
        "phoneCode": ["+34"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "ceuta-and-melilla",
        "alpha3": "ESP",
        "languages": ["Spanish"]
    },
    "TD": {
        "name": "Chad",
        "code": "TD",
        "emoji": "🇹🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/td.svg",
        "phoneCode": ["+235"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "chad",
        "alpha3": "TCD",
        "languages": ["French", "Arabic"]
    },
    "CL": {
        "name": "Chile",
        "code": "CL",
        "emoji": "🇨🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cl.svg",
        "phoneCode": ["+56"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "chile",
        "alpha3": "CHL",
        "languages": ["Spanish"]
    },
    "CN": {
        "name": "China",
        "code": "CN",
        "emoji": "🇨🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cn.svg",
        "phoneCode": ["+86"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "china",
        "alpha3": "CHN",
        "languages": ["Mandarin"]
    },
    "CX": {
        "name": "Christmas Island",
        "code": "CX",
        "emoji": "🇨🇽",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cx.svg",
        "phoneCode": ["+61"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "christmas-island",
        "alpha3": "CXR",
        "languages": ["English"]
    },
    "CC": {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "emoji": "🇨🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cc.svg",
        "phoneCode": ["+61"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "cocos-keeling-islands",
        "alpha3": "CCK",
        "languages": ["English"]
    },
    "CO": {
        "name": "Colombia",
        "code": "CO",
        "emoji": "🇨🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/co.svg",
        "phoneCode": ["+57"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "colombia",
        "alpha3": "COL",
        "languages": ["Spanish"]
    },
    "KM": {
        "name": "Comoros",
        "code": "KM",
        "emoji": "🇰🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/km.svg",
        "phoneCode": ["+269"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "comoros",
        "alpha3": "COM",
        "languages": ["Comorian", "Arabic", "French"]
    },
    "CD": {
        "name": "Congo - Kinshasa",
        "code": "CD",
        "emoji": "🇨🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cd.svg",
        "phoneCode": ["+243"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "congo-kinshasa",
        "alpha3": "COD",
        "languages": ["French"]
    },
    "CG": {
        "name": "Congo - Brazzaville",
        "code": "CG",
        "emoji": "🇨🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cg.svg",
        "phoneCode": ["+242"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "congo-brazzaville",
        "alpha3": "COG",
        "languages": ["French"]
    },
    "CK": {
        "name": "Cook Islands",
        "code": "CK",
        "emoji": "🇨🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ck.svg",
        "phoneCode": ["+682"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "cook-islands",
        "alpha3": "COK",
        "languages": ["English"]
    },
    "CR": {
        "name": "Costa Rica",
        "code": "CR",
        "emoji": "🇨🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cr.svg",
        "phoneCode": ["+506"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "costa-rica",
        "alpha3": "CRI",
        "languages": ["Spanish"]
    },
    "HR": {
        "name": "Croatia",
        "code": "HR",
        "emoji": "🇭🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hr.svg",
        "phoneCode": ["+385"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "croatia",
        "alpha3": "HRV",
        "languages": ["Croatian"]
    },
    "CU": {
        "name": "Cuba",
        "code": "CU",
        "emoji": "🇨🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cu.svg",
        "phoneCode": ["+53"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "cuba",
        "alpha3": "CUB",
        "languages": ["Spanish"]
    },
    "CW": {
        "name": "Curaçao",
        "code": "CW",
        "emoji": "🇨🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cw.svg",
        "phoneCode": ["+599"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "curaçao",
        "alpha3": "CUW",
        "languages": ["Dutch", "Papiamento"]
    },
    "CY": {
        "name": "Cyprus",
        "code": "CY",
        "emoji": "🇨🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cy.svg",
        "phoneCode": ["+357"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "cyprus",
        "alpha3": "CYP",
        "languages": ["Greek", "Turkish"]
    },
    "CZ": {
        "name": "Czech Republic",
        "code": "CZ",
        "emoji": "🇨🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cz.svg",
        "phoneCode": ["+420"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "czech-republic",
        "alpha3": "CZE",
        "languages": ["Czech"]
    },
    "CI": {
        "name": "Côte d’Ivoire",
        "code": "CI",
        "emoji": "🇨🇮",
        "image": "https://example.com/flags/ci.svg",
        "phoneCode": ["+225"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "cote-divoire",
        "alpha3": "CIV",
        "languages": ["French"]
    },
    "DK": {
        "name": "Denmark",
        "code": "DK",
        "emoji": "🇩🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dk.svg",
        "phoneCode": ["+45"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "denmark",
        "alpha3": "DNK",
        "languages": ["Danish"]
    },
    "DG": {
        "name": "Diego Garcia",
        "code": "DG",
        "emoji": "🇩🇬",
        "image": "https://example.com/flags/dg.svg",
        "phoneCode": ["+246"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "diego-garcia",
        "alpha3": "IOT",
        "languages": ["English"]
    },
    "DJ": {
        "name": "Djibouti",
        "code": "DJ",
        "emoji": "🇩🇯",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dj.svg",
        "phoneCode": ["+253"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "djibouti",
        "alpha3": "DJI",
        "languages": ["French", "Arabic"]
    },
    "DM": {
        "name": "Dominica",
        "code": "DM",
        "emoji": "🇩🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dm.svg",
        "phoneCode": ["+1767"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "dominica",
        "alpha3": "DMA",
        "languages": ["English"]
    },
    "DO": {
        "name": "Dominican Republic",
        "code": "DO",
        "emoji": "🇩🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/do.svg",
        "phoneCode": ["+1-809", "+1-829", "+1-849"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "dominican-republic",
        "alpha3": "DOM",
        "languages": ["Spanish"]
    },
    "GA": {
        "name": "Gabon",
        "code": "GA",
        "emoji": "🇬🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ga.svg",
        "phoneCode": ["+241"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "gabon",
        "alpha3": "GAB",
        "languages": ["French"]
    },
    "GM": {
        "name": "Gambia",
        "code": "GM",
        "emoji": "🇬🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gm.svg",
        "phoneCode": ["+220"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "gambia",
        "alpha3": "GMB",
        "languages": ["English"]
    },
    "GE": {
        "name": "Georgia",
        "code": "GE",
        "emoji": "🇬🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ge.svg",
        "phoneCode": ["+995"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "georgia",
        "alpha3": "GEO",
        "languages": ["Georgian"]
    },
    "DE": {
        "name": "Germany",
        "code": "DE",
        "emoji": "🇩🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/de.svg",
        "phoneCode": ["+49"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "germany",
        "alpha3": "DEU",
        "languages": ["German"]
    },
    "GH": {
        "name": "Ghana",
        "code": "GH",
        "emoji": "🇬🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gh.svg",
        "phoneCode": ["+233"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "ghana",
        "alpha3": "GHA",
        "languages": ["English"]
    },
    "GI": {
        "name": "Gibraltar",
        "code": "GI",
        "emoji": "🇬🇮",
        "image": "https://example.com/flags/gi.svg",
        "phoneCode": ["+350"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "gibraltar",
        "alpha3": "GIB",
        "languages": ["English"]
    },
    "GR": {
        "name": "Greece",
        "code": "GR",
        "emoji": "🇬🇷",
        "image": "https://example.com/flags/gr.svg",
        "phoneCode": ["+30"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "greece",
        "alpha3": "GRC",
        "languages": ["Greek"]
    },
    "GL": {
        "name": "Greenland",
        "code": "GL",
        "emoji": "🇬🇱",
        "image": "https://example.com/flags/gl.svg",
        "phoneCode": ["+299"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "greenland",
        "alpha3": "GRL",
        "languages": ["Greenlandic"]
    },
    "GD": {
        "name": "Grenada",
        "code": "GD",
        "emoji": "🇬🇩",
        "image": "https://example.com/flags/gd.svg",
        "phoneCode": ["+1473"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "grenada",
        "alpha3": "GRD",
        "languages": ["English"]
    },
    "GP": {
        "name": "Guadeloupe",
        "code": "GP",
        "emoji": "🇬🇵",
        "image": "https://example.com/flags/gp.svg",
        "phoneCode": ["+590"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "guadeloupe",
        "alpha3": "GLP",
        "languages": ["French"]
    },
    "GU": {
        "name": "Guam",
        "code": "GU",
        "emoji": "🇬🇺",
        "image": "https://example.com/flags/gu.svg",
        "phoneCode": ["+1671"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "guam",
        "alpha3": "GUM",
        "languages": ["English", "Chamorro"]
    },
    "GT": {
        "name": "Guatemala",
        "code": "GT",
        "emoji": "🇬🇹",
        "image": "https://example.com/flags/gt.svg",
        "phoneCode": ["+502"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "guatemala",
        "alpha3": "GTM",
        "languages": ["Spanish"]
    },
    "GG": {
        "name": "Guernsey",
        "code": "GG",
        "emoji": "🇬🇬",
        "image": "https://example.com/flags/gg.svg",
        "phoneCode": ["+44"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "guernsey",
        "alpha3": "GGY",
        "languages": ["English"]
    },
    "GN": {
        "name": "Guinea",
        "code": "GN",
        "emoji": "🇬🇳",
        "image": "https://example.com/flags/gn.svg",
        "phoneCode": ["+224"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "guinea",
        "alpha3": "GIN",
        "languages": ["French"]
    },
    "GW": {
        "name": "Guinea-Bissau",
        "code": "GW",
        "emoji": "🇬🇼",
        "image": "https://example.com/flags/gw.svg",
        "phoneCode": ["+245"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "guinea-bissau",
        "alpha3": "GNB",
        "languages": ["Portuguese"]
    },
    "GY": {
        "name": "Guyana",
        "code": "GY",
        "emoji": "🇬🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gy.svg",
        "phoneCode": ["+592"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "guyana",
        "alpha3": "GUY",
        "languages": ["English"]
    },
    "HT": {
        "name": "Haiti",
        "code": "HT",
        "emoji": "🇭🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ht.svg",
        "phoneCode": ["+509"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "haiti",
        "alpha3": "HTI",
        "languages": ["French", "Haitian Creole"]
    },
    "HN": {
        "name": "Honduras",
        "code": "HN",
        "emoji": "🇭🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hn.svg",
        "phoneCode": ["+504"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "honduras",
        "alpha3": "HND",
        "languages": ["Spanish"]
    },
    "HK": {
        "name": "Hong Kong SAR China",
        "code": "HK",
        "emoji": "🇭🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hk.svg",
        "phoneCode": ["+852"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "hong-kong",
        "alpha3": "HKG",
        "languages": ["Chinese", "English"]
    },
    "HU": {
        "name": "Hungary",
        "code": "HU",
        "emoji": "🇭🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hu.svg",
        "phoneCode": ["+36"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "hungary",
        "alpha3": "HUN",
        "languages": ["Hungarian"]
    },
    "IS": {
        "name": "Iceland",
        "code": "IS",
        "emoji": "🇮🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/is.svg",
        "phoneCode": ["+354"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "iceland",
        "alpha3": "ISL",
        "languages": ["Icelandic"]
    },
    "IN": {
        "name": "India",
        "code": "IN",
        "emoji": "🇮🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/in.svg",
        "phoneCode": ["+91"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "india",
        "alpha3": "IND",
        "languages": ["Hindi", "English"]
    },
    "ID": {
        "name": "Indonesia",
        "code": "ID",
        "emoji": "🇮🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/id.svg",
        "phoneCode": ["+62"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "indonesia",
        "alpha3": "IDN",
        "languages": ["Indonesian"]
    },
    "IR": {
        "name": "Iran",
        "code": "IR",
        "emoji": "🇮🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ir.svg",
        "phoneCode": ["+98"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "iran",
        "alpha3": "IRN",
        "languages": ["Persian"]
    },
    "IQ": {
        "name": "Iraq",
        "code": "IQ",
        "emoji": "🇮🇶",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/iq.svg",
        "phoneCode": ["+964"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "iraq",
        "alpha3": "IRQ",
        "languages": ["Arabic", "Kurdish"]
    },
    "IE": {
        "name": "Ireland",
        "code": "IE",
        "emoji": "🇮🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ie.svg",
        "phoneCode": ["+353"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "ireland",
        "alpha3": "IRL",
        "languages": ["Irish", "English"]
    },
    "IM": {
        "name": "Isle of Man",
        "code": "IM",
        "emoji": "🇮🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/im.svg",
        "phoneCode": ["+44-1624"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "isle-of-man",
        "alpha3": "IMN",
        "languages": ["English"]
    },
    "IL": {
        "name": "Israel",
        "code": "IL",
        "emoji": "🇮🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/il.svg",
        "phoneCode": ["+972"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "israel",
        "alpha3": "ISR",
        "languages": ["Hebrew", "Arabic"]
    },
    "IT": {
        "name": "Italy",
        "code": "IT",
        "emoji": "🇮🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/it.svg",
        "phoneCode": ["+39"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "italy",
        "alpha3": "ITA",
        "languages": ["Italian"]
    },
    "JM": {
        "name": "Jamaica",
        "code": "JM",
        "emoji": "🇯🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jm.svg",
        "phoneCode": ["+1-876"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "jamaica",
        "alpha3": "JAM",
        "languages": ["English"]
    },
    "JP": {
        "name": "Japan",
        "code": "JP",
        "emoji": "🇯🇵",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jp.svg",
        "phoneCode": ["+81"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "japan",
        "alpha3": "JPN",
        "languages": ["Japanese"]
    },
    "JE": {
        "name": "Jersey",
        "code": "JE",
        "emoji": "🇯🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/je.svg",
        "phoneCode": ["+44-1534"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "jersey",
        "alpha3": "JEY",
        "languages": ["English"]
    },
    "JO": {
        "name": "Jordan",
        "code": "JO",
        "emoji": "🇯🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jo.svg",
        "phoneCode": ["+962"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "jordan",
        "alpha3": "JOR",
        "languages": ["Arabic"]
    },
    "KZ": {
        "name": "Kazakhstan",
        "code": "KZ",
        "emoji": "🇰🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kz.svg",
        "phoneCode": ["+7"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "kazakhstan",
        "alpha3": "KAZ",
        "languages": ["Kazakh", "Russian"]
    },
    "KE": {
        "name": "Kenya",
        "code": "KE",
        "emoji": "🇰🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ke.svg",
        "phoneCode": ["+254"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "kenya",
        "alpha3": "KEN",
        "languages": ["Swahili", "English"]
    },
    "KI": {
        "name": "Kiribati",
        "code": "KI",
        "emoji": "🇰🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ki.svg",
        "phoneCode": ["+686"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "kiribati",
        "alpha3": "KIR",
        "languages": ["English", "Gilbertese"]
    },
    "KW": {
        "name": "Kuwait",
        "code": "KW",
        "emoji": "🇰🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kw.svg",
        "phoneCode": ["+965"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "kuwait",
        "alpha3": "KWT",
        "languages": ["Arabic"]
    },
    "KG": {
        "name": "Kyrgyzstan",
        "code": "KG",
        "emoji": "🇰🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kg.svg",
        "phoneCode": ["+996"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "kyrgyzstan",
        "alpha3": "KGZ",
        "languages": ["Kyrgyz", "Russian"]
    },
    "LV": {
        "name": "Latvia",
        "code": "LV",
        "emoji": "🇱🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lv.svg",
        "phoneCode": ["+371"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "latvia",
        "alpha3": "LVA",
        "languages": ["Latvian"]
    },
    "LB": {
        "name": "Lebanon",
        "code": "LB",
        "emoji": "🇱🇧",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lb.svg",
        "phoneCode": ["+961"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "lebanon",
        "alpha3": "LBN",
        "languages": ["Arabic"]
    },
    "LS": {
        "name": "Lesotho",
        "code": "LS",
        "emoji": "🇱🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ls.svg",
        "phoneCode": ["+266"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "lesotho",
        "alpha3": "LSO",
        "languages": ["English", "Sotho"]
    },
    "LR": {
        "name": "Liberia",
        "code": "LR",
        "emoji": "🇱🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lr.svg",
        "phoneCode": ["+231"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "liberia",
        "alpha3": "LBR",
        "languages": ["English"]
    },
    "LY": {
        "name": "Libya",
        "code": "LY",
        "emoji": "🇱🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ly.svg",
        "phoneCode": ["+218"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "libya",
        "alpha3": "LBY",
        "languages": ["Arabic"]
    },
    "LI": {
        "name": "Liechtenstein",
        "code": "LI",
        "emoji": "🇱🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/li.svg",
        "phoneCode": ["+423"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "liechtenstein",
        "alpha3": "LIE",
        "languages": ["German"]
    },
    "LT": {
        "name": "Lithuania",
        "code": "LT",
        "emoji": "🇱🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lt.svg",
        "phoneCode": ["+370"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "lithuania",
        "alpha3": "LTU",
        "languages": ["Lithuanian"]
    },
    "LU": {
        "name": "Luxembourg",
        "code": "LU",
        "emoji": "🇱🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lu.svg",
        "phoneCode": ["+352"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "luxembourg",
        "alpha3": "LUX",
        "languages": ["Luxembourgish", "French", "German"]
    },
    "MO": {
        "name": "Macao SAR China",
        "code": "MO",
        "emoji": "🇲🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mo.svg",
        "phoneCode": ["+853"],
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "macao",
        "alpha3": "MAC",
        "languages": ["Chinese", "Portuguese"]
    },
    "MG": {
        "name": "Madagascar",
        "code": "MG",
        "emoji": "🇲🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mg.svg",
        "phoneCode": ["+261"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "madagascar",
        "alpha3": "MDG",
        "languages": ["Malagasy", "French"]
    },
    "MW": {
        "name": "Malawi",
        "code": "MW",
        "emoji": "🇲🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mw.svg",
        "phoneCode": ["+265"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "malawi",
        "alpha3": "MWI",
        "languages": ["English", "Chichewa"]
    },
    "MY": {
        "name": "Malaysia",
        "code": "MY",
        "emoji": "🇲🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/my.svg",
        "phoneCode": ["+60"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "malaysia",
        "alpha3": "MYS",
        "languages": ["Malay"]
    },
    "MV": {
        "name": "Maldives",
        "code": "MV",
        "emoji": "🇲🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mv.svg",
        "phoneCode": ["+960"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "maldives",
        "alpha3": "MDV",
        "languages": ["Dhivehi"]
    },
    "ML": {
        "name": "Mali",
        "code": "ML",
        "emoji": "🇲🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ml.svg",
        "phoneCode": ["+223"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mali",
        "alpha3": "MLI",
        "languages": ["French"]
    },
    "MT": {
        "name": "Malta",
        "code": "MT",
        "emoji": "🇲🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mt.svg",
        "phoneCode": ["+356"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "malta",
        "alpha3": "MLT",
        "languages": ["Maltese", "English"]
    },
    "MH": {
        "name": "Marshall Islands",
        "code": "MH",
        "emoji": "🇲🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mh.svg",
        "phoneCode": ["+692"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "marshall-islands",
        "alpha3": "MHL",
        "languages": ["Marshallese", "English"]
    },
    "MQ": {
        "name": "Martinique",
        "code": "MQ",
        "emoji": "🇲🇶",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mq.svg",
        "phoneCode": ["+596"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "martinique",
        "alpha3": "MTQ",
        "languages": ["French"]
    },
    "MR": {
        "name": "Mauritania",
        "code": "MR",
        "emoji": "🇲🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mr.svg",
        "phoneCode": ["+222"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mauritania",
        "alpha3": "MRT",
        "languages": ["Arabic"]
    },
    "MU": {
        "name": "Mauritius",
        "code": "MU",
        "emoji": "🇲🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mu.svg",
        "phoneCode": ["+230"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mauritius",
        "alpha3": "MUS",
        "languages": ["English", "French"]
    },
    "YT": {
        "name": "Mayotte",
        "code": "YT",
        "emoji": "🇾🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/yt.svg",
        "phoneCode": ["+262"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mayotte",
        "alpha3": "MYT",
        "languages": ["French"]
    },
    "MX": {
        "name": "Mexico",
        "code": "MX",
        "emoji": "🇲🇽",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mx.svg",
        "phoneCode": ["+52"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mexico",
        "alpha3": "MEX",
        "languages": ["Spanish"]
    },
    "FM": {
        "name": "Micronesia",
        "code": "FM",
        "emoji": "🇫🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fm.svg",
        "phoneCode": ["+691"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "micronesia",
        "alpha3": "FSM",
        "languages": ["English"]
    },
    "MD": {
        "name": "Moldova",
        "code": "MD",
        "emoji": "🇲🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/md.svg",
        "phoneCode": ["+373"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "moldova",
        "alpha3": "MDA",
        "languages": ["Romanian"]
    },
    "MC": {
        "name": "Monaco",
        "code": "MC",
        "emoji": "🇲🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mc.svg",
        "phoneCode": ["+377"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "monaco",
        "alpha3": "MCO",
        "languages": ["French"]
    },
    "MN": {
        "name": "Mongolia",
        "code": "MN",
        "emoji": "🇲🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mn.svg",
        "phoneCode": ["+976"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "mongolia",
        "alpha3": "MNG",
        "languages": ["Mongolian"]
    },
    "ME": {
        "name": "Montenegro",
        "code": "ME",
        "emoji": "🇲🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/me.svg",
        "phoneCode": ["+382"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "montenegro",
        "alpha3": "MNE",
        "languages": ["Montenegrin"]
    },
    "MS": {
        "name": "Montserrat",
        "code": "MS",
        "emoji": "🇲🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ms.svg",
        "phoneCode": ["+1-664"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "montserrat",
        "alpha3": "MSR",
        "languages": ["English"]
    },
    "MA": {
        "name": "Morocco",
        "code": "MA",
        "emoji": "🇲🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ma.svg",
        "phoneCode": ["+212"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "morocco",
        "alpha3": "MAR",
        "languages": ["Arabic"]
    },
    "MZ": {
        "name": "Mozambique",
        "code": "MZ",
        "emoji": "🇲🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mz.svg",
        "phoneCode": ["+258"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "mozambique",
        "alpha3": "MOZ",
        "languages": ["Portuguese"]
    },
    "MM": {
        "name": "Myanmar (Burma)",
        "code": "MM",
        "emoji": "🇲🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mm.svg",
        "phoneCode": ["+95"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "myanmar",
        "alpha3": "MMR",
        "languages": ["Burmese"]
    },
    "NA": {
        "name": "Namibia",
        "code": "NA",
        "emoji": "🇳🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/na.svg",
        "phoneCode": ["+264"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "namibia",
        "alpha3": "NAM",
        "languages": ["English"]
    },
    "NR": {
        "name": "Nauru",
        "code": "NR",
        "emoji": "🇳🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nr.svg",
        "phoneCode": ["+674"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "nauru",
        "alpha3": "NRU",
        "languages": ["English", "Nauruan"]
    },
    "NP": {
        "name": "Nepal",
        "code": "NP",
        "emoji": "🇳🇵",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/np.svg",
        "phoneCode": ["+977"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "nepal",
        "alpha3": "NPL",
        "languages": ["Nepali"]
    },
    "NL": {
        "name": "Netherlands",
        "code": "NL",
        "emoji": "🇳🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nl.svg",
        "phoneCode": ["+31"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "netherlands",
        "alpha3": "NLD",
        "languages": ["Dutch"]
    },
    "NC": {
        "name": "New Caledonia",
        "code": "NC",
        "emoji": "🇳🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nc.svg",
        "phoneCode": ["+687"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "new-caledonia",
        "alpha3": "NCL",
        "languages": ["French"]
    },
    "NZ": {
        "name": "New Zealand",
        "code": "NZ",
        "emoji": "🇳🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nz.svg",
        "phoneCode": ["+64"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "new-zealand",
        "alpha3": "NZL",
        "languages": ["English", "Māori"]
    },
    "NI": {
        "name": "Nicaragua",
        "code": "NI",
        "emoji": "🇳🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ni.svg",
        "phoneCode": ["+505"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "nicaragua",
        "alpha3": "NIC",
        "languages": ["Spanish"]
    },
    "NE": {
        "name": "Niger",
        "code": "NE",
        "emoji": "🇳🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ne.svg",
        "phoneCode": ["+227"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "niger",
        "alpha3": "NER",
        "languages": ["French"]
    },
    "NG": {
        "name": "Nigeria",
        "code": "NG",
        "emoji": "🇳🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ng.svg",
        "phoneCode": ["+234"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "nigeria",
        "alpha3": "NGA",
        "languages": ["English"]
    },
    "NU": {
        "name": "Niue",
        "code": "NU",
        "emoji": "🇳🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nu.svg",
        "phoneCode": ["+683"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "niue",
        "alpha3": "NIU",
        "languages": ["English"]
    },
    "NF": {
        "name": "Norfolk Island",
        "code": "NF",
        "emoji": "🇳🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nf.svg",
        "phoneCode": ["+672"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "norfolk-island",
        "alpha3": "NFK",
        "languages": ["English"]
    },
    "KP": {
        "name": "North Korea",
        "code": "KP",
        "emoji": "🇰🇵",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kp.svg",
        "phoneCode": ["+850"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "north-korea",
        "alpha3": "PRK",
        "languages": ["Korean"]
    },
    "MK": {
        "name": "North Macedonia",
        "code": "MK",
        "emoji": "🇲🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mk.svg",
        "phoneCode": ["+389"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "north-macedonia",
        "alpha3": "MKD",
        "languages": ["Macedonian"]
    },
    "MP": {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "emoji": "🇲🇵",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mp.svg",
        "phoneCode": ["+1-670"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "northern-mariana-islands",
        "alpha3": "MNP",
        "languages": ["English", "Chamorro"]
    },
    "NO": {
        "name": "Norway",
        "code": "NO",
        "emoji": "🇳🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/no.svg",
        "phoneCode": ["+47"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "norway",
        "alpha3": "NOR",
        "languages": ["Norwegian"]
    },
    "OM": {
        "name": "Oman",
        "code": "OM",
        "emoji": "🇴🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/om.svg",
        "phoneCode": ["+968"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "oman",
        "alpha3": "OMN",
        "languages": ["Arabic"]
    },
    "PK": {
        "name": "Pakistan",
        "code": "PK",
        "emoji": "🇵🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pk.svg",
        "phoneCode": ["+92"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "pakistan",
        "alpha3": "PAK",
        "languages": ["Urdu", "English"]
    },
    "PW": {
        "name": "Palau",
        "code": "PW",
        "emoji": "🇵🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pw.svg",
        "phoneCode": ["+680"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "palau",
        "alpha3": "PLW",
        "languages": ["Palauan", "English"]
    },
    "PS": {
        "name": "Palestine",
        "code": "PS",
        "emoji": "🇵🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ps.svg",
        "phoneCode": ["+970"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "palestine",
        "alpha3": "PSE",
        "languages": ["Arabic"]
    },
    "PA": {
        "name": "Panama",
        "code": "PA",
        "emoji": "🇵🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pa.svg",
        "phoneCode": ["+507"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "panama",
        "alpha3": "PAN",
        "languages": ["Spanish"]
    },
    "PG": {
        "name": "Papua New Guinea",
        "code": "PG",
        "emoji": "🇵🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pg.svg",
        "phoneCode": ["+675"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "papua-new-guinea",
        "alpha3": "PNG",
        "languages": ["English"]
    },
    "PY": {
        "name": "Paraguay",
        "code": "PY",
        "emoji": "🇵🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/py.svg",
        "phoneCode": ["+595"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "paraguay",
        "alpha3": "PRY",
        "languages": ["Spanish", "Guaraní"]
    },
    "PE": {
        "name": "Peru",
        "code": "PE",
        "emoji": "🇵🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pe.svg",
        "phoneCode": ["+51"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "peru",
        "alpha3": "PER",
        "languages": ["Spanish"]
    },
    "PH": {
        "name": "Philippines",
        "code": "PH",
        "emoji": "🇵🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ph.svg",
        "phoneCode": ["+63"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "philippines",
        "alpha3": "PHL",
        "languages": ["Filipino", "English"]
    },
    "PN": {
        "name": "Pitcairn Islands",
        "code": "PN",
        "emoji": "🇵🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pn.svg",
        "phoneCode": ["+64"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "pitcairn-islands",
        "alpha3": "PCN",
        "languages": ["English"]
    },
    "PL": {
        "name": "Poland",
        "code": "PL",
        "emoji": "🇵🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pl.svg",
        "phoneCode": ["+48"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "poland",
        "alpha3": "POL",
        "languages": ["Polish"]
    },
    "PT": {
        "name": "Portugal",
        "code": "PT",
        "emoji": "🇵🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pt.svg",
        "phoneCode": ["+351"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "portugal",
        "alpha3": "PRT",
        "languages": ["Portuguese"]
    },
    "PR": {
        "name": "Puerto Rico",
        "code": "PR",
        "emoji": "🇵🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pr.svg",
        "phoneCode": ["+1"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "puerto-rico",
        "alpha3": "PRI",
        "languages": ["Spanish", "English"]
    },
    "QA": {
        "name": "Qatar",
        "code": "QA",
        "emoji": "🇶🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/qa.svg",
        "phoneCode": ["+974"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "qatar",
        "alpha3": "QAT",
        "languages": ["Arabic"]
    },
    "RE": {
        "name": "Réunion",
        "code": "RE",
        "emoji": "🇷🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/re.svg",
        "phoneCode": ["+262"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "reunion",
        "alpha3": "REU",
        "languages": ["French"]
    },
    "RO": {
        "name": "Romania",
        "code": "RO",
        "emoji": "🇷🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ro.svg",
        "phoneCode": ["+40"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "romania",
        "alpha3": "ROU",
        "languages": ["Romanian"]
    },
    "RU": {
        "name": "Russia",
        "code": "RU",
        "emoji": "🇷🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ru.svg",
        "phoneCode": ["+7"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "russia",
        "alpha3": "RUS",
        "languages": ["Russian"]
    },
    "RW": {
        "name": "Rwanda",
        "code": "RW",
        "emoji": "🇷🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rw.svg",
        "phoneCode": ["+250"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "rwanda",
        "alpha3": "RWA",
        "languages": ["Kinyarwanda", "French", "English"]
    },
    "BL": {
        "name": "Saint Barthélemy",
        "code": "BL",
        "emoji": "🇧🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bl.svg",
        "phoneCode": ["+590"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "saint-barthelemy",
        "alpha3": "BLM",
        "languages": ["French"]
    },
    "SH": {
        "name": "Saint Helena",
        "code": "SH",
        "emoji": "🇸🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sh.svg",
        "phoneCode": ["+290"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "saint-helena",
        "alpha3": "SHN",
        "languages": ["English"]
    },
    "KN": {
        "name": "Saint Kitts & Nevis",
        "code": "KN",
        "emoji": "🇰🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kn.svg",
        "phoneCode": ["+1-869"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "saint-kitts-and-nevis",
        "alpha3": "KNA",
        "languages": ["English"]
    },
    "LC": {
        "name": "Saint Lucia",
        "code": "LC",
        "emoji": "🇱🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lc.svg",
        "phoneCode": ["+1-758"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "saint-lucia",
        "alpha3": "LCA",
        "languages": ["English"]
    },
    "MF": {
        "name": "Saint Martin",
        "code": "MF",
        "emoji": "🇲🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mf.svg",
        "phoneCode": ["+590"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "saint-martin",
        "alpha3": "MAF",
        "languages": ["French", "Dutch", "English"]
    },
    "PM": {
        "name": "Saint Pierre & Miquelon",
        "code": "PM",
        "emoji": "🇵🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pm.svg",
        "phoneCode": ["+508"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "saint-pierre-and-miquelon",
        "alpha3": "SPM",
        "languages": ["French"]
    },
    "VC": {
        "name": "Saint Vincent & Grenadines",
        "code": "VC",
        "emoji": "🇻🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vc.svg",
        "phoneCode": ["+1-784"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "saint-vincent-and-the-grenadines",
        "alpha3": "VCT",
        "languages": ["English"]
    },
    "WS": {
        "name": "Samoa",
        "code": "WS",
        "emoji": "🇼🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ws.svg",
        "phoneCode": ["+685"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "samoa",
        "alpha3": "WSM",
        "languages": ["Samoan", "English"]
    },
    "SM": {
        "name": "San Marino",
        "code": "SM",
        "emoji": "🇸🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sm.svg",
        "phoneCode": ["+378"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "san-marino",
        "alpha3": "SMR",
        "languages": ["Italian"]
    },
    "ST": {
        "name": "São Tomé & Príncipe",
        "code": "ST",
        "emoji": "🇸🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/st.svg",
        "phoneCode": ["+239"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "sao-tome-and-principe",
        "alpha3": "STP",
        "languages": ["Portuguese"]
    },
    "SA": {
        "name": "Saudi Arabia",
        "code": "SA",
        "emoji": "🇸🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sa.svg",
        "phoneCode": ["+966"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "saudi-arabia",
        "alpha3": "SAU",
        "languages": ["Arabic"]
    },
    "SN": {
        "name": "Senegal",
        "code": "SN",
        "emoji": "🇸🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sn.svg",
        "phoneCode": ["+221"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "senegal",
        "alpha3": "SEN",
        "languages": ["French"]
    },
    "RS": {
        "name": "Serbia",
        "code": "RS",
        "emoji": "🇷🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rs.svg",
        "phoneCode": ["+381"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "serbia",
        "alpha3": "SRB",
        "languages": ["Serbian"]
    },
    "SC": {
        "name": "Seychelles",
        "code": "SC",
        "emoji": "🇸🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sc.svg",
        "phoneCode": ["+248"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "seychelles",
        "alpha3": "SYC",
        "languages": ["Seychellois Creole", "French", "English"]
    },
    "SL": {
        "name": "Sierra Leone",
        "code": "SL",
        "emoji": "🇸🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sl.svg",
        "phoneCode": ["+232"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "sierra-leone",
        "alpha3": "SLE",
        "languages": ["English"]
    },
    "SG": {
        "name": "Singapore",
        "code": "SG",
        "emoji": "🇸🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sg.svg",
        "phoneCode": ["+65"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "singapore",
        "alpha3": "SGP",
        "languages": ["English", "Malay", "Mandarin", "Tamil"]
    },
    "SX": {
        "name": "Sint Maarten",
        "code": "SX",
        "emoji": "🇸🇽",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sx.svg",
        "phoneCode": ["+1-721"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "sint-maarten",
        "alpha3": "SXM",
        "languages": ["Dutch", "English"]
    },
    "SK": {
        "name": "Slovakia",
        "code": "SK",
        "emoji": "🇸🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sk.svg",
        "phoneCode": ["+421"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "slovakia",
        "alpha3": "SVK",
        "languages": ["Slovak"]
    },
    "SI": {
        "name": "Slovenia",
        "code": "SI",
        "emoji": "🇸🇮",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/si.svg",
        "phoneCode": ["+386"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "slovenia",
        "alpha3": "SVN",
        "languages": ["Slovenian"]
    },
    "SB": {
        "name": "Solomon Islands",
        "code": "SB",
        "emoji": "🇸🇧",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sb.svg",
        "phoneCode": ["+677"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "solomon-islands",
        "alpha3": "SLB",
        "languages": ["English"]
    },
    "SO": {
        "name": "Somalia",
        "code": "SO",
        "emoji": "🇸🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/so.svg",
        "phoneCode": ["+252"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "somalia",
        "alpha3": "SOM",
        "languages": ["Somali", "Arabic"]
    },
    "ZA": {
        "name": "South Africa",
        "code": "ZA",
        "emoji": "🇿🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/za.svg",
        "phoneCode": ["+27"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "south-africa",
        "alpha3": "ZAF",
        "languages": ["Afrikaans", "English", "Zulu", "Xhosa", "Swazi", "Southern Sotho", "Tswana", "Northern Sotho", "Venda", "Tsonga"]
    },
    "GS": {
        "name": "South Georgia & South Sandwich Islands",
        "code": "GS",
        "emoji": "🇬🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gs.svg",
        "phoneCode": ["+500"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "south-georgia-and-the-south-sandwich-islands",
        "alpha3": "SGS",
        "languages": ["English"]
    },
    "KR": {
        "name": "South Korea",
        "code": "KR",
        "emoji": "🇰🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kr.svg",
        "phoneCode": ["+82"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "south-korea",
        "alpha3": "KOR",
        "languages": ["Korean"]
    },
    "SS": {
        "name": "South Sudan",
        "code": "SS",
        "emoji": "🇸🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ss.svg",
        "phoneCode": ["+211"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "south-sudan",
        "alpha3": "SSD",
        "languages": ["English"]
    },
    "ES": {
        "name": "Spain",
        "code": "ES",
        "emoji": "🇪🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/es.svg",
        "phoneCode": ["+34"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "spain",
        "alpha3": "ESP",
        "languages": ["Spanish"]
    },
    "LK": {
        "name": "Sri Lanka",
        "code": "LK",
        "emoji": "🇱🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lk.svg",
        "phoneCode": ["+94"],
        "mask": "XXXXXXXXXXXX",
        "phoneLimit": 12,
        "slug": "sri-lanka",
        "alpha3": "LKA",
        "languages": ["Sinhala", "Tamil"]
    },
    "SD": {
        "name": "Sudan",
        "code": "SD",
        "emoji": "🇸🇩",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sd.svg",
        "phoneCode": ["+249"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "sudan",
        "alpha3": "SDN",
        "languages": ["Arabic", "English"]
    },
    "SR": {
        "name": "Suriname",
        "code": "SR",
        "emoji": "🇸🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sr.svg",
        "phoneCode": ["+597"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "suriname",
        "alpha3": "SUR",
        "languages": ["Dutch"]
    },
    "SJ": {
        "name": "Svalbard & Jan Mayen",
        "code": "SJ",
        "emoji": "🇸🇯",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sj.svg",
        "phoneCode": ["+47"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "svalbard-and-jan-mayen",
        "alpha3": "SJM",
        "languages": ["Norwegian"]
    },
    "SE": {
        "name": "Sweden",
        "code": "SE",
        "emoji": "🇸🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/se.svg",
        "phoneCode": ["+46"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "sweden",
        "alpha3": "SWE",
        "languages": ["Swedish"]
    },
    "CH": {
        "name": "Switzerland",
        "code": "CH",
        "emoji": "🇨🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ch.svg",
        "phoneCode": ["+41"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "switzerland",
        "alpha3": "CHE",
        "languages": ["German", "French", "Italian", "Romansh"]
    },
    "SY": {
        "name": "Syria",
        "code": "SY",
        "emoji": "🇸🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sy.svg",
        "phoneCode": ["+963"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "syria",
        "alpha3": "SYR",
        "languages": ["Arabic"]
    },
    "TW": {
        "name": "Taiwan",
        "code": "TW",
        "emoji": "🇹🇼",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tw.svg",
        "phoneCode": ["+886"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "taiwan",
        "alpha3": "TWN",
        "languages": ["Mandarin"]
    },
    "TJ": {
        "name": "Tajikistan",
        "code": "TJ",
        "emoji": "🇹🇯",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tj.svg",
        "phoneCode": ["+992"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "tajikistan",
        "alpha3": "TJK",
        "languages": ["Tajik", "Russian"]
    },
    "TZ": {
        "name": "Tanzania",
        "code": "TZ",
        "emoji": "🇹🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tz.svg",
        "phoneCode": ["+255"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "tanzania",
        "alpha3": "TZA",
        "languages": ["Swahili", "English"]
    },
    "TH": {
        "name": "Thailand",
        "code": "TH",
        "emoji": "🇹🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/th.svg",
        "phoneCode": ["+66"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "thailand",
        "alpha3": "THA",
        "languages": ["Thai"]
    },
    "TL": {
        "name": "Timor-Leste",
        "code": "TL",
        "emoji": "🇹🇱",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tl.svg",
        "phoneCode": ["+670"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "timor-leste",
        "alpha3": "TLS",
        "languages": ["Tetum", "Portuguese"]
    },
    "TG": {
        "name": "Togo",
        "code": "TG",
        "emoji": "🇹🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tg.svg",
        "phoneCode": ["+228"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "togo",
        "alpha3": "TGO",
        "languages": ["French"]
    },
    "TK": {
        "name": "Tokelau",
        "code": "TK",
        "emoji": "🇹🇰",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tk.svg",
        "phoneCode": ["+690"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "tokelau",
        "alpha3": "TKL",
        "languages": ["Tokelauan", "English"]
    },
    "TO": {
        "name": "Tonga",
        "code": "TO",
        "emoji": "🇹🇴",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/to.svg",
        "phoneCode": ["+676"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "tonga",
        "alpha3": "TON",
        "languages": ["Tongan", "English"]
    },
    "TT": {
        "name": "Trinidad & Tobago",
        "code": "TT",
        "emoji": "🇹🇹",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tt.svg",
        "phoneCode": ["+1-868"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "trinidad-and-tobago",
        "alpha3": "TTO",
        "languages": ["English"]
    },
    "TA": {
        "name": "Tristan da Cunha",
        "code": "TA",
        "emoji": "🇹🇦",
        "image": "https://example.com/flags/ta.svg",
        "phoneCode": ["+290"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 8,
        "slug": "tristan-da-cunha",
        "alpha3": "TAA",
        "languages": ["English"]
    },
    "TN": {
        "name": "Tunisia",
        "code": "TN",
        "emoji": "🇹🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tn.svg",
        "phoneCode": ["+216"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "tunisia",
        "alpha3": "TUN",
        "languages": ["Arabic"]
    },
    "TR": {
        "name": "Turkey",
        "code": "TR",
        "emoji": "🇹🇷",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tr.svg",
        "phoneCode": ["+90"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "turkey",
        "alpha3": "TUR",
        "languages": ["Turkish"]
    },
    "TM": {
        "name": "Turkmenistan",
        "code": "TM",
        "emoji": "🇹🇲",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tm.svg",
        "phoneCode": ["+993"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "turkmenistan",
        "alpha3": "TKM",
        "languages": ["Turkmen"]
    },
    "TC": {
        "name": "Turks & Caicos Islands",
        "code": "TC",
        "emoji": "🇹🇨",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tc.svg",
        "phoneCode": ["+1-649"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "turks-and-caicos-islands",
        "alpha3": "TCA",
        "languages": ["English"]
    },
    "TV": {
        "name": "Tuvalu",
        "code": "TV",
        "emoji": "🇹🇻",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tv.svg",
        "phoneCode": ["+688"],
        "mask": "XXXXXX",
        "phoneLimit": 6,
        "slug": "tuvalu",
        "alpha3": "TUV",
        "languages": ["Tuvaluan", "English"]
    },
    "UG": {
        "name": "Uganda",
        "code": "UG",
        "emoji": "🇺🇬",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ug.svg",
        "phoneCode": ["+256"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "uganda",
        "alpha3": "UGA",
        "languages": ["English", "Swahili"]
    },
    "UA": {
        "name": "Ukraine",
        "code": "UA",
        "emoji": "🇺🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ua.svg",
        "phoneCode": ["+380"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "ukraine",
        "alpha3": "UKR",
        "languages": ["Ukrainian"]
    },
    "AE": {
        "name": "United Arab Emirates",
        "code": "AE",
        "emoji": "🇦🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ae.svg",
        "phoneCode": ["+971"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "united-arab-emirates",
        "alpha3": "ARE",
        "languages": ["Arabic"]
    },
    "GB": {
        "name": "United Kingdom",
        "code": "GB",
        "emoji": "🇬🇧",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gb.svg",
        "phoneCode": ["+44"],
        "mask": "XXXXXXXXXXX",
        "phoneLimit": 11,
        "slug": "united-kingdom",
        "alpha3": "GBR",
        "languages": ["English"]
    },
    "US": {
        "name": "United States",
        "code": "US",
        "emoji": "🇺🇸",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/us.svg",
        "phoneCode": ["+1"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "united-states",
        "alpha3": "USA",
        "languages": ["English"]
    },
    "UY": {
        "name": "Uruguay",
        "code": "UY",
        "emoji": "🇺🇾",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uy.svg",
        "phoneCode": ["+598"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "uruguay",
        "alpha3": "URY",
        "languages": ["Spanish"]
    },
    "UZ": {
        "name": "Uzbekistan",
        "code": "UZ",
        "emoji": "🇺🇿",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uz.svg",
        "phoneCode": ["+998"],
        "mask": "XXXXXXXXXX",
        "phoneLimit": 10,
        "slug": "uzbekistan",
        "alpha3": "UZB",
        "languages": ["Uzbek", "Russian"]
    },
    "VU": {
        "name": "Vanuatu",
        "code": "VU",
        "emoji": "🇻🇺",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vu.svg",
        "phoneCode": ["+678"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "vanuatu",
        "alpha3": "VUT",
        "languages": ["Bislama", "English", "French"]
    },
    "VA": {
        "name": "Vatican City",
        "code": "VA",
        "emoji": "🇻🇦",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/va.svg",
        "phoneCode": ["+379"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "vatican-city",
        "alpha3": "VAT",
        "languages": ["Italian", "Latin"]
    },
    "VE": {
        "name": "Venezuela",
        "code": "VE",
        "emoji": "🇻🇪",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ve.svg",
        "phoneCode": ["+58"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "venezuela",
        "alpha3": "VEN",
        "languages": ["Spanish"]
    },
    "VN": {
        "name": "Vietnam",
        "code": "VN",
        "emoji": "🇻🇳",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vn.svg",
        "phoneCode": ["+84"],
        "mask": "XXXXXXXXX",
        "phoneLimit": 9,
        "slug": "vietnam",
        "alpha3": "VNM",
        "languages": ["Vietnamese"]
    },
    "WF": {
        "name": "Wallis & Futuna",
        "code": "WF",
        "emoji": "🇼🇫",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/wf.svg",
        "phoneCode": ["+681"],
        "mask": "XXXXXXX",
        "phoneLimit": 7,
        "slug": "wallis-and-futuna",
        "alpha3": "WLF",
        "languages": ["French"]
    },
    "EH": {
        "name": "Western Sahara",
        "code": "EH",
        "emoji": "🇪🇭",
        "image": "https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/eh.svg",
        "mask": "XXXXXXXX",
        "phoneLimit": 8,
        "slug": "western-sahara",
        "alpha3": "ESH",
        "languages": ["Arabic", "Spanish"]
    }
}

// Memoization cache for countriesList results
let cachedCountries = null

/**
 * Retrieves country data based on the specified type.
 * @param {number} type - The type of data format to retrieve:
 *   - 0: Returns the original countries object.
 *   - 1: Returns a transformed object with specific fields (name, code, language, alpha3).
 *   - 2: Returns an array of objects with selected properties (code, name, mask, phoneLimit, phoneCode).
 *   - Default: Returns a simplified object with only country codes and names.
 * @returns {Object|Array} The formatted country data based on the specified type.
 */
export const countriesList = (type = null) => {
    let result // Declare the variable outside the switch block

    // Use memoization to cache results
    if (cachedCountries !== null && cachedCountries[type] !== undefined) {
        return cachedCountries[type]
    }

    switch (type) {
        case 0:
            // Return the original countries object
            result = countries
            break

        case 1:
            // Return transformed object with specific fields
            result = Object.fromEntries(
                Object.entries(countries).map(([code, {name, languages, alpha3}]) => [
                    code,
                    [name, code, languages[0], alpha3]
                ])
            )
            break

        case 2:
            // Return array of objects with selected properties
            result = Object.values(countries).map(({code, name, mask, phoneLimit, phoneCode}) => ({
                code,
                name,
                mask,
                phoneLimit,
                phoneCode
            }))
            break

        default:
            // Return simplified object with only country codes and names
            result = Object.fromEntries(
                Object.entries(countries).map(([code, {name}]) => [code, name])
            )
            break
    }

    // Cache the result for the current type
    cachedCountries = {...cachedCountries, [type]: result}
    return result
}

/**
 * Finds a country by phone code prefix.
 * @param {string} prefix - The phone code prefix to search for.
 * @returns {Object|null} The country object if found, or null if not found.
 */
export const findCountryByPrefix = (prefix) => {
    return countriesList(2).find(country => {
        if (country.phoneCode && Array.isArray(country.phoneCode)) {
            return country.phoneCode.includes(prefix)
        }
        return false
    })
}

/**
 * Finds a country by its country code.
 * @param {string} code - The country code to search for.
 * @returns {Object|null} The country object if found, or null if not found.
 */
export const findCountryByCode = (code) => {
    return Object.values(countriesList(2)).find(country => country.code === code)
}
