import { computed } from 'vue'
import { typesList } from '@/shared/data'
import { SessionModel } from '@/entities/Session'

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'users-table',
            pathDt: 'users/dt',
            initOrder: 'created',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Username',
                    key: 'username',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'First Name',
                    key: 'firstname',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Last Name',
                    key: 'lastname',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Skype',
                    key: 'skype',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Telegram',
                    key: 'telegram',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Upline',
                    key: 'upline',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Domain',
                    key: 'domain',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Created At',
                    key: 'created',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Status',
                    key: 'status',
                    type: 'boolean',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'role',
                    placeholder: 'Select Role',
                    type: 'select',
                    data: typesList
                },
                {
                    field: 'reportTo',
                    placeholder: 'Select Report',
                    type: 'select',
                    data: sessionStore.brands || []
                }
            ]),
            searchByList: computed(() => [
                {
                    key: 'name',
                    value: 'Name'
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'delete_user',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [{ key: 'delete_mass_users', label: 'Delete' }]
                }
            ])
        }
    }
}
