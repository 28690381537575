<script setup>
import { computed, ref } from 'vue'

import { BaseSelectBox } from '@/shared/ui/Inputs'
import { BaseButton } from '@/shared/ui/Buttons'

import { DatatableModel } from '@/entities/Datatable'
import { ModalModel } from '@/entities/Modal'
import { TemplatesModel } from '@/entities/Templates'
import { BaseModal } from '@/shared/ui/Modal'
import { BaseImage } from '@/shared/ui/Images'

const datatableStore = DatatableModel.useDatatableStore()
const store = TemplatesModel.useTemplatesStore()
const modalStore = ModalModel.useModalStore()

const tableId = computed(() => DatatableModel.getTableId(datatableStore))
const savedTemplates = computed(() => TemplatesModel.getSavedTemplates(store, tableId.value))

const selectedTemplate = ref({})

const savedFiltersData = computed(() => {
    let obj = []
    for (const item in savedTemplates.value) {
        obj.push(savedTemplates.value[item])
    }

    return obj
})

const setSelectedTemplate = (template) => {
    selectedTemplate.value = template
}

const remove = (data) => {
    modalStore.closeModal().then(() => {
        modalStore.setModal('confirmation')
        modalStore.setActionData({
            action: 'DELETE',
            option: 'delete_template',
            data: data.value
        })
    })
}

const close = () => {
    modalStore.closeModal()
}

const loadTemplate = () => {
    let loadedTemplate = savedTemplates.value.filter((item) => item.id === selectedTemplate.value.value)[0]
    store.loadTemplate(loadedTemplate)
    selectedTemplate.value = {}
    modalStore.closeModal()
}
</script>

<template>
    <BaseModal  :width="'500px'">
        <template #title>
            <h4 class="typo-heading-h4">Load template</h4>
        </template>
        <template #content>
            <div class="load-template-modal">
                <BaseSelectBox
                    v-model="selectedTemplate.value"
                    :options="
                        savedFiltersData.map((item) => ({
                            value: item.id,
                            title: item.name
                        }))
                    "
                    :placeholder="'Select a template'"
                    :design="'load-template'"
                    @update="setSelectedTemplate"
                >
                    <template #option="{ option }">
                        {{ option.title }}
                        <BaseImage :width="14" :height="16" :path="'delete.svg'" @click="remove(option)" />
                    </template>
                </BaseSelectBox>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :design="'outline'" :text="'Cancel'" :width="125" @click="close()" />
            <BaseButton :text="'Load'" :width="125" @click.stop="loadTemplate()" />
        </template>
    </BaseModal>
</template>

<style scoped>
.select {
    width: 100%;
}
</style>
