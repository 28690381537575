<script setup>
// todo in future for creating any page need be using only config data [{}]
import {onMounted, computed} from 'vue'

import '@/app/styles/index.css'

import {BaseLoader} from '@/shared/ui/Loaders'
import {TheHeader} from '@/widgets/TheHeader'
import {GeneralNotifications} from '@/entities/GeneralNotifications'
import {modalList} from '@/shared/data'

import {AuthorizationModel} from '@/features/Authorization'
import {ModalModel} from '@/entities/Modal'
import {UserModel} from '@/entities/User'
import {HeaderModel} from '@/entities/Header'
import {SessionModel} from '@/entities/Session'

window.addEventListener('beforeunload', function (event) {
    localStorage.removeItem('affiliate_token')
})

const authorizationStore = AuthorizationModel.useAuthorizationStore()
const modalStore = ModalModel.useModalStore()
const userStore = UserModel.useUserStore()
const headerStore = HeaderModel.useHeaderStore()
const sessionStore = SessionModel.useSessionStore()

const topBarIsVisible = computed(() => headerStore.topBarIsVisible)
const user = computed(() => userStore.user)
const modal = computed(() => ModalModel.getCurrentModal(modalStore))
</script>

<template>
    <div class="wrapper">
        <TheHeader v-if="topBarIsVisible && sessionStore.pageIsLoading && authorizationStore.loginState"/>
        <BaseLoader
            v-if="!sessionStore.pageIsLoading && user"
            :hidden="sessionStore.pageIsLoading"
            class="loader-box"
        />

        <router-view v-if="sessionStore.pageIsLoading"/>

        <GeneralNotifications/>
        <component :is="modalList[modal]" v-if="modal"></component>
    </div>
</template>

<style>
.loader-box {
    height: 100vh;
    width: 100%;
}
</style>
