import { computed } from 'vue'
import { SessionModel } from '@/entities/Session'
import {countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()
        const priorityList = {
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10
        }
        const brands = computed(() => SessionModel.getBrands(sessionStore))

        return {
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Row Status',
                    key: 'rowStatus',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Row ID',
                    key: 'numericId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Campaign',
                    key: 'campaign',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Brand Name',
                    key: 'brand.name',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country Source',
                    key: 'countries',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country Row',
                    key: 'country',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Amount of Leads',
                    key: 'amountOfLeads',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Amount of Deposits',
                    key: 'amountOfDeposits',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Source Name',
                    key: 'source.name',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Activity Hours',
                    key: 'activityHours',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Priority',
                    key: 'priority',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Weight',
                    key: 'weight',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Cap Type',
                    key: 'capType',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Daily Cap',
                    key: 'capDaily',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Live Caps',
                    key: 'liveCaps',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Enable Traffic',
                    key: 'status',
                    type: 'boolean',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'timeCreate',
                    placeholder: 'Data Range',
                    type: 'time',
                    multiselect: false,
                    search: false
                },
                {
                    field: 'source.countries',
                    placeholder: 'Select Country',
                    type: 'array',
                    data: (() => {
                        return countriesList()
                    })()
                },
                {
                    field: 'brandId',
                    placeholder: 'Select Brand',
                    type: 'array',
                    multiselect: false,
                    search: true,
                    data: sessionStore.brands || []
                },
                {
                    field: 'sourceId',
                    placeholder: 'Select Sources',
                    type: 'array',
                    multiselect: false,
                    search: true,
                    data: sessionStore.sources || []
                },
                {
                    field: 'priority',
                    placeholder: 'Select Priority',
                    type: 'array',
                    multiselect: false,
                    search: false,
                    data: priorityList
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'edit',
                    value: 'Edit'
                },
                {
                    key: 'delete_row',
                    value: 'Delete'
                }
            ]),
            searchList: computed(() => [
                {
                    value: 'Country Source',
                    key: 'globalFilter'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [
                        { key: 'status', label: 'Traffic', length: 2, buttonText: 'Change' },
                        { key: 'weight', label: 'Change Weight', length: 2, buttonText: 'Change' },
                        { key: 'priority', label: 'Change Priority', length: 2, buttonText: 'Change' },
                        { key: 'brand', label: 'Change Brand', length: 2, buttonText: 'Apply' },
                        { key: 'capDaily', label: 'Change Daily Cap', length: 2, buttonText: 'Change' },
                        { key: 'capType', label: 'Cap Type', length: 2, buttonText: 'Change' },
                        { key: 'delete_mass_rows', label: 'Delete' }
                    ]
                },
                {
                    key: 'status',
                    placeholder: 'Select Status',
                    options: [
                        { key: 0, label: 'Disable' },
                        { key: 1, label: 'Enable' }
                    ]
                },
                {
                    key: 'weight',
                    placeholder: 'Enter Weight value...',
                    type: 0
                },
                {
                    key: 'priority',
                    placeholder: 'Select Priority Value',
                    options: Object.entries(priorityList).map(([key, value]) => ({
                        key: key,
                        label: value
                    }))
                },
                {
                    key: 'capType',
                    placeholder: 'Select Role',
                    options: [{ key: 1, label: 'Lead' }]
                },
                {
                    key: 'brand',
                    placeholder: 'Select brand',
                    options: Object.entries(brands.value || []).map(([key, value]) => ({
                        key: key,
                        label: value
                    }))
                },
                {
                    key: 'capDaily',
                    placeholder: 'Enter Daily Cap value...',
                    type: 0
                }
            ]),
            actions: computed(() => ['reset', 'saveTemplate', 'customize']),
            priorityList: priorityList,
        }
    }
}
