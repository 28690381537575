<script setup>
import { computed } from 'vue'

import config from '@/pages/leads/configs/leads-analysis-tab.config'
const { cols, filters, tableId } = config.config()

import { BaseKeyValuePanel } from '@/shared/ui/Panels'
import { DataTable, FiltersList } from '@/features/Datatable'

import { LeadsModel } from '@/entities/Leads'
import {countriesList} from "@/shared/data/countriesList"

const store = LeadsModel.usestore()
const lead = computed(() => store.lead)
</script>

<template>
    <div class="leadAnalysis">
        <div class="info-panel-container">
            <BaseKeyValuePanel :title="'Reg Time'" :sub-title="'dd/mm/yy'" />
            <BaseKeyValuePanel :title="'Country'" :sub-title="countriesList()[lead.country.data]" />
            <BaseKeyValuePanel :title="'Affiliate'" :sub-title="'Testname (ID: 0000) '" />
        </div>
        <p class="search-info">Box Totals: 5 Rows, 6 Campaigns | Relevant: 1 Rows, 0 Campaigns</p>
        <FiltersList :filters="filters" :button-text="'Filter'" />
        <p class="showing">Showing: 8/8 Results</p>
        <DataTable :path="'leads/dt'" :table-id="tableId" :fields="cols" />
    </div>
</template>

<style scoped>
.leadAnalysis {
    padding-right: 5px;
}

.filters-list,
.info-panel-container {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
}

.search-info {
    margin-bottom: 20px;
}

.showing {
    color: var(--primary-gray);
}
</style>
