/**
 * Formats a timestamp into a string representing date and time, adjusted for a time zone offset.
 * @param {number} timestamp - The timestamp to format.
 * @param {number} offset - The time zone offset in minutes. Positive values for east of UTC, negative for west.
 * @param {boolean} onlyData - Indicates whether to include only the date part or both date and time. Defaults to false.
 * @returns {string} The formatted date and time string.
 */
export function timeFormat(timestamp, offset = 0, onlyData = false) {
    const adjustedTimestamp = (timestamp + ((offset * -1) * 60)) * 1000

    const date = new Date(adjustedTimestamp)

    const day = date.getUTCDate().toString().padStart(2, '0')
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = date.getUTCFullYear().toString().slice(-2)

    let timePart = ''
    if (!onlyData) {
        const hours = date.getUTCHours().toString().padStart(2, '0')
        const minutes = date.getUTCMinutes().toString().padStart(2, '0')
        const seconds = date.getUTCSeconds().toString().padStart(2, '0')
        timePart = ` ${hours}:${minutes}:${seconds} `
    }

    return `${timePart}${day}/${month}/${year}`
}
