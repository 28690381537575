<script setup>
import {computed, ref} from "vue"
import {BaseButton} from "@/shared/ui/Buttons"
import {checkOnRequiredFields} from "@/shared/lib/utils/validation"
import {BaseTestData} from "@/entities/Partners"
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {BaseGrid} from "@/shared/ui/GridSystem"
import {BaseInput, BaseSelectBox} from "@/shared/ui/Inputs"
import {BaseBorderPanel} from "@/shared/ui/Panels"
import {SessionModel} from '@/entities/Session'
import {getBrands} from "@/entities/Session/model"
import {IntegrationModel} from "@/entities/Integration"
import {TimePicker} from "@/features/Time"

const sessionStore = SessionModel.useSessionStore()
const integrationStore = IntegrationModel.useIntegrationStore()

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const brands = computed(() => getBrands(sessionStore))
const testData = ref({})

const brand = ref("")
const method = ref("")
const pageNumber = ref("")
const timeBack = ref("")
const period = ref({})

const requireFields = ref({
    brand: {data: brand, error: false},
    method: {data: method, error: false},
})

const setTime = (time) => {
    period.value.from = time.timestamp[0] / 1000
    period.value.to = time.timestamp[1] / 1000
}

const testIntegration = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (testData.value.loaded) {
        return
    }
    testData.value.request = null
    testData.value.response = null
    testData.value.loaded = true

    integrationStore.getLead({
        brandId: brand.value,
        ...period.value
    })
        .then(ans => {
            testData.value.request = JSON.stringify(ans.data.data.request, null, 4)
            testData.value.response = JSON.stringify(ans.data.data.response, null, 4)
        })
        .finally(() => {
            testData.value.loaded = false
        })
}
</script>

<template>
    <div class="single-tab">
        <BaseBorderPanel>
            <template #title>
                <h3 class="typo-heading-h3">General</h3>
            </template>
            <template #content>
                <p class="typo-heading-small-semi-bold-gray">
                    Require to fill all fields <br> <br>
                    You can use this tool to manually pull statuses or FTDs from a specific brand, the same as what the
                    system does automatically
                    <br> <br>
                    This can be used to make sure there’s no error when pulling request from the brand, and that we are
                    up-to-date with brand system <br> <br>
                    10 leads will be displayed
                </p>
                <BaseGrid :col="3" :width="'240px'">
                    <template #content>
                        <BaseSelectBox
                            v-model="method"
                            :options="[{
                                value: 'get',
                                title: 'Get Leads'
                            }]"
                            :error="requireFields.method.error"
                            :placeholder="'Select Method'"
                            :label="'Method*'"
                        />
                        <BaseSelectBox
                            v-model="brand"
                            :options="
                            Object.entries(brands).map(([key, value]) => ({
                                    value: key,
                                    title: value
                                }))"
                            :error="requireFields.brand.error"
                            :placeholder="'Select Brand'"
                            :label="'Brand*'"
                        />
                        <BaseInput
                            v-model="pageNumber"
                            :placeholder="'Enter Number'"
                            :label="'Page Number*'"
                        />
                    </template>
                </BaseGrid>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel>
            <template #title>
                <h3 class="typo-heading-h3">Time</h3>
            </template>
            <template #content>
                <BaseGrid :col="3" :width="'240px'">
                    <template #content>
                        <BaseSelectBox
                            :options="[]"
                            :placeholder="'Select Time'"
                            :label="'Select Time*'"
                        />
                        <div class="time-period">
                            <label>Period</label>
                            <TimePicker
                                :placeholder="'Select Period'"
                                @update="setTime"
                            />
                        </div>
                        <BaseInput
                            v-model="timeBack"
                            :placeholder="'Enter Time Back'"
                            :label="'Time Back*'"
                        />
                    </template>
                </BaseGrid>
            </template>
        </BaseBorderPanel>
        <BaseButton :text="'Test Lead'" :width="240" :load="testData.loaded" @click="testIntegration"/>
        <BaseTestData :title="'Lead Test'" :data="testData"/>
    </div>
</template>

<style scoped>
p {
    margin-bottom: 20px;
}

.mx-datepicker.mx-datepicker-range {
    width: 100% !important;
}
</style>
