import axios from 'axios'
import { AuthorizationModel } from '@/features/Authorization'

import { API } from '@/shared/config'

const RestService = {
    get(path, config = {}) {
        const authorizationStore = AuthorizationModel.useAuthorizationStore()
        return new Promise((resolve, reject) => {
            axios
                .get(`${API}/${path}`, config)
                .then((ans) => {
                    if (ans.data.error && ans.data.code === 401) {
                        authorizationStore.logout()
                    } else if (ans.data.error || ans.data.code === 400) {
                        reject(ans.data)
                    } else {
                        resolve(ans) // resolving ans and not ans.data.data, because it's ans.data.data on the receiving side (re-factor later)
                    }
                })
                .catch((axiosErrorObject) => {
                    console.log(`Axios error: ${axiosErrorObject}`)
                    reject()
                })
        })
    },

    post(path, payload, config = {}) {
        const authorizationStore = AuthorizationModel.useAuthorizationStore()

        const finalConfig = {
            ...config,
            headers: {
                ...config.headers
            }
        }

        return new Promise((resolve, reject) => {
            axios
                .post(`${API}/${path}`, payload, finalConfig)
                .then((ans) => {
                    if (ans.data.error && ans.data.code === 401) {
                        authorizationStore.logout()
                    } else if (ans.data.error) {
                        reject(ans.data)
                    } else {
                        resolve(ans) // resolving ans and not ans.data.data, because it's ans.data.data on the receiving side (re-factor later)
                    }
                })
                .catch((axiosErrorObject) => {
                    console.log(`Axios error: ${axiosErrorObject}`)
                    reject()
                })
        })
    },

    delete(path, payload, config = {}) {
        const authorizationStore = AuthorizationModel.useAuthorizationStore()

        const defaultHeaders = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0'
        }

        const finalConfig = {
            ...config,
            headers: {
                ...defaultHeaders,
                ...config.headers
            }
        }

        return new Promise((resolve, reject) => {
            axios
                .delete(`${API}/${path}`, payload, finalConfig)
                .then((ans) => {
                    if (ans.data.error && ans.data.code === 401) {
                        authorizationStore.logout()
                    } else if (ans.data.error || ans.data.code === 400) {
                        reject(ans.data)
                    } else {
                        resolve(ans) // resolving ans and not ans.data.data, because it's ans.data.data on the receiving side (re-factor later)
                    }
                })
                .catch((axiosErrorObject) => {
                    console.log(`Axios error: ${axiosErrorObject}`)
                    reject()
                })
        })
    },

    put(path, payload, config = {}) {
        const authorizationStore = AuthorizationModel.useAuthorizationStore()

        const defaultHeaders = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0'
        }

        const finalConfig = {
            ...config,
            headers: {
                ...defaultHeaders,
                ...config.headers
            }
        }

        return new Promise((resolve, reject) => {
            axios
                .put(`${API}/${path}`, payload, finalConfig)
                .then((ans) => {
                    if (ans.data.error && ans.data.code === 401) {
                        authorizationStore.logout()
                    } else if (ans.data.error || ans.data.code === 400) {
                        reject(ans.data)
                    } else {
                        resolve(ans) // resolving ans and not ans.data.data, because it's ans.data.data on the receiving side (re-factor later)
                    }
                })
                .catch((axiosErrorObject) => {
                    console.log(`Axios error: ${axiosErrorObject}`)
                    reject()
                })
        })
    }
}

export default RestService
