import {markRaw, computed} from "vue"

import {TabBrandResponse, TabConnections, TabGeneralInfo, TabLeadAnalysis, TabTransactions} from "@/pages/leads/tabs"
import {LeadsModel} from "@/entities/Leads"

export default {
    config: () => {
        const store = LeadsModel.usestore()
        const lead = computed(() => store.lead)

        return {
            tabs: markRaw([
                {
                    key: 'general',
                    value: 'General Info',
                    component: TabGeneralInfo
                },
                {
                    key: 'connections',
                    value: 'Connections',
                    component: TabConnections
                },
                {
                    key: 'transactions',
                    value: 'Transactions',
                    component: TabTransactions,
                    hide: 1
                },
                {
                    key: 'brand_response',
                    value: 'Brand Response',
                    component: TabBrandResponse,
                    hide: !lead.value.rawBrandRequest ? 1 : 0
                },
                {
                    key: 'lead_analysis',
                    value: 'Lead Analysis',
                    component: TabLeadAnalysis,
                    hide: 1
                }
            ])
        }
    }
}
