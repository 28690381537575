<script setup>
import {shallowRef} from "vue"

import {BasePage, PageTitle} from "@/shared/ui/Page"
import {BaseTabBox, BaseTabViewer, BaseTabViewerItem} from "@/shared/ui/Tabs"

import config from '@/pages/intergration-test/configs/integration-test.config'
import {usePageTitle} from "@/shared/lib/use/usePageTitle"

const {tabs} = config.config()
const title = usePageTitle('Testing Integration')

const activeTab = shallowRef(tabs[0])
const setActiveTab = (tab) => {
    activeTab.value = tab
}
</script>

<template>
    <BasePage class="integration-test">
        <PageTitle :icon="'page-icons/integration-test.svg'" :title="'INTEGRATION TESTING'"/>
        <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab"/>
        <BaseTabViewer>
            <template #content>
                <BaseTabViewerItem :component="activeTab.component"/>
            </template>
        </BaseTabViewer>
    </BasePage>
</template>

<style scoped>

</style>
