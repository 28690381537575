import {ENVIRONMENT} from "@/shared/config"
import {UserModel} from '@/entities/User'

export const getDefaultRoute = (store) => store.headerLinks.find((item) => item.defaultScreen)

export const getBaseHeaderLinks = (store) => {
    const { user } = UserModel.useUserStore()
    let baseHeaderLinks = store.headerLinks

    if (ENVIRONMENT === 'production' || ENVIRONMENT === 'demo') {
        baseHeaderLinks = store.headerLinks.filter((w) => w.prodAvailable)
    }

    return baseHeaderLinks
        .filter((item) => user.permissions.includes(item.permission) || !item.permission)
        .filter((item) => !item.defaultScreen)
        .sort((a, b) => a.order - b.order)
}
