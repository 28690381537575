<script setup>
import { computed, onUnmounted, onMounted, ref } from 'vue'

import { BaseKeyValuePanel } from '@/shared/ui/Panels'
import { BaseTextarea, BaseInput } from '@/shared/ui/Inputs'

import { LeadsModel } from '@/entities/Leads'
import { useAppStore } from '@/app/providers'

const store = LeadsModel.usestore()
const appStore = useAppStore()

const lead = computed(() => store.lead)

const payout = ref('')
const revenue = ref('')
const note = ref('')
const tableId = 'leads-table'

onMounted(() => {
    if (lead.value.note) {
        note.value = lead.value.note.data || ''
    }

    if (lead.value.payout) {
        payout.value = lead.value.payout.data || ''
    }

    if (lead.value.revenue) {
        revenue.value = lead.value.revenue.data || ''
    }
})

onUnmounted(() => {
    if (note.value && note.value !== lead.value.note.data) {
        appStore.update(
            {
                note: note.value
            },
            lead.value,
            tableId
        )
    }

    if (payout.value && payout.value !== lead.value.payout) {
        appStore.update(
            {
                payout: payout.value
            },
            lead.value,
            tableId
        )
    }

    if (revenue.value && revenue.value !== lead.value.revenue) {
        appStore.update(
            {
                revenue: revenue.value
            },
            lead.value,
            tableId
        )
    }
})
</script>

<template>
    <div class="general">
        <div class="items">
            <div class="item">
                <h6 class="typo-heading-h6">Note</h6>
                <BaseTextarea v-model="note" :placeholder="'Enter the description...'" :limit="250" :height="90" />
            </div>
            <div class="item">
                <h6 class="typo-heading-h6">Payout</h6>
                <BaseTextarea v-model="payout" :placeholder="'Enter the Payout... (Use digits)'" :height="90"/>
            </div>
            <div class="item">
                <h6 class="typo-heading-h6">Revenue</h6>
                <BaseTextarea v-model="revenue" :placeholder="'Enter the Payout... (Use digits)'" :height="90"/>
            </div>
        </div>
        <div class="items">
            <h6 class="typo-heading-h6">Free Parameters</h6>
            <div class="info-panel-container">
                <BaseKeyValuePanel :title="lead.fullname.data" :sub-еitle="lead.fullname.data" />
                <BaseKeyValuePanel :title="''" :sub-еitle="''" />
                <BaseKeyValuePanel :title="''" :sub-еitle="''" />
            </div>
        </div>
    </div>
</template>

<style scoped>
h2 {
    margin-bottom: 15px;
    color: var(--white-086);
}

.item {
    margin-bottom: 20px;
}

.input-box {
    width: 100%;
}

.info-panel {
    flex: 1 1 130px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    color: var(--white-086);
}


@media (max-width: 1000px) {
    .info-panel-container {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr;
    }

    .typo-heading-h6 {
        margin-bottom: 5px;
    }

    .info-panel-container {
        gap: 4px;
    }

    .info-panel {
        height: 63px;
        width: auto;
        font-size: 14px;
        color: var(--white-086);
    }
}
</style>
