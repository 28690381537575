<script setup>
import {BaseMultiselect, BaseToggle} from '@/shared/ui/Inputs'
import {BaseTextarea, BaseSelectBox, BaseInput} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'

import config from '@/pages/source/configs/source.config'

const {priorityList} = config.config()

import {capTypesList, timezonesList} from '@/shared/data'

import {computed, ref} from 'vue'

import {ModalModel} from '@/entities/Modal'
import {SessionModel} from '@/entities/Session'
import {SourcesModel} from '@/entities/Sources'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {useAppStore} from '@/app/providers'
import {checkOnRequiredFields} from '@/shared/lib/utils/validation'
import {BaseModal} from '@/shared/ui/Modal'
import {BaseBorderPanel} from '@/shared/ui/Panels'
import {UpdateActivityHours} from '@/features/Sources'
import {convertToSchedule} from '@/entities/Sources/model'
import {countriesList} from "@/shared/data/countriesList"

const modalStore = ModalModel.useModalStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const appStore = useAppStore()
const sourcesStore = SourcesModel.useSourcesStore()

const source = computed(() => sourcesStore.source)
const sources = computed(() => sourcesStore.sources)
const brands = computed(() => SessionModel.getBrands(sessionStore))
const requestIsPending = computed(() => appStore.requestIsPending)

const countriesSource = computed(() => {
    if (source.value.type === 1) {
        return countriesList()
    }
    return [...source.value.countries].sort()
})

const tips = computed(() => {
    if (source.value.type === 2) {
        return {}
    }

    return sources.value
        .filter(el => el.countries.length)
        .map((tip, key) => ({
            title: tip.name,
            key: key,
            value: tip.countries
        }))
})

const sourceID = computed(() => SourcesModel.getSource(sourcesStore).id)
const countriesData = ref([])

const scheduleTimezone = ref(0)
const priority = ref(0)
const brand = ref('')
const capType = ref('')
const capDaily = ref('')
const activityObject = ref({})
const description = ref('')
const status = ref(1)

const requireFields = ref({
    brand: {data: brand, error: false},
    country: {data: countriesData, error: false},
    capType: {data: capType, error: false},
    capDaily: {data: capDaily, error: false}
})

const close = () => {
    modalStore.closeModal()
}

const save = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    appStore.create(
        {
            priority: priority.value,
            brandId: brand.value,
            sourceId: sourceID.value,
            capType: capType.value,
            capDaily: capDaily.value,
            description: description.value,
            scheduleTimezone: scheduleTimezone.value,
            schedule: convertToSchedule(activityObject.value),
            status: status.value,
            countries: countriesData.value
        },
        'rows'
    )
}

const setCountries = (data) => {
    countriesData.value = data['countries']
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">Add new row</h4>
        </template>
        <template #content>
            <div class="create-row">
                <BaseBorderPanel>
                    <template #title>
                        <h5 class="typo-heading-h5">Basic</h5>
                    </template>
                    <template #content>
                        <div class="filters">
                            <BaseSelectBox
                                v-model="brand"
                                :label="'Brands*'"
                                :error="requireFields['brand'].error"
                                :options="
                                    Object.entries(brands).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :placeholder="'Select brand'"
                            />
                            <BaseMultiselect
                                v-if="Object.keys(countriesSource).length"
                                :field="'countries'"
                                :options="Object.entries(countriesSource).map(([key, value]) => ({
                                        key:  countriesList()[key] ? key : value,
                                        value: countriesList()[value] || value
                                    }))
                                "
                                :error="requireFields['country'].error"
                                :tips="tips"
                                :placeholder="'Select countries source'"
                                @change="setCountries"
                            >
                                <template #label>
                                    <label>Countries source*</label>
                                </template>
                                <template #tips-label>
                                    Sources:
                                </template>
                                <template #options-label>
                                    Countries:
                                </template>
                            </BaseMultiselect>
                            <BaseSelectBox
                                v-model="capType"
                                :error="requireFields['capType'].error"
                                :label="'Cap Type*'"
                                :options="
                                    Object.entries(capTypesList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :placeholder="'Select cap type'"
                            />
                            <BaseInput
                                v-model="capDaily"
                                :error="requireFields['capDaily'].error"
                                :padding="{ left: '10px' }"
                                :label="'Daily cap value*'"
                                :placeholder="'Enter daily cap value'"
                            />
                            <BaseSelectBox
                                v-model="priority"
                                :label="'Priority'"
                                :options="
                                    Object.entries(priorityList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :placeholder="'Select priority'"
                            />
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel class="time-zone">
                    <template #title>
                        <h5 class="typo-heading-h5">Time zone</h5>
                    </template>
                    <template #content>
                        <div class="item">
                            <BaseSelectBox
                                v-model="scheduleTimezone"
                                :is-sorted="false"
                                :options="
                                    Object.entries(timezonesList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :placeholder="'UTC'"
                                :width="110"
                                :label="'Choose time zone'"
                            />
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel class="activity">
                    <template #title>
                        <h5 class="typo-heading-h5">Activity hours</h5>
                    </template>
                    <template #content>
                        <UpdateActivityHours v-model="activityObject"/>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel class="info">
                    <template #label>
                        <h5 class="typo-heading-h5">Information</h5>
                    </template>
                    <template #content>
                        <BaseTextarea v-model="description" :placeholder="'Enter the description'" :height="91" :label="'Description'"/>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel :border="false">
                    <template #content>
                        <div class="traffic">
                            <h5 class="typo-heading-h5">Enable traffic</h5>
                            <div class="switch-box">
                                <BaseToggle :value="status" @change="(value) => (status = value)"/>
                                {{ status ? 'On' : 'Off' }}
                            </div>
                        </div>
                    </template>
                </BaseBorderPanel>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton class="cancel" :text="'Cancel'" :design="'outline'" @click="close()"/>
            <BaseButton class="save" :text="'Save'" :load="requestIsPending" @click="save()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.filters {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 260px);
    gap: 20px;
    place-items: center;
}

.traffic {
    display: flex;
    align-items: center;
    gap: 8px;
}

.time-zone {
    margin-bottom: 30px;
}

.time-zone .item {
    display: flex;
    align-items: center;
    gap: 30px;
}

.item {
    width: 100%;
}

.switch-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-box,
.select {
    width: 100%;
}

@media (max-width: 1000px) {
    .filters {
        grid-template-columns: 1fr;
    }

    .time-zone .item {
       flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }

    .time-zone .item .select {
        width: 100% !important;
    }

    .cancel {
        width: 100px !important;
    }

    .save {
        width: 100px !important;
    }
}
</style>
