<script setup>
import {computed, ref} from 'vue'
import {copyToClipboard} from '@/shared/lib/utils/copyToClipboard'
import {errors, requestStatusesList} from '@/shared/data'

import {BasePad} from '@/shared/ui/Panels'
import {BaseImage} from '@/shared/ui/Images'

import {LeadsModel} from '@/entities/Leads'

const store = LeadsModel.usestore()

const lead = computed(() => store.lead)

const isUsed = ref(lead.value.autologinUsed.data)
const request = JSON.stringify(lead.value.rawBrandRequest.data, null, 2)
const response = JSON.stringify(lead.value.rawBrandResponse.data, null, 2)
const status = lead.value.rawBrandResponse.data ? lead.value.rawBrandResponse.data.status : 'Error'

const login = `Login URL: ${lead.value.autologinUrl.data}`
</script>

<template>
    <div class="brand-response">
        <BasePad>
            <template #header>
                <h5 class="w-100 typo-heading-h5">Registration</h5>
                <p class="w-100 typo-heading-h5">Auto Login: {{ isUsed ? 'Used' : 'Not Used' }}</p>
                <div class="result w-100">
                    <p>Result:</p>
                    <span :class="status == '200' ? 'green' : 'red'">{{
                            status === 200 ? 'Successful' : errors[status] || status
                        }}</span>
                </div>
            </template>
            <template #request>
                <div class="box item">
                    <div class="box-title">
                        <h6 class="typo-heading-h6">Request:</h6>
                        <BaseImage
                            :width="18"
                            :height="18"
                            :path="'copy.svg'"
                            class="copy"
                            @click="copyToClipboard(request)"
                        />
                    </div>
                    <pre v-html="request"></pre>
                </div>
            </template>
            <template #response>
                <div class="box item">
                    <div class="box-title">
                        <h6 class="typo-heading-h6">Response:</h6>
                        <BaseImage
                            :width="18"
                            :height="18"
                            :path="'copy.svg'"
                            class="copy"
                            @click="copyToClipboard(response)"
                        />
                    </div>
                    <pre v-html="response"></pre>
                </div>
            </template>
            <template v-if="lead.autologinUrl.data" #auto-login>
                <div class="box login">
                    <div class="box-title">
                        <h6 class="typo-heading-h6">Auto Login:</h6>
                        <BaseImage
                            :width="18"
                            :height="18"
                            :path="'copy.svg'"
                            class="copy"
                            @click="copyToClipboard(login)"
                        />
                    </div>
                    <pre v-html="login"></pre>
                </div>
            </template>
        </BasePad>
    </div>
</template>

<style scoped>
.brand-response {
    padding-right: 5px;
}

.result {
    justify-content: flex-end;
    width: 100%;
}

.w-100 {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.typo-heading-h5 {
    color: var(--white-086);
}

pre {
    color: var(--white-064);
    word-break: break-word;
    font-weight: var(--general-font-weight);
    overflow: auto;
}

pre::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.box {
    overflow: auto;
    position: relative;
    background-color: var(--gray-1000);
    padding: 8px;
    height: 235px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.copy {
    cursor: pointer;
}

.box.login {
    height: 100%;
}

.result p {
    color: var(--white-048);
}

.box.item span {
    min-height: 95%;
}

.box span {
    padding-bottom: 20px;
    word-wrap: break-word;
}

.box.login span {
    padding-bottom: 30px;
}

.box-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 14px 0;
    justify-content: space-between;
}

h5,
h6 {
    color: var(--white-086);
}

.copy {
    transition: transform 0.2s ease-in-out;
}

.copy:active {
    transform: scale(0.8);
}

@media (max-width: 1000px) {
    .brand-response {
        padding-top: 16px;
    }

    .result {
        justify-content: flex-start;
    }
}
</style>
