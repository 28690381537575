export const getDatatableData = (store) => store.datatableData

export const getDatatableDataById = (store, id) => store.datatableData[id]

export const getTableFields = (store, id) => store.columns[id]

export const getSelectModeState = (store) => store.selectMode

export const getTableLoadState = (store) => store.tableIsLoaded

export const getLimit = (store) => store.limit

export const getTableId = (store) => store.tableId
