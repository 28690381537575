<script setup>
import { UserModel } from '@/entities/User'
import { AuthorizationModel } from '@/features/Authorization'
import { computed, ref } from 'vue'
import QrcodeVue from 'qrcode.vue'

import { BaseImage } from '@/shared/ui/Images'
import { BaseKeyValueBox } from '@/shared/ui/Panels'
import { BaseInput } from '@/shared/ui/Inputs'
import { BaseButton } from '@/shared/ui/Buttons'

import { OtpModel } from '@/entities/Otp'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'

import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import {countriesList} from "@/shared/data/countriesList"

const userStore = UserModel.useUserStore()
const otpStore = OtpModel.useOtpStore()

const authorizationStore = AuthorizationModel.useAuthorizationStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const title = usePageTitle('Profile')

const user = computed(() => userStore.user)
const otp = computed(() => otpStore.otp)

const companyName = ref(user.value.companyName || '')
const companyAddress = ref(user.value.companyAddress || '')
const companyRegNumber = ref(user.value.companyRegNumber || '')
const companyVat = ref(user.value.companyVat || '')
const password = ref('')
const confirmPassword = ref('')

const updatedInfo = ref(false)
const updatedPassword = ref(false)

const checkCode = ref('')
const otpIsActivated = ref(user.value.otp || 0)

const otpIsPending = ref(false)

const updatePassword = () => {
    if (!password.value || !confirmPassword.value) {
        generalNotificationsStore.showError({
            message: 'Enter password'
        })
        return
    }

    if (password.value !== confirmPassword.value) {
        generalNotificationsStore.showError({
            message: "Passwords don't match"
        })
        return
    }

    updatedPassword.value = true
    userStore
        .selfUpdate(user.value.id, {
            password: password.value
        })
        .then(() => {
            password.value = ''
            confirmPassword.value = ''

            generalNotificationsStore
                .showSuccess({
                    title: 'Changes Applied:',
                    message: `The password has been updated!<br>Page has been reloaded!`,
                    timeout: 2500
                })
                .then(() => {
                    authorizationStore.logout()
                })
            updatedPassword.value = false
        })
}

const updateInformation = () => {
    updatedInfo.value = true
    userStore
        .selfUpdate(user.value.id, {
            companyName: companyName.value,
            companyAddress: companyAddress.value,
            companyRegNumber: companyRegNumber.value,
            companyVat: companyVat.value
        })
        .then(() => {
            generalNotificationsStore.showSuccess({
                title: 'Changes Applied:',
                message: 'The profile has been updated!'
            })
            updatedInfo.value = false
        })
}

const updateOtp = () => {
    otpIsPending.value = true
    otpStore
        .updateOtp({
            code: parseInt(checkCode.value),
            enable: user.value.otp === 1 ? 0 : 1
        })
        .then(() => {
            otpIsActivated.value = !otpIsActivated.value
            generalNotificationsStore
                .showSuccess({
                    message: `2FA is ${otpIsActivated.value ? 'activated' : 'disabled'}.<br>Page has been reloaded!`
                })
                .then(() => {
                    authorizationStore.logout()
                })
        })
        .finally(() => {
            checkCode.value = ''
            otpIsPending.value = false
        })
}
</script>

<template>
    <div class="profile">
        <div class="info">
            <div class="head">
                <BaseImage :width="110" :height="110" :path="'avatar.svg'" />
                <h2 class="typo-heading-h2">{{ user.firstname }} {{ user.lastname }}</h2>
            </div>
            <div class="contact-info">
                <h6 class="typo-heading-h6">Contact Information</h6>
                <div class="items">
                    <BaseKeyValueBox :title="'First name: '" :info="user.firstname" />
                    <BaseKeyValueBox :title="'Last name: '" :info="user.lastname" />
                    <BaseKeyValueBox :title="'Username: '" :info="user.username" />
                    <BaseKeyValueBox :title="'Email: '" :info="user.email" />
                    <BaseKeyValueBox :title="'Country: '" :info="countriesList()[user.country]" />
                    <BaseKeyValueBox :title="'Telegram: '" :info="user.telegram" />
                    <BaseKeyValueBox :title="'Skype: '" :info="user.skype" />
                </div>
            </div>
            <div class="manager-info">
                <h6 class="typo-heading-h6">Manager Contact Information</h6>
                <div class="items">
                    <BaseKeyValueBox :title="'Email: '" :info="user.email" />
                    <BaseKeyValueBox :title="'Telegram: '" :info="user.username" />
                    <BaseKeyValueBox :title="'Skype: '" :info="user.email" />
                    <BaseKeyValueBox :title="'Country: '" :info="countriesList()[user.country]" />
                </div>
            </div>
        </div>
        <div class="user-info">
            <div class="details">
                <h4 class="typo-heading-h4">My Company</h4>
                <div class="company">
                    <BaseInput v-model="companyName" :placeholder="'Enter your Company Name'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">Company Name</label>
                        </template>
                    </BaseInput>
                    <BaseInput v-model="companyAddress" :placeholder="'Enter your Company Address'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">Company Address</label>
                        </template>
                    </BaseInput>
                    <BaseInput v-model="companyRegNumber" :placeholder="'Enter your Reg Number'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">Reg. Number</label>
                        </template>
                    </BaseInput>
                    <BaseInput v-model="companyVat" :placeholder="'Enter your VAT ID'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">VAT ID</label>
                        </template>
                    </BaseInput>
                </div>
                <div class="button-box">
                    <BaseButton :text="'Update'" :load="updatedInfo" :width="160" @click="updateInformation()" />
                </div>
            </div>
            <div class="password">
                <h4 class="typo-heading-h4">Password Change</h4>
                <div class="password-change">
                    <BaseInput v-model="password" :type="'password'" :placeholder="'Enter Password'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">New Password</label>
                        </template>
                    </BaseInput>
                    <BaseInput v-model="confirmPassword" :type="'password'" :placeholder="'Confirm Password'">
                        <template #label>
                            <label class="typo-heading-small-semi-bold-gray">Confirm Password</label>
                        </template>
                    </BaseInput>
                    <BaseButton :text="'Update'" :load="updatedPassword" :width="160" @click="updatePassword()" />
                </div>
                <p class="typo-heading-small-semi-bold-gray">
                    *Must be at least 6 characters and contain a lowercase character, uppercase character and a number.
                </p>
            </div>
            <div class="authentication" :class="{ 'otp-is-active': user.otp }">
                <div class="content">
                    <div class="box">
                        <h4 class="typo-heading-h4">Two Step Authentication (2FA)</h4>
                        <p class="typo-heading-small-semi-bold-gray">
                            *Please enter your Google Authenticator details here
                        </p>
                        <BaseInput v-if="!user.otp && otp" v-model="otp.base32" :disabled="true">
                            <template #label>
                                <p class="typo-heading-small-semi-bold-gray">
                                    Activation code for Google Authenticator
                                </p>
                            </template>
                        </BaseInput>
                        <BaseInput v-model="checkCode" :type="'number'" :placeholder="'Enter your code'">
                            <template #label>
                                <p class="typo-heading-small-semi-bold-gray">
                                    Input your generated Google Authenticator code here
                                </p>
                            </template>
                        </BaseInput>
                    </div>
                    <BaseButton
                        :class="{ red: user.otp }"
                        :load="otpIsPending"
                        :text="!user.otp ? 'Activate 2FA' : 'Disable 2FA'"
                        @click="updateOtp"
                    />
                </div>
                <div v-if="!user.otp && otp && Object.keys(otp).length" class="qr">
                    <p>Your QR Code</p>
                    <QrcodeVue :level="'L'" :value="otp.otpauth_url" :size="200" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.profile {
    padding: 80px;
    display: grid;
    grid-template-columns: 480px 1fr;
    gap: 20px;
}

.info,
.details,
.password,
.authentication {
    border-radius: var(--general-border-radius);
    border: var(--general-border) solid var(--purple);
}

.details,
.password,
.authentication {
    padding: 22px 20px;
}

.info {
    padding: 55px 65px 20px;
    text-align: center;
}

.head h4 {
    margin-top: 10px;
}

.company,
.bank-details,
.contact-info .items,
.manager-info .items,
.head h2 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
}

.manager-info .items,
.contact-info .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 0;
    margin-top: 20px;
}

.notes h6 {
    margin-bottom: 20px;
}

h4 {
    margin-bottom: 20px;
}

p {
    margin-top: 20px;
}

.input-box {
    width: 100%;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.password-change,
.bank-details,
.company {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Adjust minmax values as needed */
    gap: 20px;
    place-items: end self-start;
}

.details .button-box {
    text-align: right;
}

.authentication {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: flex-start center;
}

.authentication.otp-is-active .content {
    grid-column: 1 / 3;
    width: 100%;
    padding-right: 0;
    border: none;
}

.authentication .content {
    padding-right: 40px;
    border-right: var(--general-border) solid var(--white-08);
}

.authentication .box {
    border-bottom: var(--general-border) solid var(--white-08);
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.authentication .input-box p {
    margin: 20px 0 15px;
}

.qr p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.authentication button {
    width: 100% !important;
}
</style>
