<script setup>
import {computed, onMounted, onUnmounted, ref, defineProps} from 'vue'

import {ModalModel} from '@/entities/Modal'

import {BaseImage} from '@/shared/ui/Images'
import {ScrollViewer} from '@/shared/ui/Blocks'
import {startModalScrollingFrom} from '@/shared/lib/consts'

const props = defineProps({
    title: {
        type: String
    },
    padding: {
        type: Object,
        default: () => ({
            top: '24px',
            right: '24px',
            bottom: '24px',
            left: '24px'
        })
    },
    border: {
        type: Boolean,
        default: false
    },
    width: {
        type: String
    },
    actionButtons: {
        type: String,
        default: 'row'
    }
})

onMounted(() => {
    const modal = modalFocus.value
    const modalHeight = modal.getBoundingClientRect().height

    for (const child of modal.children) {
        const childHeight = child.clientHeight
        if (childHeight >= 300 && modalHeight < 700) {
            scrollViewer.value = true
            break
        }
    }

    if (modalHeight >= startModalScrollingFrom) {
        scrollViewer.value = true
    }

    const firstInput = modalFocus.value.querySelector('input')
    if (firstInput) {
        firstInput.focus()
    }
})

const modalStore = ModalModel.useModalStore()

const modalVisible = computed(() => modalStore.modalVisible)

const modalFocus = ref(null)
const scrollViewer = ref(false)

const setPadding = computed(() => {
    if (scrollViewer.value) {
        return '0px'
    }
    const p = props.padding
    return `${p.top ?? '24px'} ${p.right ?? '24px'} ${p.bottom ?? '24px'} ${p.left ?? '24px'}`
})

const close = (event) => {
    const isInsideModalContent = event.target.closest('.modal-content')
    const isCloseButtonClicked = event.target.closest('.close')

    if (!isInsideModalContent || isCloseButtonClicked) {
        modalStore.closeModal()
    }
}
</script>

<template>
    <div v-show="modalVisible" class="modal-box" @click="close" @keydown.esc="close">
        <div
            ref="modalFocus"
            class="modal-content"
            :style="{ padding: setPadding, width: width, overflow: scrollViewer ? 'auto' : '' }"
        >
            <div v-if="$slots['title'] || title" class="close-header">
                <slot name="title">
                    <h4 v-if="title" class="typo-heading-h4">{{ title }}</h4>
                </slot>
                <BaseImage class="close" :path="'close-gray.svg'" :height="11" :width="11"/>
            </div>
            <slot name="content"></slot>

            <div v-if="$slots['action-buttons']" class="action-buttons" :class="[actionButtons]">
                <slot name="action-buttons"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 105;
    padding: 0 24px;
}

.modal-content {
    position: relative;
    background-color: var(--background);
    border-radius: var(--general-border-radius);
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    min-width: 315px;
    padding: 24px !important;
    border: var(--general-border) solid var(--purple);
    overflow: hidden;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.close-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
}

.close-header .close {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.header img {
    cursor: pointer;
}

.title {
    margin-bottom: 35px;
}

.action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 24px;
}

.action-buttons.row {
    flex-direction: row;
}

.action-buttons.col {
    flex-direction: column;
}
</style>
