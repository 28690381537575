<script setup>
import {computed, ref} from 'vue'

import {ModalModel} from '@/entities/Modal'

import {BaseButton} from '@/shared/ui/Buttons'
import {BaseImage} from '@/shared/ui/Images'
import {BaseInput} from '@/shared/ui/Inputs'
import {copyToClipboard} from '@/shared/lib/utils/copyToClipboard'
import {PartnersModel} from '@/entities/Partners'
import {BaseModal} from '@/shared/ui/Modal'

const modalStore = ModalModel.useModalStore()
const partnersStore = PartnersModel.usePartnersStore()

const modalData = computed(() => modalStore.modalData)
const reset = ref(false)
const password = ref('')

const add = () => {
    console.log('add')
}

const copy = () => {
    let text = ''
    for (const item in modalData.value) {
        if (item === 'password') {
            continue
        }

        text += `${item}: ${modalData.value[item]}\n`
    }
    copyToClipboard(text)
    close()
}

const cancel = () => {
    close()
}

const close = () => {
    modalStore.closeModal()
}

const resetPassword = () => {
    partnersStore
        .updatePartnerDetails({
            id: modalData.value.id,
            password: password.value
        })
        .then(() => {
            reset.value = false
        })
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">API details of partners {{ modalData.username }}</h4>
        </template>
        <template #content>
            <div class="api-details">
                <div class="fields">
                    <div class="field">
                        <p>Select owner:</p>
                        <p>{{ modalData.username }}</p>
                    </div>
                    <div class="password-field">
                        <p>Password:</p>
                        <div class="reset">
                            <div v-if="!reset" class="reset-box">
                                <p>⚪⚪⚪⚪⚪⚪⚪</p>
                                <div class="reset-action" @click.stop="reset = true">
                                    <BaseImage :path="'reset-password.svg'" :width="18" :height="18"/>
                                </div>
                            </div>
                            <div v-if="reset" class="password-field">
                                <BaseInput
                                    v-model="password"
                                    :placeholder="'Enter password'"
                                    :padding="{ top: '5px', bottom: '5px', left: '10px' }"
                                >
                                    <template #action>
                                        <BaseButton
                                            class="approve"
                                            :icon="'approve-small.svg'"
                                            :width="30"
                                            :design="'transparent'"
                                            :height="30"
                                            :icon-height="7"
                                            :icon-width="10"
                                            @click="resetPassword()"
                                        />
                                        <BaseButton
                                            class="cancel"
                                            :icon="'close-gray.svg'"
                                            :width="30"
                                            :design="'transparent'"
                                            :height="30"
                                            :icon-height="7"
                                            :icon-width="10"
                                            @click.stop="reset = false"
                                        />
                                    </template>
                                </BaseInput>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <p>API Token:</p>
                        <p>{{ modalData.token }}</p>
                    </div>
                    <div class="field">
                        <p>URL:</p>
                        <p>{{ modalData.url }}</p>
                    </div>
                    <div class="field">
                        <p>API documents:</p>
                        <div class="value">
                            <p>{{ modalData.documentation }}</p>
                            <div class="copy" @click.stop="copyToClipboard(modalData.documentation)">
                                <BaseImage
                                    :path="'copy.svg'"
                                    :width="19"
                                    :height="22"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :design="'outline'" :width="100" @click="cancel()"/>
            <BaseButton :text="'Copy and Close'" :width="140" @click="copy()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.api-details {
    max-width: 800px;
    overflow: auto;
}

.field {
    display: grid;
    width: 100%;
    grid-template-columns: 100px 1fr;
    align-items: start;
    gap: 8px;
    margin-bottom: 21px;
}

.field p {
    font-size: 14px;
}

.value,
.field p:first-child {
    color: var(--white-064);
}

.value p,
.field p:last-child {
    word-break: break-all;
    width: fit-content;
}

.value p {
    color: var(--white) !important;
}

.field:last-child {
    margin-bottom: 35px;
}

.reset {
    cursor: pointer;
    display: flex;
    place-items: center;
    gap: 7px;
    place-content: end;
    width: 100%;
    margin-bottom: 21px;
}

.input-box {
    width: 100% !important;
}

.reset span {
    text-decoration: underline;
}

.reset-box {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    background: var(--gray-600);
    border-radius: 8px;
    font-size: 8px;
    align-items: center;
    height: 40px;
    justify-content: space-between;
}

.password-field {
    display: flex;
    gap: 4px;
    align-items: baseline;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
}

.reset-box p {
    font-size: 8px !important;
}

.password-field p {
    font-size: 12px;
    font-weight: 400;
}

.password-field span {
    white-space: nowrap;
    text-decoration: underline;
    color: var(--primary-gray);
    font-weight: 500;
    font-size: 12px;
}

.approve {
    border-radius: 0 var(--general-border-radius) var(--general-border-radius) 0;
}

.value {
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 7px;
    place-items: start stretch;
    color: var(--white);
}

.copy {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--white);
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 1000px) {
    h4 {
        max-width: 200px;
    }

    .value {
        place-items: center stretch;
    }
}
</style>
