<script setup>
import {onMounted, ref} from 'vue'
import {useRouter} from 'vue-router'
import {checkOnRequiredFields, isValidEmail} from '@/shared/lib/utils/validation'

import config from '@/pages/user-registration/configs/user-registration.config'

const {fields} = config.config()

import {BaseCheckBox, BaseInput, BaseSelectBox} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseImage} from '@/shared/ui/Images'

import {PartnersModel} from '@/entities/Partners'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {HeaderModel} from '@/entities/Header'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import { trafficSourceList, verticalsList} from "@/shared/data"
import {countriesList} from "@/shared/data/countriesList"

const router = useRouter()
const title = usePageTitle('Registration')

const headerStore = HeaderModel.useHeaderStore()
const partnersStore = PartnersModel.usePartnersStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

onMounted(() => {
    headerStore.setTopBarVisibility(false)
    const {query} = router.currentRoute.value
    if (!Object.keys(query).length) {
        router.push({name: 'partners'})
    }
})

const firstname = ref('')
const username = ref('')
const lastname = ref('')
const companyName = ref('')
const email = ref('')
const phone = ref('')

const password = ref('')
const confirmPassword = ref('')

const domain = ref('')
const country = ref('')
const telegram = ref('')
const trafficSource = ref('')
const skype = ref('')
const vertical = ref('')

const terms = ref(false)
const termsNotChoose = ref(false)
const registered = ref(false)

const requireFields = ref({
    password: {data: password, error: false},
    confirmPassword: {data: confirmPassword, error: false},
    email: {data: email, error: false},
    username: {data: username, error: false}
})

const setTerms = () => {
    terms.value = !terms.value
    termsNotChoose.value = false
}

const register = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (!isValidEmail(email.value)) {
        generalNotificationsStore.showError({
            message: 'Invalid Email'
        })
        requireFields.value.email.error = true
        return
    }

    requireFields.value.email.error = false

    if (password.value !== confirmPassword.value) {
        generalNotificationsStore.showError({
            message: "Passwords don't match"
        })
        return
    }

    if (!terms.value) {
        termsNotChoose.value = true
        return
    }

    partnersStore
        .register({
            firstname: firstname.value,
            username: username.value,
            lastname: lastname.value,
            companyName: companyName.value,
            email: email.value,
            phone: phone.value,
            password: password.value,
            domain: domain.value,
            country: country.value,
            telegram: telegram.value,
            trafficSource: trafficSource.value,
            vertical: vertical.value,
        })
        .then(() => {
            registered.value = true
        })
}
</script>

<template>
    <div class="user-register" :class="{ registered: registered }">
        <div v-if="!registered" class="content">
            <div class="page-title">
                <h2 class="typo-heading-h1">Welcome To</h2>
                <h1>TraffixAI</h1>
            </div>
            <div class="title">
                <h3 class="mob-tablet">General information*</h3>
                <span class="typo-heading-h6">All fields with * symbol are required to fill</span>
            </div>
            <div class="section">
                <BaseInput
                    v-model="firstname"
                    :placeholder="'Enter first name'"
                >
                    <template #label>
                        <label>First name</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="lastname"
                    :placeholder="'Enter last name'"
                >
                    <template #label>
                        <label>Last name</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="email"
                    :placeholder="'Enter email'"
                    :error="requireFields['email'].error"
                >
                    <template #label>
                        <label>Email*</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="username"
                    :placeholder="'Enter username'"
                    :error="requireFields['username'].error"
                >
                    <template #label>
                        <label>Username*</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="companyName"
                    :placeholder="'Enter company name'"
                >
                    <template #label>
                        <label>Company name</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="phone"
                    :type="'number'"
                    :placeholder="'Enter phone number'"
                >
                    <template #label>
                        <label>Phone number</label>
                    </template>
                </BaseInput>
            </div>
            <div class="title">
                <h3 class="mob-tablet">Password form</h3>
            </div>
            <div class="section">
                <BaseInput
                    v-model="password"
                    :error="requireFields['password'].error"
                    :type="'password'"
                    :placeholder="'Enter password'"
                >
                    <template #label>
                        <label>Password*</label>
                    </template>
                </BaseInput>
                <BaseInput
                    v-model="confirmPassword"
                    :error="requireFields['confirmPassword'].error"
                    :type="'password'"
                    :placeholder="'Confirm password'"
                >
                    <template #label>
                        <label>Confirm password*</label>
                    </template>
                </BaseInput>
            </div>
            <div class="title">
                <h3 class="mob-tablet">Additional information</h3>
            </div>
            <div class="section">
                <BaseInput v-model="domain" :placeholder="'Enter domain'">
                    <template #label>
                        <label>Domain</label>
                    </template>
                </BaseInput>
                <BaseSelectBox
                    v-model="country"
                    :placeholder="'Select country'"
                    :placement="'top'"
                    :options="
                            Object.entries(countriesList()).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                >
                    <template #label>
                        <label>Country</label>
                    </template>
                </BaseSelectBox>
                <BaseInput v-model="telegram" :placeholder="'Enter telegram'">
                    <template #label>
                        <label>Telegram</label>
                    </template>
                </BaseInput>
                <BaseInput v-model="skype" :placeholder="'Enter skype'">
                    <template #label>
                        <label>Skype</label>
                    </template>
                </BaseInput>
                <BaseSelectBox
                    v-model="trafficSource"
                    :placeholder="'Select traffic'"
                    :placement="'top'"
                    :options="
                            Object.entries(trafficSourceList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                >
                    <template #label>
                        <label>Traffic sources</label>
                    </template>
                </BaseSelectBox>
                <BaseSelectBox
                    v-model="vertical"
                    :placeholder="'Select vertical'"
                    :placement="'top'"
                    :options="
                            Object.entries(verticalsList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                >
                    <template #label>
                        <label>Verticals</label>
                    </template>
                </BaseSelectBox>
            </div>
            <div class="terms" @click="setTerms">
                <BaseCheckBox :class="{ red: termsNotChoose }" :checked="terms" :value="terms" @checked="setTerms"/>
                <p>By clicking Sing up, you agree to our Terms of Service and Privacy policy</p>
            </div>
            <BaseButton :text="'Sign in'" @click="register()"/>
        </div>
        <div v-else class="user-registered">
            <div class="box">
                <h2 class="typo-heading-h2">
                    Congratulations! <br/>
                    You are now a part of
                </h2>
                <BaseImage :height="120" :width="400" :path="'logo/logo-white.png'"/>
                <p>
                    Your information being verified. <br/>
                    Once information will be verified our Manager will contact you.
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-title {
    margin-bottom: 24px;
}

h2 {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
}

h1 {
    font-size: 48px;
    font-weight: 800;
}

.user-register {
    padding: 40px 20px;
    background: linear-gradient(#9D95FF, #4F6BE8, #9D95FF);
    width: 100%;
    min-height: 100%;
}

.user-register.registered {
    background: url('@/shared/assets/auth-bg.svg') no-repeat 50% 50% / cover;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--gray-1000);
    border-radius: 8px;
    padding: 24px;
    width: 100%;
}

.title {
   width: 100%;
    text-align: left;
    margin-bottom: 16px;
}

.title span {
    margin-top: 5px;
}

.section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
    margin-bottom: 24px;
}

.select,
.input-box {
    width: 100% !important;
}

.terms {
    display: grid;
    place-items: center stretch;
    grid-template-columns: 18px 1fr;
    gap: 10px;
    cursor: pointer;
    margin: 8px 0 32px;
    width: 100%;
}

.terms p {
    font-size: 12px;
}

.button-box {
    width: 100% !important;
}

.check-box.red {
    border: var(--general-border) solid var(--light-red);
}

.warning-box {
    text-align: center;
}

.box {
    text-align: center;
    padding-top: 100px;
}

.box p {
    font-size: var(--general-font-size);
}

button {
    color: var(--white);
    font-size: 16px;
}

@media (max-width: 768px) {
    .section {
        grid-template-columns: 1fr;
    }
}
</style>
