import BrandsPage from '@/pages/brands'
import BrandPage from '@/pages/brand'
export const route = {
    path: '/brands',
    meta: {
        permission: 'brands'
    },
    children: [
        {
            path: '',
            name: 'brandsPage',
            component: BrandsPage
        }
    ]
}
