/**
 * Converts a string to a UUID-like format by appending a random hexadecimal string.
 * @param {string} str - The input string to convert.
 * @returns {string} The string in UUID-like format.
 */
export const convertStringToUuid = (str) => {
    const randomHex = Math.random().toString(16).slice(2, 10)
    return `${str.replace(/-/g, '')}-${randomHex}`
}

/**
 * Converts a UUID-like string to a plain string by removing hyphens.
 * @param {string} uuid - The UUID-like string to convert.
 * @returns {string} The plain string without hyphens.
 */
export const convertUuidToString = (uuid) => {
    return uuid.replace(/-/g, '')
}
