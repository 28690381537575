<script setup>
/**
 * BaseLoader
 * ------------------------------
 * Description:
 * A component for displaying a loader with customizable type and padding.
 *
 * Usage:
 * <BaseLoader
 *    :type="'spinner'" // Specify the type of loader (e.g., 'spinner', 'bar', etc.)
 *    :padding="{ top: '20px', bottom: '20px' }" // Customize the padding around the loader
 *    :hidden="false" // Specify whether the loader is initially hidden
 * />
 *
 * Props:
 * - type: The type of loader to display (e.g., 'spinner', 'bar', etc.).
 * - padding: An object specifying the padding around the loader (e.g., { top: '20px', bottom: '20px' }).
 * - hidden: A boolean indicating whether the loader is initially hidden.
 */

import { defineProps } from 'vue'

const props = defineProps({
    type: {
        type: String,
        default: ''
    },
    padding: {
        type: Object
    },
    hidden: {
        type: Boolean,
        default: false
    }
})

const getStyles = () => {
    let paddings = {
        top: props.padding?.top ? props.padding.top : 0,
        right: props.padding?.right ? props.padding.right : 0,
        bottom: props.padding?.bottom ? props.padding.bottom : 0,
        left: props.padding?.left ? props.padding.left : 0
    }

    return `padding: ${paddings.top}px ${paddings.right}px ${paddings.bottom}px ${paddings.left}px;`
}
</script>

<template>
    <div class="loader-wrapper" :class="{ hidden: props.hidden }" :style="getStyles()">
        <div :class="`loader ${props.type ? props.type : ''}`">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<style scoped>
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    position: relative;
    width: 32px;
    height: 32px;
}

.loader.big {
    width: 44px;
}

.loader.big span:nth-child(3) {
    width: 6px;
    height: 6px;
    animation: anim-big-1 0.8s ease infinite;
}

.loader.big span:nth-child(1) {
    width: 6px;
    height: 6px;
    animation: anim-big-3 0.8s ease infinite;
}

.loader.big span:nth-child(2) {
    width: 16px;
    height: 16px;
    animation: anim-big-2 0.8s ease infinite;
}

span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transform-origin: top left;
}

span:nth-child(3) {
    width: 4px;
    height: 4px;
    background: var(--deep-purple);
    opacity: 0.5;
    left: 0;
    animation: anim-1 0.8s ease infinite;
}

span:nth-child(2) {
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #9483ff;
    animation: anim-2 0.8s ease infinite;
    transform-origin: top right;
}

span:nth-child(1) {
    width: 4px;
    height: 4px;
    background: var(--deep-purple);
    opacity: 0.5;
    right: 0;
    animation: anim-3 0.8s ease infinite;
}

.hidden {
    visibility: hidden;
}

@keyframes anim-1 {
    66.666% {
        transform: scale(3) translate(3px, -50%);
        background: #9483ff;
        opacity: 1;
    }
    100% {
        transform: scale(3) translate(3px, -50%);
        background: #9483ff;
        opacity: 1;
    }
}

@keyframes anim-2 {
    66.666% {
        transform: scale(0.333) translate(11px, -50%);
        background: var(--deep-purple);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.333) translate(11px, -50%);
        background: var(--deep-purple);
        opacity: 0.5;
    }
}

@keyframes anim-3 {
    66.666% {
        transform: translate(-28px, -50%);
    }
    100% {
        transform: translate(-28px, -50%);
    }
}

@keyframes anim-big-1 {
    66.666% {
        transform: scale(2.666) translate(5px, -50%);
        background: #9483ff;
        opacity: 1;
    }
    100% {
        transform: scale(2.666) translate(5px, -50%);
        background: #9483ff;
        opacity: 1;
    }
}

@keyframes anim-big-2 {
    66.666% {
        transform: scale(0.333) translate(16px, -50%);
        background: var(--deep-purple);
        opacity: 0.5;
    }
    100% {
        transform: scale(0.333) translate(16px, -50%);
        background: var(--deep-purple);
        opacity: 0.5;
    }
}

@keyframes anim-big-3 {
    66.666% {
        transform: translate(-38px, -50%);
    }
    100% {
        transform: translate(-38px, -50%);
    }
}
</style>
