import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const saveTemplate = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.post(`templates`, {
            name: data.name,
            columns: data.columns,
            table: data.table
        }).catch((errorObject) => {
            generalNotificationsStore.show(errorObject)
            reject(errorObject)
        })
    })
}

const updateTemplate = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.put(`template/${data.id}`, {
            columns: data.visibleColumns
        })
            .then(() => resolve())
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
                reject(errorObject)
            })
    })
}

const removeTemplate = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.delete(`template/${id}`)
            .then(() => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
                reject(errorObject)
            })
    })
}

const getTemplates = () => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.get('templates')
            .then((ans) => {
                resolve(ans)
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
                reject(errorObject)
            })
    })
}

export const api = {
    saveTemplate,
    updateTemplate,
    removeTemplate,
    getTemplates
}
