<script setup>
import {computed, shallowRef} from 'vue'

import config from '@/pages/partner/configs/partner.config'

const {tabs} = config.config()

import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseTabViewer, BaseTabViewerItem} from '@/shared/ui/Tabs'

import {ModalModel} from '@/entities/Modal'
import {PartnersModel} from '@/entities/Partners'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {BaseModal} from "@/shared/ui/Modal"
import {BaseButton} from "@/shared/ui/Buttons"

const modalStore = ModalModel.useModalStore()
const partnersStore = PartnersModel.usePartnersStore()

const partner = computed(() => partnersStore.partner)

let title

if (partner.value) {
    title = usePageTitle(partner.value['firstname'].data + ' ' + partner.value['lastname'].data)
}

const activeTab = shallowRef(tabs[0])

const availableActionPartners = ['entitles', 'brands', 'paymentPlan', 'restrictions']
const actionAvailable = computed(() => availableActionPartners.includes(activeTab.value.key))

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const makeAction = () => {
    switch (activeTab.value.key) {
        case 'entitles':
            modalStore.setModal('createEntity')
            break
        case 'brands':
            modalStore.setModal('createPartnerBrand')
            break
        case 'paymentPlan':
            modalStore.setModal('createPlan')
            break
        case 'restrictions':
            break
        default:
            break
    }
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">
                {{ partner ? partner.firstname.data + ' ' + partner.lastname.data : '' }}
                <BaseButton
                    v-if="actionAvailable"
                    :icon="'plus.svg'"
                    :width="40"
                    :height="40"
                    :border-radius="'4px'"
                    @click="makeAction"
                />
            </h4>
        </template>
        <template #content>
            <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab"/>
            <BaseTabViewer>
                <template #content>
                    <BaseTabViewerItem :load="!!partner" :component="activeTab.component"/>
                </template>
            </BaseTabViewer>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.tabs {
    justify-content: space-between;
}

@media (max-width: 1000px) {
    button {
        display: none;
    }
}
</style>
