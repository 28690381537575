import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const register = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.post(`partners`, { ...data })
            .then((ans) => {
                resolve(ans)
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const getPartner = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.get(`partner/${id}`)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const getPartnerDetail = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.get(`partner/${id}/api`)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const updatePartnerDetails = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.put(`partner/${data.id}`, {
            userInitPassword: data.userInitPassword
        })
            .then(() => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

export const api = {
    register,
    getPartner,
    getPartnerDetail,
    updatePartnerDetails
}
