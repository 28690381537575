import { defineStore } from 'pinia'
import { api } from '../api'

export const useBrandsStore = defineStore('brands', {
    state: () => ({
        brand: null
    }),
    actions: {
        setBrand(data) {
            this.brand = data
        },

        getBrand(id) {
            return new Promise((resolve) => {
                api.getBrand(id).then((data) => {
                    this.brand = {}
                    for (const item in data.data.data) {
                        this.brand[item] = {}
                        this.brand[item].data = data.data.data[item]
                    }
                    resolve()
                })
            })
        }
    }
})
