import { typesList } from '@/shared/data'
import { computed } from 'vue'

export default {
    config: () => {
        return {
            tableId: 'lead-analysis-table',
            cols: computed(() => [
                {
                    label: 'Status',
                    key: 'status',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Skip Reason',
                    key: 'skipReason',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Row ID',
                    key: 'rowId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Campaign',
                    key: 'campaign',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country',
                    key: 'country',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Activity Hours',
                    key: 'activityHours',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Priority',
                    key: 'priority',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Weight',
                    key: 'weight',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Cap Type',
                    key: 'capType',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'timeCreate',
                    placeholder: 'Data Range',
                    type: 'time',
                    multiselect: false,
                    search: false
                },
                {
                    field: 'role',
                    placeholder: 'Select Role',
                    type: 'select',
                    multiselect: false,
                    search: false,
                    data: (() => {
                        return typesList
                    })()
                }
            ])
        }
    }
}
