import UserProfilePage from '@/pages/user-profile'
import UserRegistrationPage from '@/pages/user-registration'
export const route = {
    path: '/user',
    children: [
        {
            path: 'profile',
            name: 'profilePage',
            component: UserProfilePage
        },
        {
            path: 'registration',
            name: 'registrationPage',
            meta: {
                isPublic: true
            },
            component: UserRegistrationPage
        }
    ]
}
