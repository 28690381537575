<script setup>
/**
 * DataTable
 * ------------------------------
 * Description:
 * A Vue component for displaying tabular data with various options like field management, sorting, and actions.
 *
 * Usage:
 * <DataTable
 *    :fields="[
 *        { label: 'Name', key: 'name' },
 *        { label: 'Age', key: 'age' },
 *        { label: 'Email', key: 'email' }
 *    ]"
 *    :path="'/data'"
 *    :tableId="'dataTable'"
 *    :isDraggable="false"
 *    :optionList="[
 *        { key: 'select', value: 'Select' },
 *        { key: 'delete', value: 'Delete', class: 'red' }
 *    ]"
 *    :actionList="[]"
 *    :order="'timeCreate'"
 *    @open="handleOpen"
 *    @action="handleAction"
 * />
 *
 * Props:
 * - fields: An array of objects representing table fields. Each object should have a 'label' and a 'key'.
 * - path: The path for data fetching.
 * - tableId: The ID of the table.
 * - isDraggable: A boolean indicating whether the rows are draggable. Default is false.
 * - optionList: An array of objects representing options for each row. Each object should have a 'key' and a 'value'. Optionally, it can have a 'class' for styling.
 * - actionList: An array of objects representing action buttons. Each object should have a 'label' and a 'icon' for the button.
 * - order: The default order for sorting.
 *
 * Events:
 * - open: Emitted when a row is opened. Provides additional row data.
 * - action: Emitted when an action is performed. Provides the action type and row data.
 */

import {defineProps, ref, computed, defineEmits, onMounted, watch, onUnmounted} from 'vue'
import {debounce} from '@/shared/lib/utils/debounce'
import {timeFormat} from '@/shared/lib/utils/Date/format'

import {DatatableModel} from '@/entities/Datatable'
import {SessionModel} from '@/entities/Session'
import {ModalModel} from '@/entities/Modal'
import {useAppStore} from '@/app/providers'
import {TemplatesModel} from '@/entities/Templates'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

import draggable from 'vuedraggable'

import {BaseImage} from '@/shared/ui/Images'
import {BaseLoader} from '@/shared/ui/Loaders'
import {BaseCheckBox, BaseToggle} from '@/shared/ui/Inputs'
import {OptionList} from '@/shared/ui/Panels'
import {ActionStepper} from '@/features/Actions'
import {useRouter} from 'vue-router'
import BaseCopy from "@/shared/ui/Actions/BaseCopy.vue"
import {countriesList} from "@/shared/data/countriesList"

const MAX_DATA_LENGTH = 50

onMounted(() => {
    initCustomScroll()
    const filterBy = {}
    if (props.filterBy) {
        filterBy.key = props.filterBy.field
        filterBy.value = props.filterBy.data || router.currentRoute.value.params.id
    }

    datatableStore.initDatatable({
        cols: props.fields,
        path: props.path,
        tableId: props.tableId,
        order: props.order,
        orderType: props.orderType,
        filterBy: filterBy
    })
    store.getTemplates()
    tableHeaderFields.value = dataTableFields.value
})

onUnmounted(() => {
    removeCustomScroll()
    datatableStore.turnOffSelectMode()
})

const router = useRouter()
const emits = defineEmits(['open', 'action'])
const props = defineProps({
    fields: {
        type: Array,
        required: true
    },
    path: {
        type: String,
        required: true
    },
    tableId: {
        type: String,
        required: true
    },
    isDraggable: {
        type: Boolean,
        default: false
    },
    optionList: {
        type: Array,
        default: () => []
    },
    actionList: {
        type: Array,
        default: () => []
    },
    order: {
        type: String,
        default: '_id'
    },
    filterBy: {
        type: Object
    },
    customClass: {
        type: String
    },
    orderType: {
        type: Number
    }
})

const datatableStore = DatatableModel.useDatatableStore()
const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const store = TemplatesModel.useTemplatesStore()
const sessionStore = SessionModel.useSessionStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const datatableData = computed(() => DatatableModel.getDatatableData(datatableStore))
const dataTableFields = computed(() => DatatableModel.getTableFields(datatableStore, props.tableId))
const selectMode = computed(() => DatatableModel.getSelectModeState(datatableStore))
const tableIsLoaded = computed(() => DatatableModel.getTableLoadState(datatableStore))
const limit = computed(() => DatatableModel.getLimit(datatableStore))
const reportsTotal = computed(() => datatableStore.reportsTotal)

const optionSelect = ref(false)
const selectedItems = ref({
    rows: [],
    ids: []
})
const tableHeaderFields = ref(null)
const placementOptionList = ref('')

const scroller = ref(null)
const scrollBar = ref(null)
const datatable = ref(null)
const innerScroll = ref(null)
const showCustomScroll = ref(false)
const dataExist = ref(true)

const isShowLoadMore = computed(() => {
    const tableData = datatableData.value[props.tableId]

    if (!tableData || !Object.keys(tableData).length || !Array.isArray(tableData) || !limit.value || !dataExist.value) {
        return false
    }

    const dataLength = Object.keys(tableData).length
    return !(dataLength % limit.value)
})

const isSelectAll = computed(() => {
    return selectedItems.value.ids.length === limit.value
})

const changeStateOptionSelect = (item, index) => {
    datatableStore.turnOffSelectMode(index)
    item.selectIsOpen = !item.selectIsOpen
    item.selectType = item.selectIsOpen ? 1 : 0

    setTimeout(() => {
        const optionListElement = document.querySelector('.option-list.open')
        if (optionListElement) {
            const rect = optionListElement.getBoundingClientRect()
            const bottomOffset = rect.bottom
            const topOffset = rect.top
            const isBottomInView = bottomOffset <= window.innerHeight
            const isTopInView = topOffset >= 220

            if (!isTopInView && isBottomInView) {
                placementOptionList.value = 'bottom'
            } else {
                placementOptionList.value = 'top'
            }
        }
    }, 1)
}

const sortBy = (key) => {
    if (selectMode.value) {
        return
    }

    datatableStore.sortByOrder(key)
}

const debouncedSortBy = debounce(sortBy)

const loadMore = () => {
    datatableStore.datatableConfig.offset += datatableStore.datatableConfig.limit

    datatableStore.loadMore()
        .then((ans) => {
            dataExist.value = !!ans.data.data.length
            if (!ans.data.data.length) {
                dataExist.value = false
                generalNotificationsStore.showSuccess({
                    message: 'All data has been loaded!'
                })
            }
        })
}

const turnOnSelectMode = (item, row) => {
    selectedItems.value = {
        rows: [],
        ids: []
    }
    datatableStore.setSelectMode(true)
    optionSelect.value = false
    item.selectIsOpen = false
    item.selectType = 2
    handleCheckboxChange(item, row)
}

const handleCheckboxChange = (item, row) => {
    const index = selectedItems.value.ids.findIndex((selectedItem) => selectedItem === row.id.data)
    if (index !== -1) {
        selectedItems.value.ids.splice(index, 1)
        selectedItems.value.rows.splice(index, 1)
    } else {
        selectedItems.value.ids.push(row.id.data)
        selectedItems.value.rows.push(row)
    }
}

const isSelected = (item) => {
    return selectedItems.value.ids.some((selectedItem) => selectedItem === item.id.data)
}

const closeSelect = (item) => {
    datatableStore.turnOffSelectMode(item)
}

const openInfoByRow = (item) => {
    if (event.target.closest('.select')) {
        return
    }

    openLevel(item)
    closeSelect(item)
    emits('open', item)
}

const openLevel = (item) => {
    if (item.childs) {
        item.showChilds = !item.showChilds
    }
}

const deleteItem = (item, key) => {
    closeSelect(item)
    modalStore.setActionData({
        option: key,
        table: props.tableId,
        row: item
    })
    modalStore.setModal('confirmation')
}

const duplicate = (item, key) => {
    closeSelect(item)
    modalStore.setActionData({
        option: key,
        table: props.tableId,
        id: item.id.data
    })
    modalStore.setModal('confirmation')
}

const getSortIcon = (key) => {
    if (!key || selectMode.value) {
        return
    }

    const sortState = datatableStore.datatableConfig.order[key]
    return sortState ? (sortState === 1 ? 'sort-icon.svg' : 'sort-icon.svg') : 'sort-icon.svg'
}

const saveDatatableFields = () => {
    datatableStore.setColumns(tableHeaderFields.value)
    tableHeaderFields.value = dataTableFields.value
    localStorage.setItem(`DataTables_${datatableStore.tableId}`, JSON.stringify(tableHeaderFields.value))
    datatableStore.getDatatableData()
}

const makeAction = (key, item, row) => {
    if (key.split('_')[0] === 'duplicate') {
        duplicate(row, key)
    } else {
        emits('action', key, item, row)
    }
}

const showActionModal = (data) => {
    modalStore.setActionData({
        action: data.action,
        option: data.option,
        table: props.tableId,
        ...selectedItems.value
    })
    modalStore.setModal('confirmation')
}

const update = (data, item, row) => {
    appStore.update(
        {
            [item.key]: data
        },
        row,
        props.tableId
    )
}

const resetSelectedItems = () => {
    selectedItems.value = {
        ids: [],
        rows: []
    }
}

const turnOffSelectMode = () => {
    datatableStore.setSelectMode(false)
    datatableStore.canselSelectMode()
    resetSelectedItems()
}

const formatData = (data) => {
    if (data && data !== 0) {
        if (data.length > MAX_DATA_LENGTH) {
            return data.slice(0, MAX_DATA_LENGTH) + '...'
        }
        return data
    }

    return ''
}

const syncScroll = (event) => {
    const {target} = event

    if (target === scroller.value) {
        innerScroll.value.scrollLeft = target.scrollLeft
    } else if (target === innerScroll.value) {
        scroller.value.scrollLeft = target.scrollLeft
    }
}

const checkScrollConditions = () => {
    if (!datatable.value) {
        return
    }
    const {width} = datatable.value.getBoundingClientRect()
    scrollBar.value.style.width = width + 'px'
    const mainScreenHasYScroll = window.innerHeight < document.documentElement.scrollHeight
    const datatableHasXScroll = scroller.value.scrollWidth > scroller.value.clientWidth

    showCustomScroll.value = mainScreenHasYScroll && datatableHasXScroll
}

const initCustomScroll = () => {
    checkScrollConditions()
    window.addEventListener('resize', checkScrollConditions)
    scroller.value.addEventListener('scroll', checkScrollConditions)
}

const removeCustomScroll = () => {
    window.removeEventListener('resize', checkScrollConditions)
    window.removeEventListener('scroll', checkScrollConditions)
}

const selectAll = () => {
    if (datatableData.value[props.tableId] && !Object.keys(datatableData.value[props.tableId]).length) {
        return
    }

    datatableStore.setSelectMode(true)
    if (selectedItems.value.ids.length !== limit.value) {
        selectedItems.value.ids = datatableData.value[props.tableId].map(item => item.id.data)
        selectedItems.value.rows = [...datatableData.value[props.tableId]]
    } else {
        resetSelectedItems()
    }
}

const openByInfo = (field, row) => {
    let time
    if (datatableStore.datatableConfig.filters.timeCreate) {
        time = datatableStore.datatableConfig.filters.timeCreate
    }

    emits('open', {
        field: field,
        row: row,
        time: time
    })
}
// todo remove in future
watch(
    () => datatableStore.columns[props.tableId],
    () => {
        tableHeaderFields.value = dataTableFields.value
    }
)

watch(
    () => datatableStore.datatableData[props.tableId],
    () => {
        dataExist.value = true
        setTimeout(() => {
            checkScrollConditions()
        }, 1)
    }
)
</script>

<template>
    <div class="table">
        <div class="header-datatable">
            <div v-if="$slots['top-left']" class="top-left" :class="{ fill: !$slots['top-right'] }">
                <slot name="top-left"></slot>
            </div>
            <div v-if="$slots['top-right']" class="top-right">
                <slot name="top-right"></slot>
            </div>
            <div v-if="$slots['center-left']" class="center-left">
                <slot name="center-left"></slot>
            </div>
            <div v-if="$slots['center-right']" class="center-right">
                <slot name="center-right"></slot>
            </div>
            <div v-if="$slots['bottom-left']" class="bottom-left">
                <slot name="bottom-left"></slot>
            </div>
            <div v-if="$slots['bottom-right']" class="bottom-right">
                <slot name="bottom-right"></slot>
            </div>
        </div>

        <div class="scroll-container">
            <div class="scroller-box" :class="{hide: !showCustomScroll || modalStore.modal}">
                <div ref="scroller" class="scroller" @scroll="syncScroll">
                    <div ref="scrollBar" class="custom-scroll"></div>
                </div>
            </div>
            <div
                ref="innerScroll"
                class="inner-scroll"
                :class="{
                    'pb-40': selectMode && datatableData[tableId] && Object.keys(datatableData[tableId]).length > 10
                }"
                @scroll="syncScroll"
            >
                <table
                    ref="datatable"
                    :class="[customClass, { 'is-load': datatableStore.isEditLoaded }, tableId]"
                    class="datatable"
                >
                    <BaseLoader v-if="datatableStore.isEditLoaded" class="loader-box--edit"/>

                    <thead>
                    <draggable
                        v-model="tableHeaderFields"
                        tag="tr"
                        :item-key="(key) => key"
                        :disabled="!isDraggable"
                        @change="saveDatatableFields()"
                    >
                        <template #item="{ element: field }">
                            <th v-if="field.key === '-'">
                                <div class="header-column">
                                    <BaseCheckBox
                                        :design="'fill'"
                                        :checked="isSelectAll"
                                        :value="isSelectAll"
                                        @click="selectAll()"
                                    />
                                </div>
                            </th>
                            <th v-else-if="field.visible">
                                <div class="header-column" @click="debouncedSortBy(field.key)">
                                    {{ field.label }}
                                    <BaseImage
                                        v-if="field.sortable && !selectMode"
                                        :width="11"
                                        :height="15"
                                        :path="`${getSortIcon(field.key)}`"
                                    />
                                </div>
                            </th>
                        </template>
                    </draggable>
                    </thead>
                    <tbody>
                    <template v-for="(row, rowIndex) in datatableData[tableId]" :key="rowIndex">
                        <!-- Render Parent Row -->
                        <tr
                            :class="{deleted: tableId === 'reports-page' && !row.name.data}"
                            @click="openInfoByRow(row)">
                            <template v-for="(item, colIndex, index) in row" :key="colIndex">
                                <td v-if="item.visible">
                                    <div
                                        class="column"
                                        :class="{selected: item.selectType === 1 && item.selectIsOpen && !selectMode}"
                                    >
                                        <BaseImage
                                            v-if="row.childs && row.childs.data.length && index === 0"
                                            :class="{open: row.showChilds}" :width="10" :height="10"
                                            :path="'arrow.svg'"/>
                                        <template v-if="item.key === '-'">
                                            <BaseImage
                                                v-if="(item.selectType === 0 || !item.selectIsOpen) && !selectMode"
                                                :width="16"
                                                :height="16"
                                                :path="'inner-menu.svg'"
                                                @click.stop="changeStateOptionSelect(item, rowIndex)"
                                            />
                                            <BaseImage
                                                v-if="item.selectType === 1 && item.selectIsOpen && !selectMode"
                                                :path="'close.svg'"
                                                :width="11"
                                                :height="11"
                                                @click.stop="changeStateOptionSelect(item, rowIndex)"
                                            />
                                            <BaseCheckBox
                                                v-if="selectMode"
                                                :value="row"
                                                :checked="isSelected(row)"
                                                @checked="(value) => handleCheckboxChange(value, row)"
                                            />
                                            <OptionList
                                                v-model="item.selectIsOpen"
                                                :list="optionList"
                                                :placement="'bottom'"
                                            >
                                                <template #custom-option="{ actionKey, actionValue, custom }">
                                                    <template v-if="actionKey.split('_')[0] === 'delete' && !custom">
                                                        <div class="option" @click.stop="deleteItem(row, actionKey)">
                                                            <div class="image-box">
                                                                <BaseImage
                                                                    :path="'close-red.svg'" :width="11"
                                                                    :height="11"/>
                                                            </div>
                                                            <span>{{ actionValue }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="actionKey === 'select' && !custom">
                                                        <div class="option" @click.stop="turnOnSelectMode(item, row)">
                                                            <div class="image-box">
                                                                <BaseImage
                                                                    :path="'approve.svg'" :width="14"
                                                                    :height="10"/>
                                                            </div>
                                                            <span>{{ actionValue }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="actionKey === 'edit' && !custom">
                                                        <div
                                                            class="option"
                                                            @click.stop="makeAction(actionKey, item, row)">
                                                            <div class="image-box">
                                                                <BaseImage :path="'edit.svg'" :width="12" :height="12"/>
                                                            </div>
                                                            <span>{{ actionValue }}</span>
                                                        </div>
                                                    </template>
                                                    <div
                                                        v-else class="option"
                                                        @click.stop="makeAction(actionKey, item, row)">
                                                        <div class="image-box">
                                                            <BaseImage
                                                                :path="`${actionKey.split('_')[0]}.svg`"
                                                                :width="20"
                                                                :height="15"
                                                            />
                                                        </div>
                                                        <span>{{ actionValue }}</span>
                                                    </div>
                                                </template>
                                            </OptionList>
                                        </template>
                                        <template v-if="item.type === 'boolean'">
                                            <slot :name="item.key" :col="item">
                                                <BaseToggle
                                                    :value="parseInt(item.data)"
                                                    @change="(data) => update(data, item, row)"
                                                />
                                            </slot>
                                        </template>
                                        <template v-else-if="item.key === 'country' && item.data">
                                            <slot :name="item.key" :col="item">
                                                {{ countriesList()[item.data] }}
                                            </slot>
                                        </template>
                                        <template v-else-if="item.type === 'time' && item.data">
                                            <slot :name="item.key" :col="item">
                                                {{ timeFormat(item.data, sessionStore.offset) }}
                                            </slot>
                                        </template>
                                        <template v-else-if="item.type === 'func'">
                                            <slot :name="item.key" :col="item">
                                                <BaseImage
                                                    v-if="tableId === 'reports-page' && item.key === 'leads' || item.key === 'ftds'"
                                                    :path="'info.svg'"
                                                    :height="20"
                                                    :width="20"
                                                    @click.stop="openByInfo(item.key, row)"/>
                                                <div v-html="item.func(item, item.data)"></div>
                                            </slot>
                                        </template>
                                        <template v-else>
                                            <slot
                                                :name="item.key"
                                                v-bind="{ item, row, elData: row[item.key].data }">
                                                {{ formatData(item.data) }}
                                            </slot>
                                            <BaseCopy
                                                v-if="item.data && item.data.toString().length >= MAX_DATA_LENGTH"
                                                :value="item.data"
                                            />
                                        </template>
                                    </div>
                                </td>
                            </template>
                        </tr>

                        <template v-if="row.showChilds">
                            <template
                                v-for="(level, levelIndex) in row.childs.data"
                                :key="`level-${rowIndex}-${levelIndex}`">
                                <tr
                                    class="levels" :class="{deleted: tableId === 'reports-page' && !level.name}"
                                    @click="openLevel(level)">
                                    <template
                                        v-for="(tableFieldLevel, index) in dataTableFields"
                                        :key="tableFieldLevel"
                                    >
                                        <td
                                            v-if="tableFieldLevel.key !== 'childs' && tableFieldLevel.key !== 'crleads'"
                                            :style="{ paddingLeft: index === 0 ? '20px' : '' }">
                                            <div class="column">
                                                <BaseImage
                                                    v-if="level.childs.length && index === 0"
                                                    :class="{open: level.showChilds}" :width="10" :height="10"
                                                    :path="'arrow.svg'"/>
                                                <slot
                                                    :name="`level-${tableFieldLevel.key}`"
                                                    v-bind="{elData: level[tableFieldLevel.key], level}">
                                                    <BaseImage
                                                        v-if="tableFieldLevel.key === 'leads' || tableFieldLevel.key === 'ftds'"
                                                        :path="'info.svg'"
                                                        :height="20"
                                                        :width="20"
                                                        @click.stop="openByInfo(tableFieldLevel.key, level)"
                                                    />
                                                    {{
                                                        (tableFieldLevel.key === 'cr' || tableFieldLevel.key === 'lateCr') ? level[tableFieldLevel.key].toFixed(1) + '%' : formatData(level[tableFieldLevel.key]) || 0
                                                    }}
                                                </slot>
                                            </div>
                                        </td>
                                    </template>
                                </tr>

                                <template v-if="level.childs && level.showChilds">
                                    <template
                                        v-for="(subLevel, subLevelIndex) in level.childs"
                                        :key="`subLevel-${rowIndex}-${levelIndex}-${subLevelIndex}`">
                                        <tr
                                            class="sub-levels"
                                            :class="{deleted: tableId === 'reports-page' && !subLevel.name}">
                                            <template
                                                v-for="(tableFieldLevel, index) in dataTableFields"
                                                :key="tableFieldLevel"
                                            >
                                                <td
                                                    v-if="tableFieldLevel.key !== 'childs' && tableFieldLevel.key !== 'crleads'"
                                                    :style="{ paddingLeft: index === 0 ? '20px' : '' }">
                                                    <div
                                                        class="column"
                                                        :style="{marginLeft: index !== 0 ? '-20px' : ''}">
                                                        <BaseImage
                                                            v-if="subLevel.childs.length && index === 0"
                                                            :class="{open: row.showChilds}" :width="10"
                                                            :height="10" :path="'arrow.svg'"/>
                                                        <slot
                                                            :name="`sublevel-${tableFieldLevel.key}`"
                                                            v-bind="{elData: subLevel[tableFieldLevel.key], subLevel}">
                                                            <BaseImage
                                                                v-if="tableFieldLevel.key === 'leads' || tableFieldLevel.key === 'ftds'"
                                                                :path="'info.svg'"
                                                                :height="20"
                                                                :width="20"
                                                                @click.stop="openByInfo(tableFieldLevel.key, subLevel)"
                                                            />
                                                            {{
                                                                (tableFieldLevel.key === 'cr' || tableFieldLevel.key === 'lateCr') ? subLevel[tableFieldLevel.key].toFixed(1) + '%' : formatData(subLevel[tableFieldLevel.key]) || 0
                                                            }}
                                                        </slot>
                                                    </div>
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                    <tr v-if="tableId === 'reports-page'"></tr>
                    <tr v-if="tableId === 'reports-page'"></tr>
                    <tr v-if="tableId === 'reports-page'"></tr>
                    <tr
                        v-if="tableId === 'reports-page' && datatableData[tableId] && Object.keys(datatableData[tableId]).length"
                        class="total">
                        <td>Total</td>
                        <template
                            v-for="(tableFieldLevel) in dataTableFields"
                            :key="tableFieldLevel"
                        >
                            <td v-if="tableFieldLevel.key !== 'childs' && tableFieldLevel.key !== 'name'">
                                <div class="total-column">
                                    {{
                                        tableFieldLevel.key === 'cr' || tableFieldLevel.key === 'lateCr' ? reportsTotal[tableFieldLevel.key].toFixed(1) + '%' : (reportsTotal[tableFieldLevel.key])
                                    }}
                                </div>
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            v-if="!datatableStore.isEditLoaded"
            class="load-more"
            :class="{ 'mh-55': isShowLoadMore }"
            @click="loadMore()"
        >
            <BaseLoader v-if="!tableIsLoaded" class="loader-box--load"/>
            <button v-if="isShowLoadMore && tableIsLoaded" class="load-more-btn">
                load more
            </button>
        </div>
        <Transition name="slide">
            <div v-if="selectMode" class="selected-box">
                <div class="select-box-content">
                    <span>{{ selectedItems.ids.length }}/{{ datatableStore.total }} Selected Items</span>
                    <ActionStepper :action-list="actionList" @update="showActionModal" @cancel="turnOffSelectMode"/>
                </div>
                <BaseImage class="close" :width="11" :height="11" :path="'close-gray.svg'" @click="turnOffSelectMode"/>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.header-datatable {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, max-content);
}

.top-left.fill {
    grid-column: 1/4;
}

.top-left {
    grid-column: 1/3;
    grid-row: 1/2;
}

.bottom-right {
    grid-column: 3/3;
    grid-row: 3/3;
    font-size: 12px;
    color: rgba(143, 142, 158, 1);
    display: flex;
    align-items: flex-end;
}

.top-right {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
    display: flex;
    align-items: center;
}

.bottom-left,
.bottom-right {
    margin-top: 16px;
}

.bottom-left {
    grid-column: 2 / 1;
    grid-row: 3 / 3;
}

.center-left {
    grid-column: 1 / 3;
    grid-row: 2/3;
    justify-self: start;
    display: flex;
}

.levels {
    position: relative;
    left: 20px;
}

.levels td {
    padding: 0;
}

.sub-levels {
    position: relative;
    left: 40px;
}

.sub-levels td {
    padding: 0;
}

.center-right,
.center-left {
    margin-top: 20px;
    width: 100%;
}

.center-right {
    grid-column: 1/3;
    grid-row: 2/3;
    justify-content: flex-end;
    display: flex;
}

.table {
    width: 100%;
}

.scroller-box.hide {
    opacity: 0;
    visibility: hidden;
}

.scroller-box {
    background: var(--background);
    padding: 12px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;
    opacity: 1;
    visibility: visible;
}

.scroller-box.hide {
    opacity: 0;
    visibility: hidden;
}

.scroller {
    width: calc(100% - 40px);
    overflow-x: scroll;
    position: fixed;
    bottom: 12px;
    left: var(--gutter);
    z-index: 10;
}

.custom-scroll {
    height: 20px;
}

.inner-scroll {
    overflow-x: auto;
    position: relative;
    //min-height: calc(100vh - 310px);
}

.inner-scroll.pb-40 {
    padding-bottom: 40px;
}

.datatable.is-load::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.datatable {
    width: 100%;
    border-top: var(--general-border) solid var(--white-08);
    margin-top: 16px;
    border-spacing: 0 10px;
    position: relative;
}

tbody,
thead {
    width: 100%;
}

th {
    color: var(--primary-gray);
    cursor: pointer;
    text-transform: capitalize;
    padding: var(--datatable-top-bottom-padding) var(--datatable-left-right-padding);
    font-weight: var(--general-font-weight);
}


td {
    padding: 0 var(--datatable-left-right-padding);
    color: var(--white-086)
}

th,
td {
    white-space: nowrap;
    text-align: left;
}

th:first-child,
td:first-child {
    padding: 0 0 0 20px;
    width: 16px;
}

.reports-page th:first-child .header-column,
.reports-page td:first-child .column {
    width: auto;
    height: auto;
    justify-content: flex-start;
}

.reports-page .column {
    gap: 8px;
}

th:first-child .header-column,
td:first-child .column {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
}

td:first-child .column.selected {
    background-color: var(--purple);
}

.column {
    display: flex;
    align-items: center;
    gap: 35px;
}

.column img {
    cursor: pointer;
}

thead tr {
    padding: 0 36px;
    border-spacing: 20px;
}

th:last-child,
td:last-child {
    border-radius: 0 10px 10px 0;
}

th:first-child,
td:first-child {
    border-radius: 10px 0 0 10px;
}

tbody tr {
    border-radius: var(--general-border-radius);
    background: var(--dark-bg);
    height: 50px;
    width: 100%;
    cursor: pointer;
}

tbody tr.deleted {
    background: var(--gray-700);
}

.header-column {
    display: flex;
    align-items: center;
    border-radius: var(--general-border-radius);
    transition: background-color 0.3s ease-in-out;
    gap: 8px;
}

th img {
    opacity: 1;
    visibility: visible;
}

img {
    max-width: 100%;
    height: auto; /* Ensures images are fully visible and scaled correctly */
}

.options img {
    cursor: pointer;
}

.load-more {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 101;
}

.scroll-container {
    position: relative;
}

.scroll-container::after {
    content: '';
    position: absolute;
    bottom: -102px;
    left: 0;
    right: 0;
    background: var(--background);
    width: 100%;
    height: 100px;
    z-index: 100;
}

.load-more.mh-55 {
    min-height: 55px;
}

.loader-box--edit {
    position: fixed !important;
    left: 50% !important;
    bottom: -50% !important;
    transform: translate(-50%, -50%);
}

.loader-box--load {
    min-height: 54px;
}

.load-more-btn {
    display: block;
    margin: auto 0 auto auto;
    cursor: pointer;
    background: transparent;
    padding: 16px 5px 4px;
    outline: none;
    color: var(--gray-400);
    font-size: 21px;
    border-bottom: 1px solid var(--gray-400);
}

.image-box {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

td {
    position: relative;
}

.selected-box {
    background-color: var(--gray-800);
    padding: 45px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 103;
    border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
    border-top: 1px solid var(--purple);
}

.select-box-content {
    display: flex;
    align-items: center;
    gap: 30px;
}

.select-box-content span {
    white-space: nowrap;
}

.select-box-content ul {
    padding: 20px;
    border-radius: var(--general-border-radius);
    background-color: var(--background);
}

.select-box-content ul li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--primary-gray);
}

.select-box-content ul li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.datatable .loader-wrapper {
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
}

.option-list span {
    display: inline-block;
    width: 100%;
    color: var(--white);
}

.option-list li:last-child span {
    color: var(--light-red);
}

.statuses td:nth-child(2) {
    width: 650px;
}

.option {
    display: inline-grid;
    grid-template-columns: max-content 1fr;
    place-items: center;
    gap: 12px;
}

.close {
    display: none;
}

.slide-enter-active, .slide-leave-active {
    transition: all 0.25s ease-in-out;
}

.slide-enter-active {
    transition: all 0.25s ease-in-out;
    transform: translateY(200px);
}

.slide-enter {
    opacity: 0;
    transition: all 0.25s ease-in-out;
    transform: translateY(200px);
}

.slide-enter-to {
    opacity: 1;
    transition: all 0.25s ease-in-out;
    transform: translateY(0);
}

.slide-leave {
    opacity: 1;
    transition: all 0.25s ease-in-out;
    transform: translateY(200px);
}

.slide-leave-to {
    opacity: 1;
    transition: all 0.25s ease-in-out;
    transform: translateY(100px);
}

img.open {
    transform: rotate(180deg);
}

.table.mobile .select-box-content {
    flex-direction: column;
    gap: 16px;
}

.table.mobile .inner-scroll.pb-40 .datatable {
    padding-bottom: 160px;
}

.table.mobile .center-right {
    grid-row: 3 / 3;
    justify-content: center;
}

.table.mobile .bottom-left {
    grid-row: 4 / 4;
    grid-column: 3 / 1;
}

.total {
    cursor: default;
    background-color: transparent;
}

.total td {
    color: var(--light-purple);
    border-radius: 0;
    width: 50px;
    border-top: 2px solid #434343;
}

.reports .scroll-container::after {
    content: none;
}

@media (max-width: 1000px) {
    .datatable {
        border-spacing: 0 4px;
    }

    th {
        font-size: 12px;
        padding: 10px var(--datatable-left-right-padding);
    }

    th:first-child .header-column,
    td:first-child .column {
        width: 40px;
        height: 40px;
    }

    .load-more-btn {
        font-size: var(--general-font-size);
    }

    tbody tr {
        height: 40px;
    }

    .selected-box {
        padding: 16px 24px 32px;
    }

    .selected-box span {
        padding: 12px 1px;
    }

    .select-box-content {
        align-items: flex-start;
    }

    .close {
        display: block;
        position: absolute;
        top: 32px;
        right: 35px;
    }

    .center-right,
    .center-left {
        border: 0;
        padding: 0;
        margin-top: 16px;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .select-box-content {
        flex-direction: column;
        gap: 16px;
    }

    .inner-scroll.pb-40 .datatable {
        padding-bottom: 160px;
    }

    .center-right {
        grid-row: 3 / 3;
        justify-content: center;
    }

    .bottom-left {
        grid-row: 4 / 4;
        grid-column: 3 / 1;
    }
}
</style>
