<script setup>
import depositsPageConfig from '@/pages/deposits/configs/deposits-config'

import {DataTable, FiltersList} from '@/features/Datatable'
import { PaginationBox, TableActions } from '@/features/Datatable'
import { PageTitle } from '@/shared/ui/Page'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'
import {onMounted} from "vue";

import {SessionModel} from '@/entities/Session'

const { cols, searchByList, actionList, tableActions, tableId, pathDt, initOrder, filterBy, filters } =
    depositsPageConfig.config()

const title = usePageTitle('Deposits')

const sessionStore = SessionModel.useSessionStore()

onMounted(() => {
    sessionStore.setTimeDepositFoundLS(sessionStore.timeDepositsFound)
})

const leaveField = {
    key: 'type',
    data: 1
}
</script>

<template>
    <div class="leads-page container">
        <div class="content">
            <PageTitle :title="'Deposits'" :icon="'page-icons/deposits.svg'" />
            <DataTable
                :fields="cols"
                :is-draggable="true"
                :path="pathDt"
                :table-id="tableId"
                :order="initOrder"
                :filter-by="filterBy"
                :option-list="tableActions"
                :action-list="actionList"
            >
                <template #center-right>
                    <TableActions :search-list="searchByList" />
                </template>
                <template #top-left>
                    <FiltersList :leave-filter="leaveField" :search-list="searchByList" :filters="filters" :button-text="'Search'" />
                </template>
                <template #bottom-left>
                    <PaginationBox />
                </template>
            </DataTable>
        </div>
    </div>
</template>

<style scoped></style>
