import { defineStore } from 'pinia'
import { errors } from '@/shared/data'

export const useGeneralNotificationsStore = defineStore('generalNotifications', {
    state: () => ({
        notifications: []
    }),
    actions: {
        removeNotification(id) {
            this.notifications = this.notifications.filter((notification) => notification.id !== id)
        },

        showSuccess(data) {
            return this.showNotification(data, 1, data.message)
        },

        showWarning(data) {
            return this.showNotification(data, 2, data.message)
        },

        showError(data) {
            // const message = data && data.code ? errors[data.code] : data?.message || 'Something went wrong'
            const message = data && data.code ? data.error : data?.message || 'Something went wrong'
            return this.showNotification(data, 0, message)
        },

        showNotification(data, type, message) {
            return new Promise((resolve) => {
                let notificationId = new Date().getTime()
                this.notifications.push({
                    type: type,
                    message: message,
                    id: notificationId
                })

                setTimeout(
                    () => {
                        this.removeNotification(notificationId)
                        resolve(notificationId)
                    },
                    data && data.timeout ? data.timeout : 3000
                )
            })
        }
    }
})
