<script setup>
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseInput, BaseSelectBox, BaseTextarea} from '@/shared/ui/Inputs'

import {computed, ref} from 'vue'

import {disableEnableObjectList} from '@/shared/data'

import {ModalModel} from '@/entities/Modal'
import {useAppStore} from '@/app/providers'
import {AffiliateModel} from '@/entities/Affiliates'
import {BaseModal} from '@/shared/ui/Modal'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const affiliateStore = AffiliateModel.useAffiliatesStore()

const affiliate = computed(() => affiliateStore.affiliate)

const note = ref(affiliate.value['note'].data || '')
const status = ref(affiliate.value['status'].data)

const close = () => {
    modalStore.closeModal()
}

const save = () => {
    appStore.update(
        {
            note: note.value,
            status: status.value
        },
        affiliate.value,
        'affiliate-tables'
    )
    close()
}

const cancel = () => {
    close()
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Edit affiliate</h4>
        </template>
        <template #content>
            <div class="edit-affiliate">
                <BaseSelectBox
                    v-model="status"
                    :options="
                                Object.entries(disableEnableObjectList).map(([key, value]) => ({
                                    value: key,
                                    title: value
                                }))
                            "
                    :label="'Permission'"
                    :placeholder="disableEnableObjectList[status] || 'Select Status'"
                />
                <BaseTextarea
                    v-model="note" :label="'Note'" :placeholder="'Enter notes'" :limit="100"
                    :height="105"/>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :text="'Cancel'" :width="100" :design="'outline'" @click="cancel()"/>
            <BaseButton :text="'Save'" :width="100" @click="save()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.edit-affiliate {
    width: 100%;
    background-color: var(--background);
    border-radius: var(--general-border-radius);
    display: flex;
    gap: 16px;
    flex-direction: column;
}

h4 {
    padding: 9px 0;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 54px;
    width: 100%;
    gap: 20px;
}

.select {
    width: 100% !important;
}
</style>
