import { defineStore } from 'pinia'
import { api } from '../api'

export const useSourcesStore = defineStore('sources', {
    state: () => ({
        sources: null,
        row: null,
        source: null,
        activeTab: '',
        datatableConfig: {
            filters: {},
            globalFilter: '',
            order: {
                created: -1
            }
        },
        favorites: JSON.parse(localStorage.getItem('sources-favorite') || '[]') || []
    }),
    actions: {
        setActiveTab(data) {
            this.activeTab = data
        },

        setRow(data) {
            this.row = data
        },

        setSource(data) {
            this.source = data
        },

        getSources() {
            return new Promise((resolve, reject) => {
                api.getSources(this.datatableConfig).then((ans) => {
                    this.sources = ans.data.data
                    resolve(ans)
                })
            })
        },

        getSource(id) {
            return new Promise((resolve, reject) => {
                api.getSource(id).then((ans) => {
                    this.source = ans.data.data
                })
            })
        },

        setConfig(data) {
            this.datatableConfig.globalFilter = data.value
            this.getSources()
        },

        clearGlobalFilter() {
            this.datatableConfig.globalFilter = ''
            this.getSources()
        },

        addToFavorites(item) {
            this.favorites.push(item)
            this.saveFavorites()
        },

        removeFromFavorites(index) {
            this.favorites.splice(index, 1)
            this.saveFavorites()
        },

        saveFavorites() {
            localStorage.setItem('sources-favorite', JSON.stringify(this.favorites))
        }
    }
})
