<script setup>
/**
 * BaseTabBox
 * ------------------------------
 * Description:
 * A Vue component for displaying a tab box with customizable tabs.
 *
 * Usage:
 * <BaseTabBox
 *    :tabs="tabList"
 *    :active="activeTab"
 *    :disableBorder="true"
 *    @change-tab="handleTabChange"
 * />
 *
 * Props:
 * - tabs: An array of objects representing the tabs. Each object should contain at least a 'label' property representing the tab label.
 * - active: The key of the currently active tab.
 * - disableBorder: A boolean indicating whether to disable the tab box border. Default is false.
 *
 * Events:
 * - change-tab: Emitted when a tab is changed. Provides the key of the newly selected tab.
 */

import { defineProps, defineEmits, shallowRef } from 'vue'

const emits = defineEmits(['change-tab'])
const props = defineProps({
    tabs: {
        type: Array
    },
    active: {
        type: Object,
        default: () => {}
    },
    disableBorder: {
        type: Boolean,
        default: false
    }
})

const activeTab = shallowRef(props.active)
const changeTab = (tab) => {
    activeTab.value = tab
    emits('change-tab', activeTab.value)
}
</script>

<template>
    <div v-if="tabs.length > 1" class="tabs" :class="{ 'disable-border': disableBorder }">
        <template v-for="i in tabs" :key="i.key">
            <div v-if="!i.hide" class="tab" :class="{ active: activeTab.key === i.key }" @click="changeTab(i)">
                {{ i.value }} {{ i.count ? `(${i.count})` : '' }}
            </div>
        </template>
    </div>
</template>

<style scoped>
.tabs.disable-border {
    border-bottom: none;
}

.tabs.disable-border .tab {
    padding: 0;
}

.tabs.disable-border .tab.active::after {
    bottom: -12px;
}

.tabs {
    border-bottom: var(--general-border) solid var(--white-08);
    display: flex;
    gap: 20px;
}

.tab {
    padding: 0 30px 8px;
    cursor: pointer;
    color: var(--white-064);
    position: relative;
}

.tab.active {
    color: var(--white);
}

.tab::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: var(--general-border);
}

.tab.active::after {
    width: 100%;
    animation: changeTab 0.2s;
    background-color: var(--white);
}

@keyframes changeTab {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
</style>
