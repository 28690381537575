<script setup>
import {defineProps} from 'vue'
import {copyToClipboard} from "@/shared/lib/utils/copyToClipboard"
import {BaseImage} from "@/shared/ui/Images"
import {GeneralNotificationsModel} from "@/entities/GeneralNotifications"
import {BaseLoader} from "@/shared/ui/Loaders"

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const props = defineProps({
    title: {
        type: String,
    },
    data: {
        type: String,
        default: null
    },
    loaded: {
        type: Boolean,
        default: false
    }
})

const copy = (data) => {
    copyToClipboard(data)
        .then(() => {
            generalNotificationsStore.showSuccess({
                message: 'Copied'
            })
        })
}
</script>

<template>
    <div class="code-panel">
        <h6 v-if="data" class="typo-heading-h6">{{ title }}</h6>
        <div class="content">
            <div class="data">
                <pre v-if="data">{{ data }}</pre>
                <div v-else class="info-box">
                    <BaseLoader v-if="loaded"/>
                    <p v-else class="info">The data will be here.</p>
                </div>
            </div>
            <BaseImage
                v-if="data"
                :width="14"
                :height="14"
                :path="'copy.svg'"
                class="copy"
                @click="copy(data)"
            />
        </div>
    </div>
</template>

<style scoped>
.code-panel {
    padding: 20px;
    border-radius: var(--general-border-radius);
    background-color: var(--background);
    height: inherit;
    overflow: hidden;
}

h6 {
    margin-bottom: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
}

.data {
    height: 100%;
    overflow: auto;
}

pre {
    overflow: auto;
    color: #aeb1ff;
    white-space: pre;
    font-weight: var(--general-font-weight);
}

.info-box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.copy {
    margin: 0 0 0 auto;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.copy:active {
    transform: scale(.9);
}
</style>
