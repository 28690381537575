<script setup>

import {BaseModal} from "@/shared/ui/Modal"
import {computed, ref} from "vue"

import {ModalModel} from '@/entities/Modal'
import {BaseTabBox, BaseTabViewer, BaseTabViewerItem} from "@/shared/ui/Tabs"

const modalStore = ModalModel.useModalStore()

const info = computed(() => modalStore.modalData)

const tabs = ref([
    {
        key: 'mapped',
        value: 'Mapped',
    }, {
        key: 'original',
        value: 'Original',
    }
])
const activeTab = ref(tabs.value[0])

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const calcPercentage = (count) => {
    return count / info.value.totalLeads * 100
}
</script>

<template>
    <BaseModal >
        <template #title>
            <h4 class="typo-heading-h4">Info</h4>
        </template>
        <template #content>
            <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab"/>
            <BaseTabViewer>
                <template #content>
                    <div v-if="activeTab.key === 'mapped'" class="mapped">
                        <ul>
                            <li v-for="(status, i) in info.mapped" :key="i">
                                {{status.status !== null ? status.status : 'Not selected'}}: {{calcPercentage(status.count) ? calcPercentage(status.count).toFixed(2) : 0}}% - Total: {{status.count}}
                            </li>
                        </ul>
                    </div>
                    <div v-else class="original">
                        <ul>
                            <li v-for="(status, i) in info.original" :key="i">
                                {{status.status !== null ? status.status : 'Not selected'}}: {{calcPercentage(status.count) ? calcPercentage(status.count).toFixed(2) : 0}}% - Total: {{status.count}}
                            </li>
                        </ul>
                    </div>
                </template>
            </BaseTabViewer>
        </template>
    </BaseModal>
</template>

<style scoped>
.tabs {
    justify-content: center;
}

ul {
    margin-top: 4px;
    max-height: 216px;
    overflow: auto;
}

ul li {
    font-size: 16px;
    color: var(--white-086);
    margin-bottom: 8px;
}
</style>
