import { markRaw } from 'vue'

import { TabGeneral } from '../tabs'

export default {
    config: () => {
        return {
            tabs: markRaw([
                {
                    key: 'general',
                    value: 'General',
                    component: TabGeneral
                }
            ])
        }
    }
}
