import { defineStore } from 'pinia'
import { api } from '../api'

export const useOtpStore = defineStore('otp', {
    state: () => ({
        otp: null
    }),
    actions: {
        setOtp(data) {
            this.otp = data
        },

        createOtp() {
            return new Promise((resolve, reject) => {
                api.createOtp().then((ans) => {
                    this.setOtp(ans.data.data)
                })
            })
        },

        updateOtp(otp) {
            return new Promise((resolve, reject) => {
                api.updateOtp({
                    code: otp.code,
                    enable: otp.enable
                }).then(() => resolve())
            })
        },

        disableOtp(otp) {
            return new Promise((resolve, reject) => {
                api.disableOtp(otp).then(() => resolve())
            })
        },

        disableUserOtp(data) {
            return new Promise((resolve, reject) => {
                api.disableUserOtp(data).then(() => resolve())
            })
        }
    }
})
