import { markRaw } from 'vue'

import { TabAffiliate, TabPaymentPlan, TabRestrictions, TabSettings, TabSources } from '../tabs'

export default {
    config: () => {
        return {
            tabs: markRaw([
                {
                    key: 'settings',
                    value: 'Settings',
                    component: TabSettings
                },
                {
                    key: 'sources',
                    value: 'Sources',
                    hide: 1,
                    component: TabSources
                },
                {
                    key: 'affiliates',
                    value: 'Affiliates',
                    component: TabAffiliate
                },
                {
                    key: 'paymentPlan',
                    value: 'Payment Plan',
                    hide: 1,
                    component: TabPaymentPlan
                },
                {
                    key: 'restrictions',
                    value: 'Restrictions',
                    hide: 1,
                    component: TabRestrictions
                }
            ])
        }
    }
}
