<script setup>
/**
 * BaseToggle
 * ------------------------------
 * Description:
 * A Vue component representing a switch control.
 *
 * Usage:
 * <BaseToggle
 *    v-model="switchValue"
 *    @change="handleSwitchChange"
 * />
 *
 * Props:
 * - value: The value of the switch control. Should be a number.
 *
 * Events:
 * - change: Emitted when the switch value changes. Provides the updated value.
 */

import { defineProps, defineEmits, watch, toRef } from 'vue'

const emits = defineEmits(['change'])
const props = defineProps({
    value: {
        type: [Number, Boolean, String]
    }
})

const isActive = toRef(props.value)

const change = () => {
    isActive.value = isActive.value === 1 ? 0 : 1
    emits('change', isActive.value)
}

// todo remove in future
watch(toRef(props, 'value'), (val) => {
    isActive.value = val
})
</script>

<template>
    <div class="switch-box">
        <slot name="state"></slot>
        <div class="switch" :class="{ active: isActive }" @click.stop="change"></div>
        <slot name="right-state"></slot>
    </div>
</template>

<style scoped>
.switch-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.switch {
    width: 52px;
    height: 32px;
    position: relative;
    border-radius: 100px;
    background-color: var(--select-bg);
    cursor: pointer;
}

.switch::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: left 0.3s ease-in-out;
    background: #b0b0b0;
}

.switch.active::after {
    background: linear-gradient(135deg, #9d95ff 0%, #7075e7 100%);
}

.switch::after {
    left: 3px;
}

.switch.active::after {
    left: calc(100% - 27px);
}

.state {
    min-width: 32px;
}
</style>
