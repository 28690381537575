<script setup>
import { BaseKeyValuePanel } from '@/shared/ui/Panels'
import { LeadsModel } from '@/entities/Leads'

import { computed } from 'vue'
import {countriesList} from "@/shared/data/countriesList"
const store = LeadsModel.usestore()
const lead = computed(() => store.lead)
</script>

<template>
    <div class="connections">
        <div class="info-panel-container">
            <BaseKeyValuePanel :title="'Browser'" :sub-title="lead.browser?.data" />
            <BaseKeyValuePanel :title="'Locations'" :sub-title="`${countriesList()[lead.country.data]}`" />
            <BaseKeyValuePanel :title="'Device'" :sub-title="lead.device?.data" />
            <BaseKeyValuePanel :title="'Referral Link'" :sub-title="lead.referralLink?.data" />
            <BaseKeyValuePanel :title="'User2 Agent'" :sub-title="lead.userAgent.data" />
            <span class="info-panel"></span>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 1000px) {
    .info-panel-container {
        gap: 4px;
    }

    .info-panel {
        flex: 1 1 49%;
    }
}
</style>
