<script setup>
import {computed, ref, shallowRef} from 'vue'

import config from '@/pages/leads/configs/lead.config'

const {tabs} = config.config()

import {typesList} from '@/shared/data'
import {LeadsModel} from '@/entities/Leads'
import {copyToClipboard} from '@/shared/lib/utils/copyToClipboard'

import {ModalModel} from '@/entities/Modal'

import {BaseImage} from '@/shared/ui/Images'

import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseTabViewer, BaseTabViewerItem} from '@/shared/ui/Tabs'
import {SessionModel} from '@/entities/Session'
import {timeFormat} from "@/shared/lib/utils/Date/format"
import {countriesList} from "@/shared/data/countriesList"
import {BaseButton} from "@/shared/ui/Buttons";

const store = LeadsModel.usestore()
const modalStore = ModalModel.useModalStore()
const sessionStore = SessionModel.useSessionStore()

const sources = computed(() => SessionModel.getSources(sessionStore))
const partners = computed(() => SessionModel.getPartners(sessionStore))
const brands = computed(() => SessionModel.getBrands(sessionStore))

const activeTab = shallowRef(tabs[0])
const isShowAdditionalInfo = ref(false)

const lead = computed(() => store.lead)
const close = () => {
    modalStore.closeModal()
}

const setActiveTab = (tab) => {
    activeTab.value = tab
}

const showAdditionalInfo = () => {
    isShowAdditionalInfo.value = !isShowAdditionalInfo.value
}
</script>

<template>
    <div class="leads-edit" @click="close">
        <div class="content" @click.stop>
            <div v-if="!isShowAdditionalInfo" class="lead-info">
                <div class="head">
                    <BaseImage :width="50" :height="50" :path="'avatar.svg'"/>
                    <div class="info">
                        <BaseImage
:width="11" :height="11" :path="'close-gray.svg'" class="close"
                                   @click.stop="close()"/>
                        <h2 class="typo-heading-h2 fullname">{{ lead.fullname.data }}</h2>
                        <p><span class="typo-heading-h5 id">ID:</span>{{ lead.id.data }}</p>
                    </div>
                </div>
                <div class="scroll-content">
                    <div class="body">
                        <h6 class="typo-heading-h6 contact-info">Contact Information</h6>
                        <div class="info">
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Unique ID:</span>
                                <span class="lead-data">{{ lead.brandId.data ? brands[lead.brandId.data] : '-' }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.brandId.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(brands[lead.brandId.data])"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Brand ID:</span>
                                <span class="lead-data">{{ lead.id.data }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.id.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(lead.id.data)"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Type:</span>
                                <span class="lead-data type">
                                    <BaseImage
                                        v-if="lead.overrideFields.data && lead.overrideFields.data.type != lead.type.data"
                                        :width="17"
                                        :height="17"
                                        :path="'target.svg'"
                                    />
                                    {{ typesList[lead.type.data] || '-' }}
                                </span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="typesList[lead.type.data]"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(typesList[lead.type.data])"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">IP:</span>
                                <span class="lead-data">{{ lead.ip.data || '-' }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.ip.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(lead.ip.data)"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Country:</span>
                                <span class="lead-data">{{ countriesList(0)[lead.country.data].name || '-' }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="countriesList(0)[lead.country.data].name"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(countriesList(0)[lead.country.data].name)"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Email:</span>
                                <span class="lead-data">{{ lead.email.data || '-' }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.email.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(lead.email.data)"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Call Status:</span>
                                <span class="lead-data type">
                                    <BaseImage
                                        v-if="lead.overrideFields.data && lead.overrideFields.data.callStatus && lead.overrideFields.data.callStatus !== lead.callStatus.data"
                                        :width="17"
                                        :height="17"
                                        :path="'target.svg'"
                                    />
                                    {{ lead.callStatus.data || '-' }}
                                </span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.callStatus.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(lead.callStatus.data)"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Source:</span>
                                <span class="lead-data">{{
                                        lead.sourceId.data ? sources[lead.sourceId.data] : '-'
                                    }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.sourceId.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(sources[lead.sourceId.data])"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <h6 class="typo-heading-h6">General</h6>
                        <div class="info">
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Affiliate:</span>
                                <span class="lead-data">{{
                                        lead.partnerId.data ? partners[lead.partnerId.data] : '-'
                                    }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.partnerId.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(partners[lead.partnerId.data])"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Campaign:</span>
                                <span class="lead-data">{{
                                        lead.sourceId.data ? sources[lead.sourceId.data] : '-'
                                    }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.sourceId.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(sources[lead.sourceId.data])"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">Reg. Time:</span>
                                <span class="lead-data">{{
                                        lead.timeCreate.data ? timeFormat(lead.timeCreate.data, sessionStore.offset) : '-'
                                    }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.timeCreate.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(timeFormat(lead.timeCreate.data, sessionStore.offset))"
                                    />
                                </div>
                            </div>
                            <div class="item">
                                <span class="typo-heading-small-semi-bold-gray">FTD Time:</span>
                                <span class="lead-data">{{
                                        lead.timeDepositFound.data ? timeFormat(lead.timeDepositFound.data, sessionStore.offset) : '-'
                                    }}</span>
                                <div class="image-content">
                                    <BaseImage
                                        v-if="lead.timeDepositFound.data"
                                        :path="'copy.svg'"
                                        :width="15"
                                        :height="16"
                                        @click="copyToClipboard(timeFormat(lead.timeDepositFound.data, sessionStore.offset))"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="additional-btn">
                        <BaseButton
                            :text="'Additional information'" :icon="'leads-arrow.svg'" :width="265"
                            @click="showAdditionalInfo"/>
                    </div>
                </div>
            </div>
            <div class="lead-tab-view" :class="{show: isShowAdditionalInfo}">
                <BaseImage :width="11" :height="11" :path="'close-gray.svg'" class="close" @click.stop="close()"/>
                <h2 class="typo-heading-h2 title">Additional information</h2>
                <div class="tab-box">
                    <BaseTabBox class="leads-tabs" :tabs="tabs" :active="activeTab" @change-tab="setActiveTab"/>
                </div>
                <BaseTabViewer class="lead-view" :scroll-to-top="false">
                    <template #content>
                        <BaseTabViewerItem :component="activeTab.component"/>
                    </template>
                </BaseTabViewer>
                <div class="additional-btn">
                    <BaseButton :text="'Basic information'" :width="265" @click="showAdditionalInfo">
                        <template #left-icon>
                            <BaseImage :height="10" :width="14" :path="'leads-arrow-left.svg'"/>
                        </template>
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.leads-edit {
    padding: 0 24px 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.content {
    display: grid;
    grid-template-columns: 375px 1fr;
    gap: 20px;
    width: 1200px;
    position: relative;
}

.lead-tab-view,
.lead-info {
    border-radius: var(--general-border-radius);
    border: var(--general-border) solid var(--purple);
    background-color: var(--background);
    height: 660px;
}

.lead-info {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.head {
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
    width: 100%;
}

.head .info {
    grid-template-columns: 1fr;
    gap: 5px;
}

.head p span {
    margin-right: 5px;
}

.fullname {
    font-size: 18px;
    font-weight: 400;
}

.scroll-content {
    width: 100%;
    overflow: auto;
    padding: 0 24px 24px;
}

.body,
.footer {
    width: 100%;
}

.body {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
}

.title {
    font-size: 18px;
    font-weight: 400;
}

.id {
    color: var(--white);
}

h6 {
    margin-bottom: 20px;
    color: var(--white-086);
    font-size: 16px;
}

.info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 30px;
}

.item {
    display: grid;
}

.item span {
    font-size: 14px;
}

.lead-data {
    grid-area: 2/1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item .image-content {
    grid-area: 2/2;
    text-align: right;
}

.lead-tab-view {
    position: relative;
    padding: 24px;
    display: grid;
    grid-template-rows: repeat(2, max-content) 1fr;
}

.lead-view {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 30px;
}

.lead-view > div {
    height: 100%;
}

.close {
    position: absolute;
    top: 30px;
    right: 24px;
    cursor: pointer;
    user-select: none;
}

.tabs {
    margin: 20px 0 10px;
}

.item .typo-heading-small-semi-bold-gray {
    margin-bottom: 5px;
    display: inline-block;
}

.item p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.item img {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.item img:active {
    transform: scale(0.9);
}

.item p span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.unique-id {
    white-space: nowrap;
}

.type {
    display: flex;
    gap: 5px;
}

.additional-btn {
    display: none;
}

@media (max-width: 1368px) {
    .content {
        gap: 10px;
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .tab-box {
        overflow-x: auto;
    }

    .leads-tabs {
        width: 500px;
    }

    .info {
        grid-template-columns: 1fr;
    }

    .content {
        grid-template-columns: 315px 1fr;
    }

    .item {
        grid-template-columns: repeat(2, max-content);
        gap: 5px;
        align-items: flex-end;
    }

    .lead-data {
        grid-area: 1/2;
        width: 140px;
    }

    .item .image-content {
        grid-area: 1/3;
        width: 100%;
    }

    .item .typo-heading-small-semi-bold-gray {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .additional-btn {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    .additional-btn button {
        gap: 15px;
    }

    .content {
        grid-template-columns: 1fr;
    }

    .tab-view {
        height: auto;
        padding-bottom: 0;
    }

    .lead-tab-view {
        display: none;
        overflow: auto;
        height: auto;
    }

    .tabs {
        white-space: nowrap;
    }

    .leads-tabs {
        width: auto;
    }

    .lead-tab-view.show {
        display: inline-block;
    }
}
</style>
