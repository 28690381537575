<script setup>
/**
 * BaseInput
 * ------------------------------
 * Description:
 * A basic input component that allows users to input text.
 *
 * Usage:
 * <BaseInput
 *    :type="'text'"
 *    :placeholder="'Enter text'"
 *    :modelValue="inputValue"
 *    :padding="{ top: '10px', right: '0px', bottom: '10px', left: '20px' }"
 *    :limit="10"
 *    v-model="updateInputValue"
 * />
 *
 * Props:
 * - label: The label for the input.
 * - type: The type of input ('text', 'password') - defaults to 'text'.
 * - placeholder: The placeholder text for the input - defaults to an empty string.
 * - width: The width of the input field - defaults to 'auto'.
 * - modelValue: The value of the input field - required.
 * - padding: The padding for the input field - defaults to { top: '10px', right: '0px', bottom: '10px', left: '20px' }.
 * - limit: The character limit for the input field - defaults to null.
 *
 * Events:
 * - update:modelValue: Emitted when the value of the input field changes.
 */

import { ref, defineProps, computed, defineEmits } from 'vue'
import { BaseImage } from '@/shared/ui/Images'

const emits = defineEmits(['update','update:modelValue'])
const props = defineProps({
    type: {
        type: String,
        default: 'text'
    },
    placeholder: {
        type: String,
        default: ''
    },
    width: {
        type: String,
        default: 'auto'
    },
    modelValue: {
        type: [String, Number],
        required: true
    },
    padding: {
        type: Object,
        default: () => ({
            top: '11px',
            right: '8px',
            bottom: '11px',
            left: '16px'
        })
    },
    limit: {
        type: Number,
        default: null
    },
    design: {
        type: String
    },
    disabled: {
        type: Boolean,
        default: false
    },
    label: {
        type: String
    },
    error: {
        type: Boolean,
        default: false
    }
})

const visibilityState = ref(false)
const showValue = () => {
    if (!props.modelValue) {
        return
    }

    visibilityState.value = !visibilityState.value
}

const setType = () => {
    if (props.type === 'password') {
        return visibilityState.value ? 'text' : 'password'
    } else if (props.type === 'number') {
        return 'string'
    }

    return props.type
}

const setPadding = computed(() => {
    const p = props.padding
    return `${p.top ?? '10px'} ${p.right ?? '0px'} ${p.bottom ?? '10px'} ${p.left ?? '20px'}`
})

const handleInput = ($event) => {
    if (props.type === 'number') {
        let cleanedValue = $event.target.value.replace(/[^\d+.]|(.+)\+/g, '$1')
        if (cleanedValue !== $event.target.value) {
            $event.target.value = cleanedValue
            emits('update:modelValue', cleanedValue)
            emits('update', cleanedValue)
        }
    }

    let value = $event.target.value
    if (!props.limit) {
        emits('update:modelValue', value)
        emits('update', value)
        return
    }

    if (value.length <= props.limit) {
        emits('update:modelValue', value)
        emits('update', value)
    } else {
        value = value.slice(0, props.limit)
        event.target.value = value
        emits('update:modelValue', value)
        emits('update', value)
    }
}
</script>

<template>
    <div class="input-box" :class="[design, { error: error }]">
        <label v-if="label">{{ label }}</label>
        <slot name="label"></slot>
        <div class="input-container" :style="{ width: `${width}` }">
            <input
                :type="setType()"
                :placeholder="placeholder"
                :value="modelValue"
                :style="{ padding: setPadding }"
                :disabled="disabled"
                @input="handleInput"
            />
            <div v-if="type === 'password'" class="password-visibility" @click="showValue()">
                <BaseImage :class="{disabled: !modelValue}" :path="`${visibilityState ? 'visible' : 'hidden'}_password.svg`" :width="19" :height="12" />
            </div>
            <span v-if="limit" class="limit">({{ modelValue.length }}/{{ limit }})</span>
            <div class="actions">
                <slot name="action"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.input-box {
    display: inline-grid;
    grid-template-rows: max-content 1fr;
}

.input-container {
    background-color: var(--select-bg);
    border-radius: var(--general-border-radius);
    display: grid;
    grid-template-columns: 1fr max-content;
    place-items: center stretch;
    height: fit-content;
}

.icon {
    padding: 0 20px 0 15px;
    color: var(--primary-icon-gray);
    cursor: pointer;
    user-select: none;
}

.icon.clear {
    padding: 5px 12px;
}

input {
    outline: none;
    color: var(--primary-white);
    background: transparent;
    line-height: 1;
    font-weight: 400;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 14px;
    height: 40px;
}

input::placeholder {
    font-size: 14px;
    color: var(--white-064);
    font-weight: 400;
}

.options {
    display: flex;
    align-items: center;
}

.limit {
    margin-right: 10px;
    color: var(--primary-gray);
}

.input-box.input-step .input-container {
    background: var(--gray-600);
    color: var(--gray-500);
}

.input-box.input-step input {
    padding: 10px !important;
}

.password-visibility {
    padding: 0 20px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*custom design*/

.input-box.no-border .input-container {
    border-radius: 0;
}

.input-box.transparent .input-container {
    background-color: transparent;
}

.input-box.reset .input-container {
    border-radius: 4px var(--general-border-radius) var(--general-border-radius) 4px;
}

.password-visibility img.disabled {
    opacity: .5;
}

.actions {
    display: flex;
    align-items: center;
    height: 100%;
}

@media (max-width: 1000px) {
    input::placeholder {
        font-size: 14px;
    }
}
</style>
