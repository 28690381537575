<script setup>
/**
 * OptionList
 * ------------------------------
 * Description:
 * A Vue component for rendering a list of options. This component emits an 'action' event when an option is selected.
 *
 * Usage:
 * <OptionList
 *    :list="options"
 *    :state="true/false"
 *    :placement="top/bottom"
 *    @action="handleAction"
 * />
 *
 * Props:
 * - list: The list of options to render. Should be an object containing key-value pairs representing option labels and corresponding values.
 * - state: A boolean indicating the state of the option list. Default is false.
 * - placement: A boolean indicating the placement of the option list. Default is bottom.
 *
 * Events:
 * - action: Emitted when an option is selected. Provides the selected option value.
 */

import {defineProps, defineEmits, defineModel, computed, onMounted} from 'vue'
import {UserModel} from '@/entities/User'

const emits = defineEmits(['action', 'update:modelValue'])
const [value] = defineModel()

const userStore = UserModel.useUserStore()
const userPermissions = computed(() => userStore.user.permissions) || []

const props = defineProps({
    list: {
        type: Object
    },
    placement: {
        type: String,
        default: 'bottom'
    }
})

const sendAction = (item) => {
    item.func ? item.func() : emits('action', item.key)
    value.value = false
    document.removeEventListener('click', closeAllSelects)
}

const filteredList = computed(() => {
    return props.list.filter(option =>
        !option.right || userPermissions.value.includes(option.right)
    )
})

const onOverlayAfterEnter = () => {
    document.addEventListener('click', closeAllSelects)
}

const closeAllSelects = () => {
    if (value.value) {
        value.value = false
    }
    document.removeEventListener('click', closeAllSelects)
}
</script>

<template>
    <Transition name="fade" @after-enter="onOverlayAfterEnter">
        <ul v-if="value" class="option-list multiple-select" :class="{ [placement]: placement }" @click.stop>
            <li
                v-for="i in filteredList"
                :key="i.key"
                :class="{ 'light-red': i.key.split('_')[0] === 'delete' }"
                @click="sendAction(i)"
            >
<!--                <slot name="icon"/>-->
                <slot
                    name="custom-option"
                    v-bind="{ actionKey: i.key, actionValue: i.value, custom: i.custom || false }"
                >
                    {{ i.value }}
                </slot>
            </li>
        </ul>
    </Transition>
</template>

<style scoped>
.option-list {
    position: absolute;
    border-radius: 0 var(--general-border-radius) var(--general-border-radius) var(--general-border-radius);
    background-color: var(--gray-800);
    z-index: 1;
    left: 20px;
    transition: opacity 0.2s ease-in-out;
    overflow: hidden;
}

.option-list.bottom {
    top: 60px;
    transform: translateY(-10px);
}

.option-list.top {
    bottom: 20px;
    transform: translateY(10px);
}

li {
    cursor: pointer;
    transition: background-color .3s ease-in-out;
    padding: 12px 16px;
}

li:hover {
    background-color: var(--white-08);
}

li:last-child {
    border-bottom: 0;
}

.fade-enter-active,
.fade-leave-active,
.fade-enter,
.fade-leave-to {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media (max-width: 1000px) {
    .option-list {
        left: 20px;
    }

    .option-list.bottom {
        top: 50px;
        transform: translateY(-10px);
    }

    ul li {
        font-size: 12px;
        padding: 8px;
    }
}
</style>
