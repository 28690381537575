<script setup>
import {defineProps, ref, defineModel, onMounted} from 'vue'
import {MaskInput} from 'vue-3-mask'

import {BaseToggle} from '@/shared/ui/Inputs'
import {useGeneralNotificationsStore} from '@/entities/GeneralNotifications/model'
import {isValidTimeRange, minutesToTime} from '@/entities/Sources/model'
import {BaseImage} from "@/shared/ui/Images";

const generalNotificationsStore = useGeneralNotificationsStore()
const [activity = {}] = defineModel()

const props = defineProps({
    schedule: {
        type: Object
    }
})

onMounted(() => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    if (props.schedule && Object.keys(props.schedule).length) {
        Object.entries(props.schedule).forEach(([i, item]) => {
            const dayIndex = i % 7
            if (!Object.keys(item).length) {
                activity.value[i] = {
                    day: daysOfWeek[dayIndex],
                    time: '10:30 - 18:00',
                    state: 0
                }
            } else {
                activity.value[i] = {
                    day: daysOfWeek[dayIndex],
                    time: minutesToTime(item),
                    state: 1
                }
            }
        })
    } else {
        for (let i = 0; i < 7; i++) {
            activity.value[i] = {
                day: daysOfWeek[i],
                time: '10:30 - 18:00',
                state: 1
            }
        }
    }
})

const updateValTime = ref('')

const changeActivityState = (item, state) => {
    updateValTime.value = item.time
    item.state = state
}

const updateTime = (timeObject) => {
    timeObject.updating = false
    if (!isValidTimeRange(updateValTime.value)) {
        generalNotificationsStore.showError({
            message: 'Incorrect time range format!'
        })
        return
    }

    timeObject.time = updateValTime.value
    updateValTime.value = ''
}

const startUpdating = (i) => {
    updateValTime.value = i.time
    for (const item in activity.value) {
        if (activity.value[item].updating) {
            activity.value[item].updating = false
        }
    }

    i.updating = true
}
</script>

<template>
    <div v-for="i in activity" :key="i.day" class="activity-item" @click.stop>
        <span class="day">{{ i.day }}</span>
        <div class="switch">
            <BaseToggle :value="i.state" @change="(value) => changeActivityState(i, value)"/>
            {{ i.state ? 'On' : 'Off' }}
        </div>
        <div class="time-container">
            <div v-if="!i.updating" class="edit" @click="startUpdating(i)">
                {{ i.time }}
                <BaseImage
                    :path="'edit.svg'"
                    :width="12"
                    :height="12"
                />
            </div>
            <div v-else class="mask-input">
                <MaskInput
                    v-model="updateValTime"
                    :placeholder="i.time"
                    :mask="'##:## - ##:##'"
                />
                <svg
                    class="approve green"
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="updateTime(i)">
                    <path
                        d="M4.44912 7.88963L1.13019 4.78001L0 5.83147L4.44912 10L14 1.05145L12.8778 0L4.44912 7.88963Z"
                        fill="white"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<style scoped>
.activity-item {
    display: grid;
    place-items: center;
    grid-template-columns: 40px repeat(2, max-content);
    gap: 8px;
    margin-bottom: 8px;
}

.activity-item:last-child {
    margin-bottom: 0;
}

.activity-item .day {
    text-align: left;
    width: 100%;
}

.switch {
    display: flex;
    align-items: center;
    gap: 4px;
}

.time-container {
    padding: 10px 15px;
    background: var(--gray-600);
    border-radius: 8px;
    cursor: pointer;
}

.time-container .edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    color: var(--white-086);
    font-size: 12px;
}

.mask-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input {
    outline: none;
    color: var(--primary-white);
    background: transparent;
    line-height: 1;
    font-weight: var(--general-font-weight);
    width: 90%;
    text-overflow: ellipsis;
    height: 100%;
    font-size: 12px;
}

input::placeholder {
    font-weight: var(--general-font-weight);
    color: var(--primary-gray);
}

.approve.green path {
    fill: var(--light-green);
}

@media (max-width: 1000px) {
    .activity-item {
        grid-template-columns: 30px max-content 1fr;
    }

    .time-container {
        width: 100%;
    }
}
</style>
