import {computed} from 'vue'
import {SessionModel} from '@/entities/Session'
import {countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()
        const brands = computed(() => SessionModel.getBrands(sessionStore))
        const partners = computed(() => SessionModel.getPartners(sessionStore))
        return {
            tableId: 'dashboard-table',
            mainCols: computed(() => [
                {
                    label: 'Name',
                    key: 'name',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Leads',
                    key: 'leads',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'FTD',
                    key: 'ftd',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Conversion',
                    key: 'conversion',
                    type: 'number',
                    sortable: true
                }
            ]),
            brandFailuresCols: computed(() => [
                {
                    label: 'Name',
                    key: 'name',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Failures',
                    key: 'failures',
                    type: 'string',
                    sortable: true
                }
            ]),
            timesFilters: computed(() => [
                {
                    title: 'Today',
                    value: [
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime() / 1000),
                        Math.floor(new Date().getTime() / 1000)
                    ]
                },
                {
                    title: 'Yesterday',
                    value: [
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 0, 0, 0).getTime() / 1000),
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime() / 1000) - 1
                    ]
                },
                {
                    title: 'Last 7 Days',
                    value: [
                        Math.floor(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0) / 1000),
                        Math.floor(new Date().getTime() / 1000)
                    ]
                },
                {
                    title: 'Current Month',
                    value: [
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000),
                        Math.floor(new Date().getTime() / 1000)
                    ]
                },
                {
                    title: 'Last Month',
                    value: [
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime() / 1000),
                        Math.floor(new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime() / 1000)
                    ]
                },
                {
                    title: 'All Time',
                    type: 'customTime',
                    active: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'brandIds',
                    placeholder: 'Select Brands',
                    type: 'multiselect',
                    data: brands.value
                },
                {
                    field: 'partnerIds',
                    placeholder: 'Select Affiliates',
                    type: 'multiselect',
                    data: partners.value
                },
                {
                    field: 'countries',
                    placeholder: 'Select Countries',
                    type: 'multiselect',
                    data: countriesList()
                },
            ]),
            searchList:
                computed(() => [
                    {
                        value: 'Name',
                        key: 'globalFilter'
                    }
                ])
        }
    }
}
