<script setup></script>

<template>
    <Transition name="fade" mode="out-in">
        <slot name="content"></slot>
    </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
