<script setup>
import {onMounted, onUnmounted, ref, defineProps, computed} from 'vue'
import {router} from '@/app/providers'

import {ModalModel} from '@/entities/Modal'
import {SourcesModel} from '@/entities/Sources'

import {SourceCard} from '@/shared/ui/Cards'
import {BaseImage} from '@/shared/ui/Images'
import {BaseToggle} from '@/shared/ui/Inputs'
import {OptionList} from '@/shared/ui/Panels'
import {IconStar} from '@/shared/ui/Icons'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'

const props = defineProps({
    showFavoriteState: {
        type: [Boolean, Number],
        default: true
    }
})

onMounted(() => {
    document.addEventListener('click', closeAllSelects)
})

onUnmounted(() => {
    document.removeEventListener('click', closeAllSelects)
})

const modalStore = ModalModel.useModalStore()
const store = SourcesModel.useSourcesStore()
const title = usePageTitle('Campaign Sources')

const favorites = computed(() => store.favorites.filter((el) => el.type === 1))
const campaignSources = computed(() => {
    if (!store.sources) {
        return []
    }

    let filteredSources = store.sources.filter((i) => i.type === 1)

    filteredSources.forEach((source) => {
        source.isFavorite = favorites.value.some((favorite) => favorite.id === source.id)
    })

    if (favorites.value.length > 0) {
        filteredSources = filteredSources.filter((source) => !source.isFavorite)
    }

    return filteredSources
})

const optionList = ref([
    {
        key: 'edit',
        value: 'Edit'
    },
    {
        key: 'duplicate',
        value: 'Duplicate'
    },
    {
        key: 'favorite',
        value: 'Favorite'
    },
    {
        key: 'delete',
        value: 'Delete'
    }
])

const changeFavoriteState = (item) => {
    item.isFavorite = !item.isFavorite
    item.selectIsOpen = false

    let sourcesFavorites = JSON.parse(localStorage.getItem('sources-favorite')) || []

    const existingFavoriteIndex = sourcesFavorites.findIndex((favorite) => favorite.id === item.id)
    if (item.isFavorite && existingFavoriteIndex === -1) {
        store.addToFavorites(item)
        sourcesFavorites.push(item)

        const index = campaignSources.value.findIndex((source) => source.id === item.id)
        if (index !== -1) {
            campaignSources.value.splice(index, 1)
        }
    } else if (!item.isFavorite && existingFavoriteIndex !== -1) {
        store.removeFromFavorites(existingFavoriteIndex)
        sourcesFavorites.splice(existingFavoriteIndex, 1)
    }

    localStorage.setItem('sources-favorite', JSON.stringify(sourcesFavorites))
}

const changeStateOptionSelect = (item) => {
    campaignSources.value.forEach((i) => {
        if (i.id !== item.id) {
            i.selectIsOpen = false
        }
    })

    favorites.value.forEach((i) => {
        if (i.id !== item.id) {
            i.selectIsOpen = false
        }
    })

    item.selectIsOpen = !item.selectIsOpen
}

const remove = (item) => {
    changeStateOptionSelect(item)
    modalStore.setActionData({
        option: 'delete_source',
        table: 'sources-table',
        id: item.id
    })
    modalStore.setModal('confirmation')
}

const duplicate = (item) => {
    changeStateOptionSelect(item)
    modalStore.setActionData({
        option: 'duplicate_source',
        table: 'sources-table',
        id: item.id
    })
    modalStore.setModal('confirmation')
}

const closeAllSelects = () => {
    if (!campaignSources.value) {
        return
    }

    campaignSources.value.forEach((i) => {
        i.selectIsOpen = false
    })

    favorites.value.forEach((i) => {
        i.selectIsOpen = false
    })
}

const open = (item) => {
    store.setSource(item)
    modalStore.setModal("sourceModal")
    // router.push({name: 'sourcePage', params: {id: item.id}})
}

const edit = (item) => {
    store.setSource(item)
    closeAllSelects()
    modalStore.setModal('editSource')
}

const makeAction = (key, item) => {
    switch (key) {
        case 'delete':
            remove(item)
            break
        case 'edit':
            edit(item)
            break
        case 'duplicate':
            duplicate(item)
            break
        default:
            break
    }
}
</script>

<template>
    <div class="campaign-sources">
        <h4 v-if="favorites.length && showFavoriteState" class="typo-heading-h4">Favorites</h4>
        <div v-if="favorites.length && showFavoriteState" class="favorites">
            <SourceCard v-for="i in favorites" :key="i.id" :is-favorite="i.isFavorite" :data="i" @click="open(i)">
                <template #actions>
                    <div class="action-container">
                        <BaseImage
                            v-if="!i.selectIsOpen"
                            :width="16"
                            :height="16"
                            :path="'inner-menu.svg'"
                            @click.stop="changeStateOptionSelect(i)"
                        />
                        <div v-if="i.selectIsOpen" class="close-container" @click.stop="changeStateOptionSelect(i)">
                            <BaseImage
                                :width="11"
                                :height="11"
                                :path="'close.svg'"
                            />
                        </div>
                    </div>
                    <OptionList v-model="i.selectIsOpen" :list="optionList">
                        <template #custom-option="{ actionKey, actionValue }">
                            <template v-if="actionKey === 'favorite'">
                                <span class="fav-state">
                                    <span class="image-box">
                                        <IconStar/>
                                    </span>
                                    {{ actionValue }}
                                    <BaseToggle
                                        :value="i.isFavorite || false"
                                        @change="(value) => changeFavoriteState(i, value)"
                                    />
                                </span>
                            </template>
                            <div
                                v-else-if="actionKey === 'delete'"
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`close-red.svg`"
                                        :width="11"
                                        :height="11"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                            <div
                                v-else-if="actionKey === 'edit'"
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`edit.svg`"
                                        :width="12"
                                        :height="12"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                            <div
                                v-else
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`${actionKey}.svg`"
                                        :width="20"
                                        :height="15"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                        </template>
                    </OptionList>
                    <div class="action-container">
                        <IconStar :is-active="i.isFavorite" @click.stop="changeFavoriteState(i)"/>
                    </div>
                </template>
            </SourceCard>
        </div>
        <div class="sources">
            <SourceCard v-for="i in campaignSources" :key="i.id" :data="i" @click="open(i)">
                <template #actions>
                    <div class="action-container">
                        <BaseImage
                            v-if="!i.selectIsOpen"
                            :width="16"
                            :height="16"
                            :path="'inner-menu.svg'"
                            @click.stop="changeStateOptionSelect(i)"
                        />
                        <div v-if="i.selectIsOpen" class="close-container" @click.stop="changeStateOptionSelect(i)">
                            <BaseImage
                                :width="11"
                                :height="11"
                                :path="'close.svg'"
                            />
                        </div>
                    </div>
                    <OptionList v-model="i.selectIsOpen" :list="optionList">
                        <template #custom-option="{ actionKey, actionValue }">
                            <template v-if="actionKey === 'favorite'">
                                <span class="fav-state">
                                   <span class="image-box">
                                        <IconStar/>
                                    </span>
                                    {{ actionValue }}
                                    <BaseToggle
                                        :value="i.isFavorite || false"
                                        @change="(value) => changeFavoriteState(i, value)"
                                    />
                                </span>
                            </template>
                            <div
                                v-else-if="actionKey === 'delete'"
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`close-red.svg`"
                                        :width="11"
                                        :height="11"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                            <div
                                v-else-if="actionKey === 'edit'"
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`edit.svg`"
                                        :width="12"
                                        :height="12"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                            <div
                                v-else
                                class="option"
                                @click.stop="makeAction(actionKey, i)">
                                <div class="image-box">
                                    <BaseImage
                                        :path="`${actionKey}.svg`"
                                        :width="20"
                                        :height="15"
                                    />
                                </div>
                                <span>{{ actionValue }}</span>
                            </div>
                        </template>
                    </OptionList>
                    <div class="action-container">
                        <IconStar :is-active="i.isFavorite" @click.stop="changeFavoriteState(i)"/>
                    </div>
                </template>
            </SourceCard>
        </div>
    </div>
</template>

<style scoped>
h4 {
    margin-bottom: 16px;
}

.sources {
    display: flex;
    flex-wrap: wrap;
}

.card-box {
    flex: 1 1 152px;
    max-width: 350px;
    width: 100%;
    box-sizing: border-box;
}

.sources,
.favorites {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: var(--general-border) solid var(--white-08);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.sources {
    padding: 0;
    border: none;
}

.action-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-container {
    padding: 8px;
    border-radius: 4px 4px 0 0;
    background: var(--gradient-button);
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.fav-state {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.fav-state .image-box,
.option .image-box {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option-list.bottom {
    top: 56px;
    transform: none;
    left: 8px;
}

@media (min-width: 768px) {
    .sources,
    .favorites {
        display: grid;
        grid-template-columns:  repeat(auto-fill, 350px);
    }
}

@media (max-width: 767px) {
    .sources,
    .favorites {
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }
}
</style>
