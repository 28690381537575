<script setup>

</script>

<template>
    <div class="container">
        <div class="content">
            <slot/>
        </div>
    </div>
</template>

<style scoped>

</style>
