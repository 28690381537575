import { computed } from 'vue'
import { typesList } from '@/shared/data'
import { SessionModel } from '@/entities/Session'
import { countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'leads-table',
            pathDt: 'leads/dt',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'ID',
                    key: 'id',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Full Name',
                    key: 'fullname',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return elData
                    }
                },
                {
                    label: 'Email',
                    key: 'email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Phone',
                    key: 'phone',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a href="tel:${elData.replace('+', '')}">${elData}</a>`
                    }
                },
                {
                    label: 'Country',
                    key: 'country',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return countriesList()[elData]
                    }
                },
                {
                    label: 'IP',
                    key: 'ip',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Type',
                    key: 'type',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'User Agent',
                    key: 'userAgent',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Country Detected',
                    key: 'countryDetected',
                    type: 'func',
                    func: (data, elData) => {
                        return countriesList()[elData]
                    },
                    sortable: true
                },
                {
                    label: 'Affiliate',
                    key: 'partnerId',
                    type: 'func',
                    func: (data, elData) => {
                        return sessionStore.partners[elData]
                    },
                    sortable: true
                },
                {
                    label: 'Brand',
                    key: 'brandId',
                    type: 'func',
                    func: (data, elData) => {
                        return sessionStore.brands[elData]
                    },
                    sortable: true
                },
                {
                    label: 'Modals Internal ID',
                    key: 'brandInternalId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Campaign ID',
                    key: 'campaignId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Source',
                    key: 'sourceId',
                    type: 'func',
                    func: (data, elData) => {
                        return sessionStore.sources[elData]
                    },
                    sortable: true
                },
                {
                    label: 'Time Create',
                    key: 'timeCreate',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Time Deposit',
                    key: 'timeDeposit',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Time Deposit Found',
                    key: 'timeDepositFound',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Call Status Mapped',
                    key: 'callStatusMapped',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Call Status',
                    key: 'callStatus',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'timeCreate',
                    placeholder: 'Data Range',
                    type: 'time',
                },
                {
                    field: 'type',
                    placeholder: 'Select Type',
                    type: 'multiselect',
                    data: typesList
                },
                {
                    field: 'brandId',
                    placeholder: 'Select Brands',
                    type: 'multiselect',
                    data: sessionStore.brands || []
                },
                {
                    field: 'sourceId',
                    placeholder: 'Select Sources',
                    type: 'multiselect',
                    data: sessionStore.sources || []
                },
                {
                    field: 'partnerId',
                    placeholder: 'Select Affiliates',
                    type: 'multiselect',
                    data: sessionStore.partners || []
                },
                {
                    field: 'country',
                    placeholder: 'Select Country',
                    type: 'multiselect',
                    data: countriesList()
                }
            ]),
            typesFilter: computed(() => [
                {
                    field: 0,
                    title: 'Leads',
                    type: 'array'
                },
                {
                    field: 1,
                    title: 'Clients',
                    type: 'array'
                },
                {
                    field: 2,
                    title: 'Failures',
                    type: 'array'
                },
            ]),
            searchByList: computed(() => [
                {
                    key: 'email',
                    value: 'Email'
                },
                {
                    key: 'phone',
                    value: 'Phone'
                },
                {
                    key: 'fullname',
                    value: 'Full Name'
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'delete_lead',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [
                        { key: 'type', label: 'Change Type', length: 2, buttonText: 'Change' },
                        { key: 'callStatus', label: 'Change Call Status', length: 2 },
                        { key: 'note', label: 'Change Note', length: 2 },
                        { key: 'delete_mass_leads', label: 'Delete' }
                    ]
                },
                {
                    key: 'type',
                    placeholder: 'Change Type',
                    options: [
                        { key: '0', label: 'Lead' },
                        { key: '1', label: 'Client' },
                        { key: '2', label: 'Failure' }
                    ]
                },
                {
                    key: 'callStatus',
                    placeholder: 'Change To...',
                    options: [
                        { key: 'New', label: 'New' },
                        { key: 'Test', label: 'Test' },
                        { key: 'In progress', label: 'In progress' }
                    ]
                },
                {
                    key: 'note',
                    placeholder: 'Change To...',
                    type: 0
                }
            ])
        }
    }
}
