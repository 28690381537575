import {defineStore} from "pinia"
import RestService from "@/shared/api/rest.service"

import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

export const useReportsStore = defineStore('reportsStore', {
    state: () => ({

    }),
    actions: {
        getReportInfo(field, data) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            return new Promise((resolve, reject) => {
                RestService.post('reports/general/statuses', {
                    filters: {
                        where:{
                            ...data
                        }
                    }
                })
                    .then((ans) => {
                        resolve(ans.data.data)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        },

        getReportFtds(field, data) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            return new Promise((resolve, reject) => {
                RestService.post('reports/general/ftds', {
                    filters: {
                        where:{
                            ...data
                        }
                    }
                })
                    .then((ans) => {
                        resolve(ans.data.data)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        }
    }
})
