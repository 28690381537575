<script setup>
import {computed, ref} from 'vue'

import {BaseInput, BaseSelectBox, BaseTextarea, BaseToggle} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'

import {BrandsModel} from '@/entities/Brands'
import {useAppStore} from '@/app/providers'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {SessionModel} from '@/entities/Session'
import {BaseBorderPanel} from '@/shared/ui/Panels'
import {isValidDomain} from "@/shared/lib/utils/validation"
import {useModalStore} from "@/entities/Modal/model";

const store = BrandsModel.useBrandsStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const modalStore = useModalStore()

const brand = computed(() => BrandsModel.getBrand(store))
const connectors = computed(() => SessionModel.getConnectors(sessionStore))
const users = computed(() => SessionModel.getUsers(sessionStore))

const note = ref(brand.value.note.data || '')
const emailValidation = ref(brand.value?.emailValidation.data || 0)
const privacy = ref(brand.value?.privacy.data || 0)
const name = ref(brand.value?.name.data || 0)
const apiConnector = ref(brand.value['apiConnector']?.data || '')
const apiToken = ref(brand.value['apiToken']?.data || '')
const apiUrl = ref(brand.value['apiUrl']?.data || '')
const userIdCreated = ref(brand.value['userIdCreated']?.data || '')
const apiUrlParam1 = ref(brand.value['apiUrlParam1']?.data || '')

const saving = ref(false)

const save = () => {
    saving.value = true
    if (!isValidDomain(apiUrl.value)) {
        generalNotificationsStore.showError({
            message: 'Not valid Api URl'
        })
        saving.value = false
        return
    }

    if (!name.value) {
        generalNotificationsStore.showError({
            message: 'Incorrect Brand Name'
        })
        saving.value = false
        return
    }

    appStore
        .update(
            {
                note: note.value,
                privacy: privacy.value,
                emailValidation: emailValidation.value,
                apiConnector: apiConnector.value,
                apiToken: apiToken.value,
                apiUrl: apiUrl.value,
                userIdCreated: userIdCreated.value,
                name: name.value,
                apiUrlParam1: apiUrlParam1.value
            },
            brand.value,
            'brands-table'
        )
        .then(() => {
            generalNotificationsStore.showSuccess({
                title: 'Changes Applied:',
                message: 'You’ve changed your settings successfully'
            })
            saving.value = false
            appStore.getList('brands')
        })
}

const changePrivacy = (option) => {
    privacy.value = option
}

const close = () => {
    modalStore.closeModal()
}
</script>

<template>
    <div class="settings-tab">
        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-heading-h2">Add Parameters for API Campaign</h2>
            </template>
            <template #content>
                <div class="api">
                    <BaseInput v-model="name" :placeholder="'Enter Brand Name'" :label="'Brand Parameter'"/>
                    <BaseInput v-model="apiToken" :placeholder="'Enter API Token'" :label="'API Token'"/>
                    <BaseInput v-model="apiUrl" :placeholder="'Enter API Url'" :label="'Brand API Urls'"/>
                    <BaseInput v-model="apiUrlParam1" :placeholder="'Enter Api Url Param1'">
                        <template #label>
                            <label>Api Url Param1</label>
                        </template>
                    </BaseInput>
                    <BaseSelectBox
                        v-model="apiConnector"
                        :options="
                            Object.entries(connectors).map(([key, value]) => ({
                                value: value,
                                title: value
                            }))
                        "
                        :placeholder="apiConnector || 'Select Connector'"
                    >
                        <template #label>
                            <label>Connector</label>
                        </template>
                    </BaseSelectBox>
                    <BaseSelectBox
                        v-model="userIdCreated"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :placeholder="users[userIdCreated] || 'Select Owner'"
                        :label="'Owner'"
                    />
                </div>
            </template>
        </BaseBorderPanel>

        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-heading-h2">Privacy</h2>
            </template>
            <template #content>
                <div class="info-box">
                    <div class="info">
                        <p class="typo-heading-small">What is the campaign privacy?</p>
                        <p class="typo-heading-small">Public campaigns will include all affiliates automatically.</p>
                        <p class="typo-heading-small">Private campaigns will only include manually added affiliates</p>
                    </div>
                    <div class="buttons">
                        <BaseButton
                            :text="'Public'"
                            :width="100"
                            :height="40"
                            :design="`${privacy ? 'outline' : 'fill'}`"
                            @click="changePrivacy(0)"
                        />
                        <BaseButton
                            :text="'Private'"
                            :width="100"
                            :height="40"
                            :design="`${!privacy ? 'outline' : 'fill'}`"
                            @click="changePrivacy(1)"
                        />
                    </div>
                </div>
            </template>
        </BaseBorderPanel>

        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-heading-h2">Check Email Validation</h2>
            </template>
            <template #content>
                <span class="typo-heading-small">
                    When active, invalid emails result in lead failure and appear as unassigned.
                </span>
                <div class="switch-content">
                    <span class="typo-heading-small">Check Email Validation</span>
                    <BaseToggle :value="emailValidation" @change="(value) => (emailValidation = value)">
                        <template #state>
                            {{ emailValidation ? 'On' : 'Off' }}
                        </template>
                    </BaseToggle>
                </div>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel :border="false">
            <template #content>
                <BaseTextarea
                    v-model="note"
                    :height="90"
                    :label="'Note'"
                    :placeholder="'Enter the description'"
                />
            </template>
        </BaseBorderPanel>
        <div class="save">
            <BaseButton :width="100" :design="'outline'" :height="40" :text="'Cancel'" @click="close()"/>
            <BaseButton :width="66" :height="40" :text="'Save'" :load="saving" @click="save()"/>
        </div>
    </div>
</template>

<style scoped>
.info-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

p {
    margin-bottom: 10px;
}

p:last-child {
    margin-bottom: 0;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item .input-box {
    margin-bottom: 20px;
}

.item .input-box:last-child {
    margin-bottom: 0;
}

.switch-content {
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.api {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

.save {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.switch-box {
    gap: 6px;
}

.input-box {
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 12px;
}

@media (max-width: 1000px) {
    h2 {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 14px;
    }
}
</style>
