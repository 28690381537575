<script setup>
/**
 * BaseMultiselect
 * ------------------------------
 * Description:
 * A multi-select dropdown component that allows users to select multiple options from a list.
 *
 * Usage:
 * <BaseMultiselect
 *    :placeholder="'Select an option'"
 *    :field="'exampleField'"
 *    :options="[ { key: '1', value: 'Option 1' }, { key: '2', value: 'Option 2' } ]"
 *    :search="true"
 *    :multiselect="true"
 *    :selectedValues="[ '1', '2' ]"
 *    :width="180"
 *    :removePossible="false"
 *    :design="'fill'"
 *    @change="handleMultiselectChange"
 *    @remove="handleRemoveOption"
 * />
 *
 * Props:
 * - placeholder: The placeholder text displayed when no option is selected.
 * - field: The field identifier for the multiselect component.
 * - options: The array of options to choose from. Each option should have a 'key' and 'value'.
 * - search: Boolean indicating whether to enable search functionality in the dropdown.
 * - multiselect: Boolean indicating whether to allow multiple selections.
 * - selectedValues: Array of keys representing the initially selected options.
 * - width: The width of the dropdown component.
 * - removePossible: Boolean indicating whether it's possible to remove selected options.
 * - design: The design style of the dropdown.
 *
 * Events:
 * - change: Emitted when the selected values change. Provides the updated selected values.
 * - remove: Emitted when an option is removed. Provides the removed option key.
 */

import {defineProps, ref, defineEmits, computed, watch, toRef} from 'vue'
import {BaseInput} from '@/shared/ui/Inputs'

import {SessionModel} from '@/entities/Session'
import {BaseImage} from '@/shared/ui/Images'
import BaseCheckBox from "@/shared/ui/Inputs/BaseCheckBox.vue"
import {BaseButton} from "@/shared/ui/Buttons"

const sessionStore = SessionModel.useSessionStore()
const resetSelect = computed(() => sessionStore.resetSelect)

const props = defineProps({
    placeholder: {
        type: String,
        default: ''
    },
    field: {
        type: String
    },
    options: {
        type: Array,
        default: () => [],
        validator: (options) => {
            return options.every((option) => option.value || option.key)
        },
        required: true
    },
    selectedValues: {
        type: Array,
        default: () => []
    },
    width: {
        type: Number,
        default: null
    },
    design: {
        type: String
    },
    disable: {
        type: Boolean,
        default: false
    },
    tips: {
        type: Object,
        default: () => {}
    },
    error: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['change', 'remove'])
const select = ref(null)
const selectedCount = ref(props.selectedValues.length)

const SEARCH_VISIBLE_FROM = 10

const values = toRef(props, 'options')
let localSelectedValues = ref([...props.selectedValues])
let selectedTips = ref([])
const searchData = ref('')
const selectIsOpen = ref(false)
const showTips = ref(false)
const isSelectAll = ref(false)
const isRemoveAll = ref(false)

const closeAllSelects = () => {
    if (selectIsOpen.value) {
        selectIsOpen.value = false
        reset()
    }

    document.removeEventListener('click', closeAllSelects)
}

const onOverlayAfterEnter = () => {
    document.addEventListener('click', closeAllSelects)
}

const toggleDropdown = () => {
    if (props.disable) {
        return
    }

    selectIsOpen.value = !selectIsOpen.value
    searchData.value = ''
}

const reset = () => {
    localSelectedValues.value = [...props.selectedValues]
    isRemoveAll.value = false
    isSelectAll.value = false
    searchData.value = ''
}

const save = () => {
    let queryParam = localSelectedValues.value

    emits('change', {
        [props.field]: queryParam
    })

    setTimeout(() => {
        closeAllSelects()
        localSelectedValues.value = queryParam
    }, 1)
}

const chooseElement = (option) => {
    const index = localSelectedValues.value.indexOf(option.key)
    if (index === -1) {
        localSelectedValues.value.push(option.key)
    } else {
        localSelectedValues.value.splice(index, 1)
    }
}

const chooseTips = (tip) => {
    const index = selectedTips.value.indexOf(tip.key)

    if (index === -1) {
        selectedTips.value.push(tip.key)

        for (const country of tip.value) {
            if (!localSelectedValues.value.includes(country)) {
                localSelectedValues.value.push(country)
            }
        }

    } else {
        selectedTips.value.splice(index, 1)

        const currentSelected = [...localSelectedValues.value]

        // Check if a country is selected by any other remaining tips
        const isSelectedByOtherTip = (country) => {
            return selectedTips.value.some(selectedTip =>
                selectedTip !== tip.key && Object.values(props.tips).find(t => t.key === selectedTip).value.includes(country)
            )
        }

        // Remove countries from the unselected tip that are not selected by any other remaining tips and are not in props.selectedValues
        for (const country of tip.value) {
            if (!isSelectedByOtherTip(country) && !props.selectedValues.includes(country)) {
                const countryIndex = currentSelected.indexOf(country)
                if (countryIndex !== -1) {
                    currentSelected.splice(countryIndex, 1)
                }
            }
        }

        localSelectedValues.value = currentSelected
    }
}

const isSelected = (value, obj) => {
    return obj.includes(value)
}

const selectAll = () => {
    isSelectAll.value = !isSelectAll.value
    isRemoveAll.value = false
    localSelectedValues.value = filteredOptions.value.map((obj) => obj.key)
}

const removeAll = () => {
    isRemoveAll.value = !isRemoveAll.value
    isSelectAll.value = false
    const filteredKeys = filteredOptions.value.map(item => item.key)
    localSelectedValues.value = localSelectedValues.value.filter(value => !filteredKeys.includes(value))
    selectedCount.value = localSelectedValues.value.length
    selectedTips.value = []
}

const filteredOptions = computed(() => {
    const sortedValues = [...values.value].sort((a, b) => a.value.localeCompare(b.value))

    if (searchData.value) {
        const searchLower = searchData.value.toLowerCase()

        return sortedValues.filter((obj) => {
            return String(obj.value).toLowerCase().includes(searchLower)
        })
    }

    return sortedValues
})

const filteredTips = computed(() => {
    let sortedValues = [...props.tips].sort((a, b) => a.title.localeCompare(b.title))

    if (searchData.value) {
        const searchLower = searchData.value.toLowerCase()

        sortedValues = sortedValues.filter((obj) => {
            return String(obj.title).toLowerCase().includes(searchLower)
        })
    }

    return sortedValues
})

const getPlaceholder = () => {
    return localSelectedValues.value.length ? `Selected ${localSelectedValues.value.length} / ${Object.keys(props.options).length}` : props.placeholder
}

const getSelectedInfo = () => {
    const filteredArrayValue = filteredOptions.value.map(item => item.key)
    selectedCount.value = 0

    if (localSelectedValues.value.length) {
        selectedCount.value = localSelectedValues.value.filter(value => filteredArrayValue.includes(value)).length
        return `Selected ${selectedCount.value} / ${filteredArrayValue.length}`
    }

    return `Selected ${selectedCount.value} / ${filteredArrayValue.length}`
}

const autoSearchVisibility = computed(() => {
    return Object.keys(props.options).length > SEARCH_VISIBLE_FROM
})

watch(
    () => sessionStore.clearFilters,
    () => {
        removeAll()
    }
)

watch(
    () => props.selectedValues,
    (value) => {
        if (props.field === resetSelect.value) {
            localSelectedValues.value = value
        }
    }
)
</script>

<template>
    <div
        ref="select"
        class="select"
        :class="{ [design]: design, open: selectIsOpen, error: error, }"
        :style="{ width: width ? width + 'px' : '100%' }"
        @click="toggleDropdown"
    >
        <slot name="label"></slot>
        <div class="placeholder-box">
            <span class="placeholder">
                <slot name="placeholder">{{ getPlaceholder() }}</slot>
            </span>
            <BaseImage :width="10" :height="10" :path="'arrow.svg'" class="arrow"/>
        </div>
        <Transition name="select-fade" @after-enter="onOverlayAfterEnter">
            <div v-if="selectIsOpen" class="select-dropdown" @click.stop>
                <div class="fixed-bar">
                    <div class="options">
                        <div class="option" @click="selectAll()">
                            <BaseCheckBox :checked="isSelectAll" :value="isSelectAll" @checked="selectAll()"/>
                            <p>Select All</p>
                        </div>
                        <div class="option" @click="removeAll()">
                            <BaseCheckBox :checked="isRemoveAll" :value="isRemoveAll" @checked="removeAll()"/>
                            <p>Remove All</p>
                        </div>
                    </div>
                    <div class="selected">{{ getSelectedInfo() }}</div>
                    <div v-if="autoSearchVisibility" class="search">
                        <BaseInput v-model="searchData" :placeholder="'Search'" :design="'transparent'"/>
                        <BaseImage v-if="searchData" class="clear-icon" :width="11" :height="11" :path="'clear.svg'" @click="searchData = ''"/>
                        <BaseImage :width="20" :height="20" :path="'search-gray.svg'"/>
                    </div>
                </div>
                <template v-if="tips && Object.keys(tips).length">
                    <p class="label" @click="showTips = !showTips">
                        <slot name="tips-label">Tips:</slot>
                        <BaseImage
                            :width="11" :height="6" :path="'arrow.svg'" :class="{active: showTips}"
                            class="tips-arrow"/>
                    </p>
                    <ul v-if="showTips">
                        <li
                            v-for="(item, i) in filteredTips"
                            :key="i"
                            :class="{ active: isSelected(item.key, selectedTips) }"
                            @click="chooseTips(item)"
                        >
                            <slot :option="item" name="option">{{ item.title }}</slot>
                        </li>
                    </ul>
                </template>
                <p v-if="tips" class="label">
                    <slot name="options-label">Options:</slot>
                </p>
                <ul class="option-list">
                    <li
                        v-for="(item, i) in filteredOptions"
                        :key="i"
                        :class="{ active: isSelected(item.key, localSelectedValues) }"
                        @click="chooseElement(item)"
                    >
                        <slot :option="item" name="option">{{ item.value }}</slot>
                    </li>
                </ul>
                <div class="actions-buttons">
                    <BaseButton :text="'Cancel'" :design="'outline'" :width="80" @click="reset"/>
                    <BaseButton :text="'Ok'" :width="40" @click="save"/>
                </div>
            </div>
        </Transition>
    </div>
</template>

<!--todo move base styles into .css-->
<style scoped>
.select {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.select.open::after {
    content: '';
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1;
    background: transparent;
    cursor: default;
}

.select > * {
    filter: blur(0px);
}

.tips-arrow.active,
.select.open .arrow {
    transform: rotate(180deg);
    transform-origin: center center;
}

.tips-arrow {
    transform: rotate(0);
    z-index: -1;
}

.select-dropdown {
    position: absolute;
    width: 100%;
    transition: all 0.2s ease-in-out;
    left: 0;
    border-radius: var(--general-border-radius);
    background: var(--gray-700);
    outline: none;
    z-index: 2;
    margin-top: 10px;
}

.placeholder-box {
    color: var(--primary-gray);
    font-family: var(--general-font-weight);
    padding: 12px 16px;
    position: relative;
    width: 100%;
    background: var(--gray-600);
    border-radius: var(--general-border-radius);
    white-space: nowrap;
    display: inline-grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
    place-items: center;
}

.placeholder {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.tips-arrow,
.arrow {
    transition: transform 0.4s ease-in-out;
}

.fixed-bar {
    width: 100%;
}

.options {
    margin: 0 20px 13px 20px;
    padding: 20px 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255, 0.08);
}

.options p:last-child {
    border: none;
    padding-right: 0;
}

.option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.option-list {
    max-height: 130px;
    overflow: auto;
}

.clear-icon {
    margin-right: 20px;
}

.search {
    display: flex;
    align-items: center;
    padding-right: 18px;
    border-bottom: 1px solid var(--white-08);
}

ul li {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    padding: 13px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-white);
}

ul li.active {
    background: var(--white-08);
}

ul li:hover {
    background-color: var(--white-08);
}

.actions-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.input-box {
    width: 100%;
}

.selected {
    padding: 0 14px 10px 16px;
}

.select.transparent .placeholder-box {
    background-color: transparent;
    color: var(--primary-white);
    font-weight: var(--general-font-weight);
    padding: 0;
}

.select.transparent .placeholder-box::after {
    right: 0;
}

.select.transparent .select-dropdown {
    margin-top: 10px;
}

.select.red .placeholder-box {
    color: var(--light-red);
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 5px;
    cursor: pointer;
}

/*styles for select in load template datatable*/

.load-template .select-dropdown {
    background-color: var(--background);
}

.load-template li {
    border-bottom: 1px solid var(--white-08);
}

.load-template li:last-child {
    border-bottom: 0;
}

.load-template li:hover {
    background-color: transparent;
}
</style>
