import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const getUser = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.get(`user/${id}`)
            .then((ans) => {
                resolve(ans)
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const updateUser = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    return new Promise((resolve, reject) => {
        RestService.put(`user/${data.id}`, { ...data.data })
            .then(() => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const getTokenByUser = (id) => {
    return new Promise((resolve, reject) => {
        RestService.get(`user/${id}/token`).then((ans) => {
            if (ans) {
                resolve(ans)
            }
        })
    })
}

export const api = {
    getUser,
    updateUser,
    getTokenByUser
}
