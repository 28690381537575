import {defineStore} from 'pinia'

import {api} from '../api'

export const usePartnersStore = defineStore('partners', {
    state: () => ({
        partner: null,
        partnerDetails: null,
        brand: null
    }),
    actions: {
        setPartner(data) {
            this.partner = data
        },

        setBrand(data) {
            this.brand = data
        },

        getPartner(id) {
            return new Promise((resolve, reject) => {
                api.getPartner(id).then((ans) => {
                    this.partner = {}
                    for (const item in ans.data.data) {
                        this.partner[item] = {}
                        this.partner[item].data = ans.data.data[item]
                    }
                    resolve()
                })
            })
        },

        getPartnerDetail(id) {
            this.partnerDetails = null
            return new Promise((resolve, reject) => {
                api.getPartnerDetail(id).then((ans) => {
                    this.partnerDetails = ans.data.data
                    resolve()
                })
            })
        },

        updatePartnerDetails(data) {
            return new Promise((resolve, reject) => {
                api.updatePartnerDetails({
                    id: data.id,
                    userInitPassword: data.password
                }).then(() => {
                    resolve()
                })
            })
        },

        register(data) {
            return new Promise((resolve, reject) => {
                api.register(data)
                    .then(() => {
                        resolve()
                    })
            })
        }
    }
})
