<script setup>
import { onMounted, defineProps } from 'vue'
import { scrollToTop } from '@/shared/lib/dom'

const props = defineProps({
    scrollToTop: {
        type: Boolean,
        default: true
    },
    scrollBehavior: {
        type: String,
        default: 'smooth'
    }
})

onMounted(() => {
    if (props.scrollToTop) {
        scrollToTop(props.scrollBehavior)
    }
})
</script>

<template>
    <div class="tab-view">
        <slot name="content"></slot>
    </div>
</template>

<style scoped>
.tab-view {
    padding: 20px 0 0;
}
</style>
