import { computed } from 'vue'
import {  trafficSourceList, verticalsList } from '@/shared/data'
import {countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        // type: 1 - input, 2 - textarea , 3 - select
        return {
            fields: computed(() => ({
                general: [
                    {
                        label: 'First Name',
                        placeholder: 'Enter First Name',
                        type: 1,
                        inputType: 'string',
                        field: 'firstname'
                    },
                    {
                        label: 'Username*',
                        placeholder: 'Enter username',
                        type: 1,
                        inputType: 'string',
                        field: 'username'
                    },
                    {
                        label: 'Last Name',
                        placeholder: 'Enter Last Name',
                        type: 1,
                        inputType: 'string',
                        field: 'lastname'
                    },
                    {
                        label: 'Company Name',
                        placeholder: 'Enter Company Name',
                        type: 1,
                        inputType: 'string',
                        field: 'companyName'
                    },
                    {
                        label: 'Email*',
                        placeholder: 'Enter Email',
                        type: 1,
                        inputType: 'string',
                        field: 'email'
                    },
                    {
                        label: 'Phone number',
                        placeholder: 'Enter Phone number',
                        type: 1,
                        inputType: 'number',
                        field: 'phone'
                    }
                ],
                password: [
                    {
                        label: 'Password*',
                        placeholder: 'Enter Password',
                        type: 1,
                        inputType: 'password',
                        field: 'password'
                    },
                    {
                        label: 'Confirm Password* ',
                        placeholder: 'Confirm Password',
                        type: 1,
                        inputType: 'password',
                        field: 'confirmPassword'
                    }
                ],
                additionalInfo: [
                    {
                        label: 'Domain',
                        placeholder: 'Enter Domain',
                        type: 1,
                        inputType: 'string',
                        field: 'domain'
                    },
                    {
                        label: 'Country',
                        placeholder: 'Select Country',
                        type: 2,
                        data: countriesList(),
                        field: 'country',
                        search: true,
                        multiselect: false
                    },
                    {
                        label: 'Telegram',
                        placeholder: 'Enter Telegram',
                        type: 1,
                        inputType: 'string',
                        field: 'telegram'
                    },
                    {
                        label: 'Traffic Sources',
                        placeholder: 'Select Traffic',
                        type: 2,
                        data: trafficSourceList,
                        field: 'trafficSource',
                        search: false,
                        multiselect: false
                    },
                    {
                        label: 'Skype',
                        placeholder: 'Enter Skype',
                        type: 1,
                        inputType: 'string',
                        field: 'skype'
                    },
                    {
                        label: 'Verticals',
                        placeholder: 'Select Vertical',
                        type: 2,
                        data: verticalsList,
                        field: 'vertical',
                        search: false,
                        multiselect: false
                    }
                ]
            }))
        }
    }
}
