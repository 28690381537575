<script setup>
/**
 * BaseTextarea
 * ------------------------------
 * Description:
 * A Vue component representing a textarea input with optional character limit and customizable height.
 *
 * Usage:
 * <BaseTextarea
 *    v-model="textareaValue"
 *    :placeholder="'Enter text here'"
 *    :limit="100"
 *    :height="150"
 *    @update:modelValue="handleTextareaUpdate"
 * />
 *
 * Props:
 * - placeholder: The placeholder text for the textarea input.
 * - modelValue: The value of the textarea input. Required.
 * - limit: The character limit for the textarea input.
 * - height: The height of the textarea input in pixels.
 *
 * Events:
 * - update:modelValue: Emitted when the value of the textarea input changes. Provides the updated value.
 */

import {defineProps, defineEmits} from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
    placeholder: {
        type: String
    },
    modelValue: {
        type: String,
        required: true
    },
    limit: {
        type: Number
    },
    height: {
        type: Number
    },
    label: {
        type: String
    }
})

const handleInput = ($event) => {
    let value = $event.target.value
    if (value.length <= props.limit) {
        emits('update:modelValue', value)
    } else {
        value = value.slice(0, props.limit)
        event.target.value = value
        emits('update:modelValue', value)
    }
}
</script>

<template>
    <div class="textarea-box">
        <label v-if="label">{{ label }}</label>
        <div class="textarea" :style="{ height: height + 'px' }">
            <textarea :placeholder="placeholder" :value="modelValue" @input="handleInput"/>
            <span v-if="limit" class="typo-heading-small-semi-bold-gray limit">({{ modelValue.length }}/{{
                    limit
                }})</span>
        </div>
    </div>
</template>

<style scoped>
.textarea {
    background: var(--select-bg);
    border-radius: var(--general-border-radius);
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

label {
    color: var(--white-086);
}

textarea {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    color: var(--primary-white);
    font-weight: var(--general-font-weight);
    background-color: transparent;
}

textarea::placeholder {
    color: var(--primary-gray);
    font-weight: var(--general-font-weight);
}

.limit {
    text-align: right;
    display: inline-block;
    width: 100%;
}
</style>
