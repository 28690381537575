<script setup>
import {computed, defineProps, onUnmounted, ref, defineEmits} from 'vue'

import {BaseButton} from '@/shared/ui/Buttons'
import {BaseInput, BaseSelectBox, BaseMultiselect} from '@/shared/ui/Inputs'

import {SelectsStepperModel} from '@/entities/SelectsStepper'

const props = defineProps({
    actionList: {
        type: Array,
        default: () => []
    }
})
const emits = defineEmits(['update', 'cancel'])

onUnmounted(() => {
    if (!Object.keys(selectedActions.value).length) {
        return
    }

    SelectsStepperModel.useSelectsStepperStore().$reset()
})

const selectsStepperStore = SelectsStepperModel.useSelectsStepperStore()
const selectedActions = computed(() => selectsStepperStore.selectedActions)
const inputData = ref('')

const onOptionChange = (stepKey, value) => {
    selectsStepperStore.add(stepKey, value)
}

const shouldShow = (step) => {
    return step.key === 'action' || Object.values(selectedActions.value).some((option) => option.value === step.key)
}

const openReqModal = () => {
    let option = selectedActions.value['action'].value
    let action = selectedActions.value[option]?.value || inputData.value

    emits('update', {
        option: option,
        action: action
    })
}

const shouldRenderButton = () => {
    const {action} = selectedActions.value
    return (
        action &&
        (action.length === Object.keys(selectedActions.value).length ||
            action.value.split('_')[0] === 'delete' ||
            inputData.value)
    )
}

const cancel = () => {
    emits('cancel')
}
</script>

<template>
    <div class="selects-stepper">
        <div class="steppers">
            <template v-for="step in actionList" :key="step.key">
                <template v-if="shouldShow(step)">
                    <BaseInput
                        v-if="step.type === 0"
                        v-model="inputData"
                        :design="'input-step'"
                        :placeholder="step.placeholder"
                    />
                    <BaseSelectBox
                        v-else
                        v-model="selectedActions[step.key]"
                        :placement="'top'"
                        :design="'outline-select'"
                        :options="
                            step.options.map((option) => ({
                                value: option.key.toString(),
                                title: option.label.toString(),
                                length: option.length,
                                buttonText: option.buttonText
                            }))
                        "
                        @update="(option) => onOptionChange(step.key, option)"
                    >
                        <template #placeholder>
                            <span :class="{gray: !selectedActions[step.key]?.title, 'light-red': selectedActions[step.key]?.title === 'Delete'}">
                                {{ selectedActions[step.key]?.title || step.placeholder }}</span>
                        </template>
                        <template #option="{ option }">
                            {{ option.title }}
                        </template>
                    </BaseSelectBox>
                </template>
            </template>
            <div v-if="shouldRenderButton()" class="options">
                <BaseButton
                    :design="'outline'"
                    :text="'Cancel'"
                    :width="100"
                    @click="cancel()"
                />
                <BaseButton
                    :width="100"
                    :text="selectedActions['action'].buttonText || 'Update'"
                    @click="openReqModal()"
                />
            </div>
        </div>
        <BaseButton
            class="cancel"
            :design="'outline'"
            :text="'Cancel'"
            :width="140"
            @click="cancel()"
        />
    </div>
</template>

<style scoped>
.selects-stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.steppers {
    display: flex;
    align-items: center;
    gap: 20px;
}

.options {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-box,
.select {
    width: 230px;
    height: 40px;
}

.options button:first-child {
    display: none;
}

@media (max-width: 1000px) {
    .selects-stepper {
        flex-direction: column;
        align-items: flex-start;
    }

    .steppers {
        flex-direction: column;
    }

    .options {
        width: 100%;
        justify-content: space-between;
    }

    .input-box,
    .select {
        width: 315px;
    }

    .cancel {
        display: none;
    }

    .options button:first-child {
        display: block;
    }
}

@media (max-width: 767px) {
    .steppers {
        width: 100%;
    }

    .input-box,
    .select {
        width: 100%;
    }
}
</style>
