<script setup>
import { computed } from 'vue'

import { GeneralNotificationsItem } from '@/entities/GeneralNotifications'

import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const notifications = computed(() => GeneralNotificationsModel.getNotifications(generalNotificationsStore))
</script>

<template>
    <TransitionGroup name="list" tag="div" class="general-notifications">
        <GeneralNotificationsItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
        />
    </TransitionGroup>
</template>

<style scoped>
.general-notifications {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: var(--gutter) calc(var(--gutter) + var(--general-scrollbar-width));
    z-index: 110;
}

.list-enter-active {
    transform: translateX(100vw);
}

.list-enter,
.list-leave-to {
    transition: all 0.25s ease-in-out;
    opacity: 0;
    transform: translateX(100px);
}
</style>
