<script setup>
import { ref } from 'vue'
import { BaseImage } from '@/shared/ui/Images'

const drawerIsOpen = ref(false)
</script>

<template>
    <div class="pad">
        <div class="header" @click="drawerIsOpen = !drawerIsOpen">
            <slot name="header"></slot>
            <div class="image-box arrow">
                <BaseImage :path="'arrow.svg'" :width="11" :height="6" />
            </div>
        </div>
        <div v-if="drawerIsOpen" class="content">
            <div class="body">
                <slot name="request"></slot>
                <slot name="response"></slot>
            </div>
            <slot name="auto-login"></slot>
        </div>
    </div>
</template>

<style scoped>
.pad {
    margin-bottom: 30px;
}

.pad:last-child {
    margin-bottom: 0;
}

.header {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr) max-content;
    /*place-items: center;*/
    background-color: var(--gray-1000);
    border-radius: var(--general-border-radius);
    height: 56px;
    cursor: pointer;
}

.content {
    padding: 8px 0;
    display: grid;
    grid-template-rows: 1fr max-content;
}

.body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin-bottom: 8px;
}

.pad.open .header {
    background: linear-gradient(90deg, #4f6be8 0%, var(--purple) 100%);
    border-radius: var(--general-border-radius) var(--general-border-radius) 0 0;
}

.pad.open .content {
    border: var(--general-border) solid #4f6be8;
    border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
}

.image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.pad.open .arrow {
    transform: rotate(180deg);
}

@media (max-width: 1000px) {
    .header {
        grid-template-columns: 1fr;
        height: auto;
        gap: 32px;
        position: relative;
    }

    .image-box {
        position: absolute;
        bottom: 12px;
        right: 20px;
    }

    .result {
        justify-content: flex-start;
    }
}
</style>
