import { computed } from 'vue'


import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import {countriesList} from "@/shared/data/countriesList"

export default {
    config: () => {
        const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'affiliate-tables',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Partner',
                    key: 'partner.username',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Email',
                    key: 'partner.email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Status',
                    key: 'status',
                    type: 'boolean',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'brandId',
                    placeholder: 'Select Brand',
                    type: 'select',
                    multiselect: false,
                    search: true,
                    data: sessionStore.brands || []
                },
                {
                    field: 'role',
                    placeholder: 'Select Role',
                    type: 'select',
                    data: (() => {
                        return countriesList()
                    })()
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'delete_affiliate',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [{ key: 'status', label: 'Change Status', length: 2, buttonText: 'Change' }]
                },
                {
                    key: 'status',
                    placeholder: 'Select Status',
                    options: [
                        { key: '0', label: 'Disable' },
                        { key: '1', label: 'Enable' }
                    ]
                }
            ])
        }
    }
}
