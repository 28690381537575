<script setup>
import { computed, onMounted, shallowRef } from 'vue'
import { router } from '@/app/providers'

import config from '@/pages/user/configs/user.config'
const { tabs } = config.config()

import { UsersModel } from '@/entities/Users'

import { BaseBack } from '@/shared/ui/Actions'
import { SinglePageTitle } from '@/shared/ui/Page'
import { BaseTabBox } from '@/shared/ui/Tabs'
import { BaseTabViewer, BaseTabViewerItem } from '@/shared/ui/Tabs'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'

onMounted(() => {
    if (!user.value) {
        usersStore.getUser(userID).then(() => {
            title = usePageTitle(user.value['firstname'].data + ' ' + user.value['lastname'].data)
        })
    }
})

const userID = router.currentRoute.value.params.id.toString()
const usersStore = UsersModel.useUsersStore()

const user = computed(() => usersStore.user)
let title

if (user.value) {
    title = usePageTitle(user.value['firstname'].data + ' ' + user.value['lastname'].data)
}

const activeTab = shallowRef(tabs[0])
const setActiveTab = (tab) => {
    activeTab.value = tab
}
</script>

<template>
    <div class="single-page container">
        <SinglePageTitle
            :icon="'page-icons/users.svg'"
            :loaded="!!user"
            :title="user ? user.firstname.data + ' ' + user.lastname.data : ''"
        />
        <BaseBack :path-name="'usersPage'" />
        <BaseTabBox :tabs="tabs" :active="activeTab" @change-tab="setActiveTab" />
        <BaseTabViewer>
            <template #content>
                <BaseTabViewerItem :load="!!user" :component="activeTab.component" />
            </template>
        </BaseTabViewer>
    </div>
</template>

<style scoped>
.tab-view {
    padding: 0;
}
</style>
