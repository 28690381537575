<script setup>
import config from '@/pages/leads/configs/leads-transaction-tab.config'

const { cols, tableId } = config.config()

import { SearchInput } from '@/features/Search'
import { DataTable } from '@/features/Datatable'
</script>

<template>
    <div class="transactions-tab">
        <div class="top">
            <h4>Total Results: 3</h4>
            <SearchInput />
        </div>
        <DataTable :path="'leads/dt'" :table-id="tableId" :fields="cols" />
    </div>
</template>

<style scoped>
.transactions-tab {
    padding-right: 5px;
}

h4 {
    font-size: 21px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>
