<script setup>
import {defineProps, defineModel, defineEmits, ref} from 'vue'
import {BaseInput} from "@/shared/ui/Inputs/index"
import { MaskInput } from 'vue-3-mask'

const [phone] = defineModel()
const emits = defineEmits(['update'])

const props = defineProps({
    label: {
        type: String,
    },
    error: {
        type: Boolean,
        default: false
    }
})

const update = (data) => {
    emits('update', data)
}
</script>

<template>
    <div class="phone-input" :class="{ error: error }">
        <label>{{ label }}</label>
        <div class="inputs">
            <BaseInput v-model="phone.prefix" :type="'number'" :placeholder="'Prefix'" @update="update"/>
            <div class="input-container">
                <MaskInput v-model="phone.phone" :placeholder="'Enter Number'" :value="phone.phone" :mask="phone.phoneMask"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.inputs {
    display: grid;
    grid-template-columns: 75px 1fr;
    gap: 5px;
}

.phone-input.error label {
    color: var(--light-red);
}

.input-container {
    background-color: var(--select-bg);
    border-radius: var(--general-border-radius);
    display: grid;
    grid-template-columns: 1fr max-content;
    place-items: center stretch;
    padding: 10px 0px 10px 20px;
}

input {
    outline: none;
    color: var(--primary-white);
    background: transparent;
    line-height: 1;
    font-weight: var(--general-font-weight);
    width: 100%;
    text-overflow: ellipsis;
}

input::placeholder {
    font-weight: var(--general-font-weight);
    color: var(--primary-gray);
}
</style>
