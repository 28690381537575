<script setup>
import { computed, defineProps } from 'vue'

import { BaseImage } from '@/shared/ui/Images'

import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

const generalNotifications = GeneralNotificationsModel.useGeneralNotificationsStore()

const props = defineProps({
    notification: {
        type: Object,
        default: () => {}
    }
})

const getClassType = computed(() => {
    switch (props.notification.type) {
        case 0:
            return 'error'
        case 1:
            return 'success'
        case 2:
            return 'warning'
        default:
            return ''
    }
})

const getNotificationTitle = computed(() => {
    if (props.notification.title) {
        return props.notification.title
    }

    switch (props.notification.type) {
        case 0:
            return 'Error:'
        case 1:
            return 'Success:'
        case 2:
            return 'Warning:'
        default:
            return ''
    }
})

const close = () => {
    generalNotifications.removeNotification(props.notification.id)
}
</script>

<template>
    <div class="notification" :class="[getClassType]">
        <div class="close">
            <BaseImage :width="8" :height="8" :path="'close-white.svg'" @click.stop="close()" />
        </div>
        <div class="content" @click.stop>
            <small class="typo-heading-extra-small">{{ getNotificationTitle }}</small>
            <p v-html="notification.message"></p>
        </div>
    </div>
</template>

<style scoped>
.notification {
    padding: 10px;
    border-radius: var(--general-border-radius);
    width: 220px;
    transition: all 0.2s ease-in-out;
}

.close {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-bottom: 10px;
}

.close img {
    cursor: pointer;
}

.content {
    padding-right: 40px;
    padding-bottom: 40px;
    font-size: 10px;
    font-weight: 500;
}

.content small {
    display: block;
    margin-bottom: 10px;
}

.notification.warning,
.notification.success {
    background: linear-gradient(91deg, #4f6be8 0%, #9d95ff 100%);
}

.notification.error {
    background: var(--light-red);
}
</style>
