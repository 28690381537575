/**
 * Checks if required fields have data.
 * @param {Object} fields - The object containing fields to be checked.
 * @returns {boolean} Returns true if all required fields have data, otherwise false.
 */
export const checkOnRequiredFields = (fields) => {
    return checkFields(fields)
}

const formatFields = (fields) => {
    const formattedFields = {}
    for (const key in fields) {
        formattedFields[key] = {
            data: fields[key],
            error: false
        }
    }
    return formattedFields
}

const checkFields = (fields) => {
    let hasError = false

    for (const key in fields) {
        const field = fields[key]
        if (field.data !== undefined && field.data !== null) {
            if (Array.isArray(field.data)) {
                field.error = field.data.length === 0 || field.data.some(item => !item)
            } else if (typeof field.data === 'object') {
                field.error = !checkFields(formatFields(field.data))
            } else {
                if (field.type === 'email') {
                    field.error = !field.data
                } else {
                    field.error = !field.data
                }
            }
        } else {
            field.error = true
        }

        if (field.error) {
            hasError = true
        }
    }

    return !hasError
}

/**
 * Checks if the provided domain string is a valid domain.
 *
 * The domain can optionally start with "www.", followed by one or more
 * labels (each containing letters, digits, or hyphens) separated by dots.
 * The domain must end with a top-level domain (TLD) consisting of at least
 * two letters. An optional path can follow the TLD.
 *
 * Examples of valid domains:
 * - example.com
 * - www.example.com
 * - subdomain.example.com
 * - www.subdomain.example.com
 * - example.com/path
 * - www.example.com/path
 *
 * @param {string} domain - The domain string to validate.
 * @returns {boolean} - Returns true if the domain is valid, false otherwise.
 */
export const isValidDomain = (domain) => {
    const domainRegex = /^(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/;
    return domainRegex.test(domain)
}

/**
 * Validates if the provided string is a valid IPv4 address.
 *
 * An IPv4 address consists of four octets, each ranging from 0 to 255,
 * separated by dots. Each octet is represented by one to three digits.
 *
 * Examples of valid IPv4 addresses:
 * - 192.168.1.1
 * - 255.255.255.255
 * - 0.0.0.0
 * - 172.16.254.1
 *
 * @param {string} ip - The string to validate as an IPv4 address.
 * @returns {boolean} - Returns true if the string is a valid IPv4 address, false otherwise.
 */
export const isValidIP = (ip) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
}

/**
 * Checks if the given email address is valid.
 *
 * This function uses a regular expression to validate the email format.
 *
 * @param email - The email address to validate.
 * @returns True if the email address is valid, false otherwise.
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email)
}
