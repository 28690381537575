import { computed } from 'vue'

export default {
    config: () => {
        return {
            tableId: 'transactions-table',
            cols: computed(() => [
                {
                    label: 'Created',
                    key: 'created',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Type',
                    key: 'type',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Payout',
                    key: 'payout',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Revenue',
                    key: 'revenue',
                    type: 'number',
                    sortable: true
                }
            ])
        }
    }
}
