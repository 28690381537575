<script setup>
import {defineProps, defineEmits, ref, defineModel} from 'vue'
import {TimePicker} from "@/features/Time"
import {BaseLoader} from "@/shared/ui/Loaders"

const emits = defineEmits(['update', 'update:modelValue'])
const [value] = defineModel()

const props = defineProps({
    options: {
        type: Array,
        required: true,
        default: null
    }
})

const timeRange = ref({})

const localOptions = ref(props.options)

const setValue = (el) => {
    unselectAll()
    el.active = true
    timeRange.value = {}
    emits('update', {
        from: el.value ? el.value[0] : 0,
        to: el.value ? el.value[1] : 0,
        period: el.title,
    })
}

const unselectAll = () => {
    localOptions.value.forEach((item) => {
        item.active = false
    })
}

const setRangeTime = (time, el) => {
    unselectAll()
    el.active = time.timestamp

    let from = time.timestamp ? time.timestamp[0] / 1000 : 0
    let to = time.timestamp ? time.timestamp[1] / 1000 : 0

    emits('update', {
        from: from,
        to: to,
        period: el.title,
    })
}
</script>

<template>
    <div class="switch-box">
        <template v-for="i in localOptions" :key="i">
            <BaseLoader v-if="i.type && i.type === 'customTime' && !value.length"/>
            <TimePicker
                v-else-if="i.type && i.type === 'customTime'"
                :class="{ active: i.active }"
                :design="'switch-range'"
                :placeholder="i.title"
                :is-reactive="false"
                @update="(time) => setRangeTime(time, i)"
            />
            <div
                v-else
                class="element"
                :class="{ active: i.active }"
                @click="setValue(i)"
            >
                {{ i.title }}
            </div>
        </template>
        <div v-if="$slots['custom-option']" class="custom-element element" @click="unselectAll">
            <slot name="custom-option"></slot>
        </div>
    </div>
</template>

<style scoped>
.switch-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: var(--dark-bg);
    border-radius: var(--general-border-radius);
    font-size: 16px;
}

.element {
    padding: 15px 0;
    width: 300px;
    height: 44px;
    color: var(--primary-gray);
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch-range.active,
.element.active {
    background: var(--acent-linear-gradient);
    color: var(--primary-white);
    border-radius: var(--general-border-radius);
}

.loader-wrapper {
    width: 100px;
}
</style>
