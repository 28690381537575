import {computed} from "vue"

export default {
    config: () => {
        return {
            tableId: 'statuses-table',
            path: 'statuses/dt',
            cols: computed(() => [{
                label: '',
                key: '-',
                sortable: false
            },{
                label: 'Status',
                key: 'status',
                sortable: true
            }, {
                label: 'Mapped Status',
                key: 'mappedStatus',
                sortable: true
            },]),
            tableActions: computed(() => [
                {
                    key: 'delete_status',
                    value: 'Delete'
                }
            ]),
            searchByList: computed(() => [{
                key: 'mappedStatus',
                value: 'Mapped Status'
            },]),
        }
    }
}
