import { defineStore } from 'pinia'

export const useSelectsStepperStore = defineStore('selectsStepper', {
    state: () => ({
        selectedActions: []
    }),
    actions: {
        add(key, data) {
            if (key === 'action') {
                this.selectedActions = []
            }

            this.selectedActions[key] = data
        }
    }
})
