<script setup>
import {computed, onMounted, onUnmounted, ref, shallowRef} from 'vue'
import {useRouter} from 'vue-router'

import {PageTitle} from '@/shared/ui/Page'
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseTabBox} from '@/shared/ui/Tabs'
import {BaseToggle} from '@/shared/ui/Inputs'
import {SearchInput} from '@/features/Search'

import {TabSourcesCampaign, TabSourcesCountry} from '@/pages/sources/tabs'

import {ModalModel} from '@/entities/Modal'
import {SourcesModel} from '@/entities/Sources'
import {BaseLoader} from "@/shared/ui/Loaders"
import {BaseImage} from "@/shared/ui/Images";

onMounted(() => {
    store.getSources()
        .then(() => {
            loaded.value = true
        })
})

onUnmounted(() => {
    store.setActiveTab('')
})

const modalStore = ModalModel.useModalStore()
const store = SourcesModel.useSourcesStore()
const comeFrom = computed(() => store.activeTab)

const loaded = ref(false)
const searchIsOpen = ref(false)
const router = useRouter()

const campaignSources = computed(() => {
    if (!store.sources) {
        return []
    }

    return store.sources.filter((i) => i.type === 1)
})

const countrySources = computed(() => {
    if (!store.sources) {
        return []
    }

    return store.sources.filter((i) => i.type === 2)
})

const sourcesTabs = computed(() => {
    const campaignSourcesCount = campaignSources.value.length || ''
    const countrySourcesCount = countrySources.value.length || ''

    return [
        {
            key: 'campaignSources',
            value: 'Campaign Sources',
            count: campaignSourcesCount,
            component: TabSourcesCampaign
        },
        {
            key: 'countrySources',
            value: 'Country Sources',
            count: countrySourcesCount,
            component: TabSourcesCountry
        }
    ]
})

const activeTab = shallowRef(comeFrom.value || sourcesTabs.value[0])
const showFavoriteState = ref(JSON.parse(localStorage.getItem('show-favorites') || 1))

const searchBy = ref([])
searchBy.value = [
    {
        value: 'Name',
        key: 'globalFilter'
    }
]

const search = (data) => {
    store.setConfig(data)
}

const showFavorite = (data) => {
    showFavoriteState.value = data
    localStorage.setItem('show-favorites', showFavoriteState.value)
}

const createSource = () => {
    if (activeTab.value.key === 'campaignSources') {
        modalStore.setModal('createSource')
    } else if (activeTab.value.key === 'countrySources') {
        router.push({name: 'createCountrySourcePage'})
    }
}

router.beforeEach((to, from, next) => {
    if (from.params.id) {
        let source = store.sources.find((i) => i.id === from.params.id)
        if (!source) {
            next()
            return
        }
        store.setActiveTab(source.type === 1 ? sourcesTabs.value[0] : sourcesTabs.value[1])
    }
    next()
})
</script>

<template>
    <div class="sources-screen container">
        <PageTitle :title="'Sources'" :icon="'page-icons/sources.svg'">
            <template #custom-action>
                <BaseButton :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="createSource()"/>
            </template>
        </PageTitle>

        <div class="top">
            <BaseTabBox
                :tabs="sourcesTabs"
                :active="activeTab"
                :disable-border="true"
                @change-tab="(tab) => (activeTab = tab)"
            />
            <div class="end">
                <div class="switch">
                    <BaseToggle :value="showFavoriteState" @change="showFavorite"/>
                    Show Favorites
                </div>
                <div v-if="!searchIsOpen" class="search-icon"  @click="searchIsOpen = true">
                    <BaseImage :height="20" :width="20" :path="'search.svg'"/>
                </div>
                <SearchInput v-else :search-list="searchBy" :table-id="'sources-table'" @search="search"/>
            </div>
        </div>

        <component :is="activeTab.component" v-if="loaded" :show-favorite-state="showFavoriteState"/>
        <BaseLoader v-else/>
    </div>
</template>

<style scoped>
button {
    font-size: 21px;
}

.page-title {
    margin-bottom: 10px;
}

.top {
    border-bottom: var(--general-border) solid var(--white-08);
    padding-bottom: 10px;
    align-items: flex-end;
    margin-bottom: 20px;
}

.end,
.top {
    display: flex;
    justify-content: space-between;
}

.end {
    gap: 20px;
}

.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

@media (max-width: 1000px) {
    .top {
        flex-direction: column-reverse;
        gap: 24px;
    }

    .end {
        width: 100%;
        gap: 8px;
    }

    .tabs {
        width: 100%;
        justify-content: center;
    }

    .page-title {
        margin-bottom: 16px;
    }

    .search-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: transparent;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
}
</style>
