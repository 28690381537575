<script setup>
import config from '@/pages/partner/configs/partner-brands-tab'
import { router } from '@/app/providers'

const { cols, tableId, actionList, tableActions, searchByList } = config.config()

import { DataTable } from '@/features/Datatable'
import { PaginationBox, FiltersList, TableActions } from '@/features/Datatable'

import { ModalModel } from '@/entities/Modal'
import { PartnersModel } from '@/entities/Partners'
import {computed} from "vue"
import {BaseButton} from "@/shared/ui/Buttons"

const modalStore = ModalModel.useModalStore()
const store = PartnersModel.usePartnersStore()

const affiliateId = computed(() => PartnersModel.getPartner(store).id.data)

const openEdit = (data) => {
    store.setBrand(data)
    modalStore.setModal('editPartnerBrand')
}

const openAdd = () => {
    modalStore.setModal('createPartnerBrand')
}
</script>

<template>
    <div class="single-tab">
        <DataTable
            :fields="cols"
            :path="`brand/${affiliateId}/brands/dt`"
            :table-id="tableId"
            :order="'brandId'"
            :action-list="actionList"
            :option-list="tableActions"
            @open="openEdit"
        >
            <template #top-left>
                <FiltersList :filters="[]" >
                    <template #custom-button>
                        <BaseButton
                            :icon="'plus.svg'"
                            :width="40"
                            :height="40"
                            @click="openAdd"
                        />
                    </template>
                </FiltersList>
            </template>
            <template #center-right>
                <TableActions :actions="['reset', 'saveTemplate', 'customize']" :search-list="searchByList" />
            </template>
            <template #bottom-left>
                <PaginationBox />
            </template>
        </DataTable>
    </div>
</template>

<style scoped>
.filters-list {
    display: none;
}

.custom-filters-list {
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .filters-list {
        display: block;
    }
}

@media (max-width: 768px) {
    .single-tab {
        width: 261px;
    }
}
</style>
