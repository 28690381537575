import { item as leadsItem } from './leads'
import { item as dashboardItem } from './dashboard'
import { item as brandsItem } from './brands'
import { item as sourcesItem } from './sources'
import { item as partnersItem } from './partners'
import { item as depositsItem } from './deposits'
import { item as marketplaceItem } from './marketplace'
import { item as usersItem } from './users'
import { item as reportsItem } from './reports'

export const headerLinks = [
    leadsItem,
    dashboardItem,
    brandsItem,
    sourcesItem,
    partnersItem,
    depositsItem,
    marketplaceItem,
    usersItem,
    reportsItem
]
