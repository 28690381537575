import { defineStore } from 'pinia'

export const usestore = defineStore('leads', {
    state: () => ({
        lead: null
    }),
    actions: {
        setLead(data) {
            this.lead = data
        }
    }
})
