import {defineStore} from 'pinia'
import {SessionModel} from '@/entities/Session'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {ignoredColsList} from '@/shared/data'
import RestService from '@/shared/api/rest.service'

export const useDatatableStore = defineStore('datatable', {
    state: () => ({
        tableId: '',
        url: '',
        initOrder: '',
        datatableData: {},
        datatableConfig: {},
        columns: {},
        baseColumns: {},
        limit: 25,
        pageLength: [25, 50, 100],
        total: null,
        selectMode: false,
        tableIsLoaded: false,
        isEditLoaded: false,
        // todo remove in future
        reportsTotal: null
    }),
    actions: {
        setSelectMode(data) {
            this.selectMode = data
        },

        resetTable() {
            this.datatableConfig = {
                offset: 0,
                limit: this.limit,
                filters: {},
                globalFilter: '',
                order: {
                    [this.initOrder]: -1
                }
            }

            this.columns[this.tableId] = this.baseColumns[this.tableId]
                .filter((col) => !col.isCustom)
                .map((col) => ({
                    ...col,
                    visible: true
                }))

            localStorage.setItem(`DataTables_${this.tableId}`, JSON.stringify(this.columns[this.tableId]))
            this.getDatatableData()
        },

        setBaseColumns(data) {
            this.baseColumns[this.tableId] = data
        },

        setColumns(data) {
            this.columns[this.tableId] = data
        },

        sortByOrder(data) {
            this.datatableConfig.order = {
                [data]: this.datatableConfig.order[data] === -1 ? 1 : -1
            }

            this.getDatatableData()
        },

        setDataTableConfig(data) {
            const {datatableConfig} = this
            datatableConfig.filters = {}

            for (const item in data) {
                const element = data[item]
                const {field, type, data: elData} = element

                if (!type) {
                    datatableConfig.filters[data[item].field] = data[item].data
                }

                switch (type) {
                    case 'array':
                    case 'string':
                    case 'number':
                        datatableConfig.filters[field] = parseInt(elData[0]?.key) || elData
                        break
                    case 'limit':
                        datatableConfig.limit = elData
                        this.limit = elData
                        datatableConfig.offset = 0
                        break
                    case 'globalFilter':
                        datatableConfig.globalFilter = elData
                        break
                    default:
                        datatableConfig.filters[field] = elData
                        break
                }

                if (Array.isArray(datatableConfig.filters[field]) && !datatableConfig.filters[field].length) {
                    delete datatableConfig.filters[field]
                }
            }

            if (!data.length) {
                datatableConfig.filters = {}
            }


            this.getDatatableData()
        },

        clearFilters(leaveField = null) {
            for (let field in this.datatableConfig.filters) {
                if (leaveField && field !== leaveField.key) {
                    delete this.datatableConfig.filters[field]
                } else if (leaveField) {
                    this.datatableConfig.filters[field] = leaveField.data
                } else {
                    this.datatableConfig.filters = {}
                }
            }
            this.getDatatableData()
        },

        clearGlobalFilter() {
            this.datatableConfig.globalFilter = ''
            this.getDatatableData()
        },

        turnOffSelectMode(index = null) {
            const tableData = this.datatableData[this.tableId]
            this.selectMode = false
            for (const item in tableData) {
                if (index === null || index != item) {
                    const select = tableData[item]['-']
                    if (select) {
                        select.selectIsOpen = false
                        select.selectType = 0
                    }
                }
            }
        },

        resetLimit(limit) {
            this.datatableConfig.limit = limit
            this.datatableConfig.offset = 0
            this.datatableData[this.tableId] = []
        },

        setDatatableData(data) {
            this.datatableData[this.tableId] = data
        },

        addDatatableData(data) {
            this.datatableData[this.tableId] = this.datatableData[this.tableId] || []
            const existingIds = new Set(this.datatableData[this.tableId].map((item) => item._id.data))
            const uniqueNewData = data.filter((item) => !existingIds.has(item._id.data))
            this.datatableData[this.tableId] = [...this.datatableData[this.tableId], ...uniqueNewData]
        },

        mergeArrays(primaryArray, additionalArray) {
            const updatedArray = {}
            const keySet = new Set()

            additionalArray.forEach((item) => {
                keySet.add(item.key)
                updatedArray[item.key] = {...item}
                updatedArray[item.key].visible = item.visible !== undefined ? item.visible : true
            })

            primaryArray.forEach((item) => {
                if (keySet.has(item.key)) {
                    updatedArray[item.key] = {...updatedArray[item.key], ...item}
                } else {
                    keySet.add(item.key)
                    updatedArray[item.key] = {...item}
                }
            })

            return Object.values(updatedArray)
        },

        initDatatable(initialDataTableConfig) {
            this.tableId = initialDataTableConfig.tableId
            this.url = initialDataTableConfig.path
            this.initOrder = initialDataTableConfig.order

            this.setBaseColumns(initialDataTableConfig.cols)

            this.datatableConfig = {
                offset: 0,
                limit: 25,
                filters: {},
                globalFilter: '',
                order: {
                    [this.initOrder]: initialDataTableConfig.orderType || -1
                }
            }

            if (Object.keys(initialDataTableConfig.filterBy).length) {
                this.datatableConfig.filters = {
                    [initialDataTableConfig.filterBy.key]: initialDataTableConfig.filterBy.value
                }
            }

            let visibleColsRaw =
                    JSON.parse(localStorage.getItem(`DataTables_${this.tableId}`)) || initialDataTableConfig.cols,
                updatedCols
            initialDataTableConfig.cols.forEach((col) => {
                if (!visibleColsRaw.some((obj) => obj.key === col.key)) {
                    visibleColsRaw.push({
                        ...col,
                        visible: true
                    })
                }
            })

            visibleColsRaw = visibleColsRaw.filter(
                (obj) => obj.isCustom || initialDataTableConfig.cols.some((col) => col.key === obj.key)
            )
            updatedCols = this.mergeArrays(initialDataTableConfig.cols, visibleColsRaw)
            this.columns[this.tableId] = updatedCols

            this.getDatatableData()
        },

        getDatatableData() {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            let tableId = this.tableId,
                fields = this.columns[tableId]

            this.tableIsLoaded = false

            this.datatableData[this.tableId] = []
            this.resetLimit(this.datatableConfig.limit)

            return new Promise((resolve, reject) => {
                RestService.post(`${this.url}`, this.datatableConfig, {
                    table: tableId
                })
                    .then((ans) => {
                        if (!ans.data.data.length && this.datatableConfig.offset !== 0) {
                            this.datatableConfig.offset -= this.datatableConfig.limit
                        }

                        let newArray, notBaseColumns, processData

                        if (tableId === 'reports-page') {
                            processData = this.processAndMergeData(fields, ans.data.data.results)
                            this.reportsTotal = ans.data.data.total
                        } else {
                            processData = this.processAndMergeData(fields, ans.data.data)
                        }

                        newArray = processData.newArray
                        notBaseColumns = processData.notBaseColumns


                        notBaseColumns.forEach((col) => this.columns[ans.config.table].push(col))
                        this.setDatatableData(newArray)
                        this.total = Object.keys(this.datatableData[ans.config.table]).length

                        if (!localStorage.getItem(`DataTables_${ans.config.table}`)) {
                            localStorage.setItem(
                                `DataTables_${ans.config.table}`,
                                JSON.stringify(this.columns[ans.config.table])
                            )
                        }
                        resolve()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                    })
                    .finally(() => {
                        this.tableIsLoaded = true
                    })
            })
        },

        loadMore() {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sessionStore = SessionModel.useSessionStore()

            let tableId = this.tableId
            this.tableIsLoaded = false
            let fields = this.columns[tableId]

            return new Promise((resolve, reject) => {
                RestService.post(`${this.url}`, this.datatableConfig)
                    .then((ans) => {
                        if (!ans.data.data.length && this.datatableConfig.offset !== 0) {
                            this.datatableConfig.offset -= this.datatableConfig.limit
                        }

                        const {newArray} = this.processAndMergeData(fields, ans.data.data)
                        this.addDatatableData(newArray)
                        this.total = Object.keys(this.datatableData[tableId]).length
                        resolve(ans)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                    })
                    .finally(() => {
                        this.tableIsLoaded = true
                    })
            })
        },

        processAndMergeData(fields, ansData) {
            const notBaseColumns = []
            const processedData = ansData.map((item) => {
                const newItem = {}

                fields.forEach((field) => {
                    const nestedKeys = field.key.split('.')
                    let nestedItem = item
                    nestedKeys.forEach((key) => {
                        if (nestedItem && nestedItem[key] !== undefined) {
                            nestedItem = nestedItem[key]
                        } else {
                            nestedItem = null
                        }
                    })

                    newItem[field.key] = {
                        ...field,
                        data: nestedItem !== undefined ? nestedItem : null,
                        selectIsOpen: false,
                        selectType: 0
                    }
                })

                Object.keys(item).forEach((key) => {
                    if (!fields.some((field) => field.key === key)) {
                        newItem[key] = {
                            key: key,
                            label: key,
                            type: 'string',
                            data: item[key] !== undefined ? item[key] : null,
                            sortable: true,
                            visible: false
                        }

                        if (!notBaseColumns.some((column) => column.key === key) && !ignoredColsList.includes(key)) {
                            notBaseColumns.push({
                                key: key,
                                label: key,
                                type: 'string',
                                visible: false,
                                sortable: true,
                                isCustom: true
                            })
                        }
                    }
                })

                return newItem
            })

            return {newArray: processedData, notBaseColumns}
        },

        canselSelectMode() {
            for (const item in this.datatableData[this.tableId]) {
                if (!this.datatableData[this.tableId][item]['-']) {
                    return
                }

                this.datatableData[this.tableId][item]['-'].selectIsOpen = false
                this.datatableData[this.tableId][item]['-'].selectType = 0
            }
        }
    }
})
