<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import { useAppStore } from '@/app/providers'

import { PageTitle } from '@/shared/ui/Page'
import { BaseBack } from '@/shared/ui/Actions'
import { BaseInput } from '@/shared/ui/Inputs'
import { BaseImage } from '@/shared/ui/Images'
import { BaseButton } from '@/shared/ui/Buttons'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import { usePageTitle } from '@/shared/lib/use/usePageTitle'
import { countriesList } from "@/shared/data/countriesList"

const source = ref('')
const globalCountrySearch = ref('')
const chosenCountySearch = ref('')
const chosenCountries = ref([])

const router = useRouter()

const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const title = usePageTitle('Create Country Source')

const requestIsPending = computed(() => appStore.requestIsPending)

const filteredCountries = computed(() => {
    const globalCountrySearchTerm = globalCountrySearch.value.toLowerCase()
    return Object.values(countriesList(0)).filter((country) =>
        country.name.toLowerCase().includes(globalCountrySearchTerm)
    )
})

const filteredChosenCountries = computed(() => {
    const chosenCountySearchTerm = chosenCountySearch.value.toLowerCase().trim();

    if (!chosenCountries.value.length || !chosenCountySearchTerm) {
        return chosenCountries.value; // Return all countries if no search term or if no countries selected
    }

    // Filter the countries based on search term (name or code)
    return chosenCountries.value.filter((code) => {
        const country = countriesList(0)[code];
        return country.name.toLowerCase().includes(chosenCountySearchTerm) ||
            country.code.toLowerCase().includes(chosenCountySearchTerm);
    });
});

const add = (country) => {
    if (chosenCountries.value.includes(country)) {
        chosenCountries.value.splice(chosenCountries.value.indexOf(country), 1)
    } else {
        chosenCountries.value.push(country)
    }
}

const addAll = () => {
    const countryCodesToAdd = Object.values(filteredCountries.value)
        .map((country) => country.code)
        .filter((code) => !chosenCountries.value.includes(code))

    chosenCountries.value.push(...countryCodesToAdd)
}

const remove = (country) => {
    chosenCountries.value.splice(chosenCountries.value.indexOf(country), 1)
}

const removeAll = () => {
    const filteredCountryCodes = Object.values(filteredCountries.value).map((country) => country.code)
    chosenCountries.value = chosenCountries.value.filter(code => !filteredCountryCodes.includes(code))
}

const create = () => {
    if (!source.value) {
        generalNotificationsStore.showError({
            message: 'Please enter a source name'
        })
        return
    }

    if (!chosenCountries.value.length) {
        generalNotificationsStore.showError({
            message: 'Please choose a countries'
        })
        return
    }

    appStore
        .create(
            {
                name: source.value,
                type: 2,
                countries: chosenCountries.value
            },
            'sources'
        )
        .then(() => {
            router.push({ name: 'sourcesPage' })
        })
}
</script>

<template>
    <div class="create-country-source container">
        <PageTitle :title="'SOURCES'" :icon="'page-icons/sources.svg'" />
        <BaseBack :path-name="'sourcesPage'" />
        <div class="sources-box">
            <div class="all-countries">
                <BaseInput
                    v-model="source"
                    :placeholder="'Enter country source name...'"
                    :padding="{ top: '15px', left: '20px', bottom: '15px' }"
                />
                <BaseInput
                    v-model="globalCountrySearch"
                    :placeholder="'Search Country...'"
                    :padding="{ top: '15px', left: '20px', bottom: '15px' }"
                />
                <div class="options">
                    <div class="add" @click="addAll()">Add all countries</div>
                    <div class="remove" @click="removeAll()">Remove all countries</div>
                </div>
                <div class="countries-list">
                    <div
                        v-for="(country, i) in filteredCountries"
                        :key="i"
                        class="country"
                        @click.stop="add(country.code)"
                    >
                        <p class="name">{{ country.name }}</p>
                        <div class="settings">
                            <span>{{ country.code }}, </span>
                            <span>{{ country.languages[0] }} | </span>
                            <span>{{ country.alpha3 }}</span>
                        </div>
                        <BaseImage :width="14" :height="14" :path="'plus.svg'" @click.stop="add(country.code)" />
                    </div>
                </div>
            </div>
            <div class="chosen-countries">
                <div class="top">
                    <h2 class="typo-heading-h2">Countries added: {{ chosenCountries.length }}</h2>
                    <BaseButton :text="'Save'" :load="requestIsPending" @click="create()" />
                </div>
                <BaseInput
                    v-model="chosenCountySearch"
                    :placeholder="'Search Country...'"
                    :width="'360px'"
                    :padding="{ top: '15px', left: '20px', bottom: '15px' }"
                />
                <div class="chosen-list">
                    <template v-for="(country, i) in filteredChosenCountries" :key="i">
                        <div class="chosen-country">
                            <p class="name">{{ countriesList()[country] }}</p>
                            <BaseImage :height="8" :width="8" :path="'clear.svg'" @click="remove(country)" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.sources-box {
    border: var(--general-border) solid var(--purple);
    padding: 20px;
    border-radius: var(--general-border-radius);
    display: grid;
    grid-template-columns: 500px 1fr;
    gap: 30px;
}

.all-countries {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 40px;
    border-right: var(--general-border) solid var(--primary-white);
}

.options {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    position: relative;
}

.options::after {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(50% - 10px);
    height: 40px;
    width: 1px;
    transform: translate(-50%, -50%);
    background-color: #787878;
}

.options > div {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 300;
}

.country {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    padding: 8px;
    cursor: pointer;
    border: var(--general-border) solid transparent;
    border-radius: var(--general-border-radius);
    transition: all 0.3s ease-in-out;
}

.country:hover {
    border: var(--general-border) solid var(--purple);
}

.settings {
    color: var(--primary-gray);
    margin-right: 40px;
}

h2 {
    padding-top: 5px;
    margin-bottom: 25px;
}

.chosen-list,
.countries-list {
    height: 800px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.chosen-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-top: 20px;
    border-top: var(--general-border) solid var(--white-08);
    gap: 10px;
    height: auto;
}

.top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.chosen-country {
    display: flex;
    align-items: baseline;
    padding: 8px;
    border-radius: var(--general-border-radius);
    border: var(--general-border) solid var(--purple);
    gap: 10px;
    height: fit-content;
}

.chosen-list img {
    cursor: pointer;
}
</style>
