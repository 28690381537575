import { defineStore } from 'pinia'
import { permissionsList } from '@/shared/data'

export const useSessionStore = defineStore('session', {
    state: () => ({
        brands: {},
        sources: {},
        campaigns: {},
        partners: {},
        connectors: {},
        permissions: {},
        users: {},
        statuses: {},
        pageIsLoading: false,
        clearFilters: false,
        resetSelectingFilterList: false,
        resetSelect: '',
        offset: localStorage.getItem('timeZone') || new Date().getTimezoneOffset(),
        timeDepositsFound: [],
        timeDepositsFoundLS: JSON.parse(localStorage.getItem('timeDepositsFound')) || []
    }),
    actions: {
        setPageState(state) {
            this.pageIsLoading = state
        },

        setOffset(time) {
            this.offset = time
        },

        setSelectToReset(field) {
            this.resetSelect = field
            setTimeout(() => {
                this.resetSelect = ''
            }, 1000)
        },

        setResetSelectingFilterList() {
            this.resetSelectingFilterList = !this.resetSelectingFilterList
        },

        clearFilter() {
            this.clearFilters = !this.clearFilters
        },

        setBrands(data) {
            this.brands = Object.fromEntries(data.map((item) => [item.id, item.name])) || []
        },

        setSources(data) {
            this.sources = Object.fromEntries(data.map((item) => [item.id, item.name])) || []
            this.setCampaign(data)
        },

        setCampaign(data) {
            this.campaigns = Object.fromEntries(data.map((item) => [item.id, item.numericId])) || []
        },

        setPartners(data) {
            this.partners =
                Object.fromEntries(data.map((item) => [item.id, item.firstname + ' ' + item.lastname])) || []
        },

        setUsers(data) {
            this.users = Object.fromEntries(data.map((item) => [item.id, item.firstname + ' ' + item.lastname])) || []
        },

        setPermissions(data) {
            data = data.sort()
            for (const item in data) {
                this.permissions[data[item]] = permissionsList[data[item]] || data[item]
            }
        },

        setConnectors(data) {
            this.connectors = data
        },

        setStatuses(data) {
            this.statuses = data.filter(el => el)
        },

        setTimeDepositFound(data) {
            this.timeDepositsFound = data
        },

        setTimeDepositFoundLS(data) {
            this.timeDepositsFoundLS = data
            localStorage.setItem('timeDepositsFound', JSON.stringify(this.timeDepositsFoundLS))
        }
    }
})
