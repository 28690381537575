import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const getBrand = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.get(`brand/${id}`)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
                reject(errorObject)
            })
    })
}

export const api = {
    getBrand
}
