import {defineStore} from "pinia"
import RestService from "@/shared/api/rest.service"
import {GeneralNotificationsModel} from "@/entities/GeneralNotifications"

export const useIntegrationStore = defineStore('integration', {
    state: () => ({}),
    actions: {
        createTestLead: (data) => {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            return new Promise((resolve, reject) => {
                RestService.post('test/leads', data)
                    .then(ans => {
                        resolve(ans)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        },
        getLead: (data) => {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            return new Promise((resolve, reject) => {
                RestService.get('test/leads', {
                    params: data
                })
                    .then(ans => {
                        resolve(ans)
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        }
    }
})
