import { computed } from 'vue'

export default {
    config: () => {
        return {
            tableId: 'brands-table',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'CI',
                    key: 'numericId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Name',
                    key: 'name',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Brand',
                    key: 'brand',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Brand ID',
                    key: 'id',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Status',
                    key: 'status',
                    type: 'boolean',
                    sortable: true
                },
                {
                    label: 'Private',
                    key: 'privacy',
                    type: 'boolean',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Connector',
                    key: 'apiConnector',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'API Token',
                    key: 'apiToken',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'API Url',
                    key: 'apiUrl',
                    type: 'string',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'status',
                    placeholder: 'Select Status',
                    type: 'select',
                    multiselect: false,
                    search: false,
                    data: (() => {
                        return {
                            0: 'Inactive',
                            1: 'Active'
                        }
                    })()
                },
                {
                    field: 'privacy',
                    placeholder: 'Select Privacy',
                    type: 'select',
                    multiselect: false,
                    search: false,
                    data: (() => {
                        return {
                            0: 'Public',
                            1: 'Private'
                        }
                    })()
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'edit',
                    value: 'Edit'
                },
                {
                    key: 'duplicate_brand',
                    value: 'Duplicate'
                },
                {
                    key: 'delete_brand',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [
                        { key: 'status', label: 'Change Status', length: 2, buttonText: 'Change' },
                        { key: 'privacy', label: 'Change Privacy', length: 2, buttonText: 'Change' },
                        { key: 'delete_mass_brands', label: 'Delete', buttonText: 'Confirm' }
                    ]
                },
                {
                    key: 'status',
                    placeholder: 'Enable',
                    options: [
                        { key: 0, label: 'Inactive' },
                        { key: 1, label: 'Active' }
                    ]
                },
                {
                    key: 'privacy',
                    placeholder: 'Enable',
                    options: [
                        { key: 1, label: 'Public' },
                        { key: 1, label: 'Private' }
                    ]
                }
            ]),
            searchList: computed(() => [
                {
                    value: 'Name',
                    key: 'globalFilter'
                }
            ])
        }
    }
}
