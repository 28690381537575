<script setup>

import {BaseButton} from "@/shared/ui/Buttons"
import {BaseBorderPanel} from "@/shared/ui/Panels"
import {BaseInput, BaseSelectBox} from "@/shared/ui/Inputs"
import {computed, ref, watch} from "vue"
import {checkOnRequiredFields, isValidIP} from "@/shared/lib/utils/validation"
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {IntegrationModel} from "@/entities/Integration"
import {SessionModel} from '@/entities/Session'
import {getBrands, getPartners, getSources} from "@/entities/Session/model"
import {BaseGrid} from "@/shared/ui/GridSystem"
import {BaseTestData} from "@/entities/Partners"
import BasePhoneInput from "@/shared/ui/Inputs/BasePhoneInput.vue"
import {countriesList, findCountryByCode, findCountryByPrefix} from "@/shared/data/countriesList"

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const integrationStore = IntegrationModel.useIntegrationStore()
const sessionStore = SessionModel.useSessionStore()

const brands = computed(() => getBrands(sessionStore))
const affiliates = computed(() => getPartners(sessionStore))
const sources = computed(() => getSources(sessionStore))

const testData = ref({
    loaded: false,
    request: null,
    response: null,
})
const firstName = ref('')
const lastName = ref('')
const email = ref('')
const password = ref('')
const userIp = ref('')
const phone = ref({
    prefix: '',
    phone: '',
    phoneMask: ''
})
const brand = ref('')
const affiliate = ref('')
const country = ref('')
const source = ref('')
const slicedNumber = ref('')

const requireFields = ref({
    firstName: {data: firstName, error: false},
    lastName: {data: lastName, error: false},
    email: {data: email, error: false},
    affiliate: {data: affiliate, error: false},
    password: {data: password, error: false},
    userIp: {data: userIp, error: false},
    brand: {data: brand, error: false},
    country: {data: country, error: false},
    source: {data: source, error: false},
    phone: {data: phone, error: false},
})

const firstNames = ['John', 'Jane', 'Michael', 'Emily', 'Chris', 'Sarah', 'David', 'Laura', 'Robert', 'Anna']
const lastNames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez']
const prefixes = ['+1', '+44', '+33', '+49', '+61', '+81']

const getRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let result = ''
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
}

const getRandomEmail = () => {
    const domains = ['example.com', 'mail.com', 'test.com']
    return `${getRandomString(5)}@${domains[Math.floor(Math.random() * domains.length)]}`
}

const getRandomIp = () => {
    return `${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`
}

const getRandomPhone = (prefix) => {
    switch (prefix) {
        case '+1':
            return `${Math.floor(Math.random() * 9000000000) + 1000000000}`
        case '+44':
            return `${Math.floor(Math.random() * 9000000000) + 1000000000}`
        case '+33':
            return `0${Math.floor(Math.random() * 900000000) + 100000000}`
        case '+49':
            return `${Math.floor(Math.random() * 90000000000) + 10000000000}`
        case '+61':
            return `${Math.floor(Math.random() * 900000000) + 100000000}`
        case '+81':
            return `${Math.floor(Math.random() * 900000000) + 100000000}`
    }
}

const autoFill = () => {
    firstName.value = firstNames[Math.floor(Math.random() * firstNames.length)]
    lastName.value = lastNames[Math.floor(Math.random() * lastNames.length)]
    email.value = getRandomEmail()
    password.value = getRandomString(10)
    userIp.value = getRandomIp()
    phone.value.prefix = prefixes[Math.floor(Math.random() * prefixes.length)]
    country.value = findCountryByPrefix(phone.value.prefix).code
    phone.value.phoneMask = findPhoneMask()
    phone.value.phone = getRandomPhone(phone.value.prefix)
}

const testIntegration = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (!isValidIP(userIp.value)) {
        generalNotificationsStore.showError({
            message: 'Not valid user IP'
        })
        return
    }

    if (testData.value.loaded) {
        return
    }

    testData.value.response = null
    testData.value.request = null
    testData.value.loaded = true
    let phoneNumber = (phone.value.prefix + phone.value.phone).startsWith('+')
        ? phone.value.prefix + phone.value.phone
        : '+' + phone.value.prefix + phone.value.phone
    integrationStore.createTestLead({
        firstName: firstName.value,
        lastName: lastName.value,
        fullname: firstName.value + " " + lastName.value,
        email: email.value,
        password: password.value,
        userIp: userIp.value,
        phone: phoneNumber.replace('-', ''),
        brandId: brand.value,
        sourceId: source.value,
        country: country.value,
        affiliateId: affiliate.value,
    })
        .then(ans => {
            testData.value.request = JSON.stringify(ans.data.data.request, null, 4)
            testData.value.response = JSON.stringify(ans.data.data.response, null, 4)
        })
        .finally(() => {
            testData.value.loaded = false
        })
}

const adjustPhoneNumberLength = (phoneNumber, length) => {
    if (phoneNumber.length > length) {
        slicedNumber.value = phoneNumber.slice(length)
        return phoneNumber.slice(0, length)
    } else if (phoneNumber.length < length) {
        if (slicedNumber.value) {
            const toAppend = slicedNumber.value.slice(0, length - phoneNumber.length)
            phoneNumber += toAppend
            slicedNumber.value = slicedNumber.value.slice(toAppend.length)
        }
    }

    return phoneNumber
}

const findPhoneMask = () => {
    const matchedCountry = findCountryByCode(country.value)
    if (matchedCountry) {
        phone.value.phone = adjustPhoneNumberLength(phone.value.phone, matchedCountry.phoneLimit)
        return matchedCountry.mask
    }

    return ''
}

const updatePrefix = (data) => {
    const matchedCountry = findCountryByCode(data.value)
    if (matchedCountry) {
        phone.value.prefix = matchedCountry.phoneCode[0]
        phone.value.phoneMask = findPhoneMask()
    } else {
        phone.value.prefix = ''
    }
}

const updatePhonePrefix = (value) => {
    const matchedCountry = findCountryByPrefix(value)
    if (matchedCountry) {
        country.value = matchedCountry.code
        phone.value.phoneMask = findPhoneMask()
    } else {
        country.value = ''
    }
}
</script>

<template>
    <div class="single-tab">
        <div class="box">
            <div class="content">
                <h2 class="typo-heading-h2">User Data</h2>
                <p class="typo-heading-small-semi-bold-gray">Require to fill all fields</p>
            </div>
            <BaseButton :text="'Auto Fill'" :width="86" @click="autoFill()"/>
        </div>
        <BaseBorderPanel>
            <template #content>
                <BaseGrid :col="3" :width="'240px'">
                    <template #content>
                        <BaseInput
                            v-model="firstName" :error="requireFields.firstName.error"
                            :placeholder="'Enter First Name'"
                            :label="'First Name*'"/>
                        <BaseInput
                            v-model="lastName" :error="requireFields.lastName.error"
                            :placeholder="'Enter Last Name'"
                            :label="'Last Name*'"/>
                        <BaseInput
                            v-model="email"
                            :error="requireFields.email.error"
                            :placeholder="'Enter Email'"
                            :label="'Email*'"/>
                        <BaseInput
                            v-model="password" :error="requireFields.password.error"
                            :placeholder="'Enter Password'"
                            :type="'password'"
                            :label="'Password*'"/>
                        <BaseInput
                            v-model="userIp"
                            :error="requireFields.userIp.error"
                            :type="'number'"
                            :placeholder="'Enter IP'"
                            :label="'User IP*'"/>
                        <BasePhoneInput
                            v-model="phone"
                            :error="requireFields.phone.error"
                            :label="'Prefix/Phone*'"
                            @update="updatePhonePrefix"
                        />
                    </template>
                </BaseGrid>
            </template>
        </BaseBorderPanel>
        <BaseBorderPanel>
            <template #title>
                <h2 class="typo-heading-h2">General</h2>
            </template>
            <template #content>
                <BaseGrid :col="3" :width="'240px'">
                    <template #content>
                        <BaseSelectBox
                            v-model="brand" :options="
                            Object.entries(brands).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                    "
                            :error="requireFields.brand.error"
                            :placeholder="'Select Brand'"
                            :label="'Brand*'"
                        />
                        <BaseSelectBox
                            v-model="affiliate" :options="
                            Object.entries(affiliates).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                    "
                            :error="requireFields.affiliate.error"
                            :placeholder="'Select Affiliate'"
                            :label="'Affiliate*'"
                        />
                        <BaseSelectBox
                            v-model="country" :options="
                            Object.entries(countriesList()).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                    "
                            :error="requireFields.country.error"
                            :placeholder="'Select Country'"
                            :label="'Country*'"
                            @update="updatePrefix"
                        />
                        <BaseSelectBox
                            v-model="source" :options="
                            Object.entries(sources).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                    "
                            :error="requireFields.source.error"
                            :placeholder="'Select Source'"
                            :label="'Source*'"
                        />
                    </template>
                </BaseGrid>
            </template>
        </BaseBorderPanel>
        <BaseButton :text="'Test Lead'" :load="testData.loaded" :width="240" @click="testIntegration"/>
        <BaseTestData :title="'Lead Test'" :data="testData"/>
    </div>
</template>

<style scoped>
.box {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-bottom: 20px;
}

.box p {
    margin-top: 10px;
}
</style>
