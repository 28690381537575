<script setup>
import { computed, ref } from 'vue'

import { BaseButton } from '@/shared/ui/Buttons'
import { BaseModal } from '@/shared/ui/Modal'
import { BaseSelectBox } from '@/shared/ui/Inputs'
import { SessionModel } from '@/entities/Session'
import { UserModel } from '@/entities/User'
import { UsersModel } from '@/entities/Users'
import { ModalModel } from '@/entities/Modal'

import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
const sessionStore = SessionModel.useSessionStore()
const modalStore = ModalModel.useModalStore()
const usersStore = UsersModel.useUsersStore()
const userStore = UserModel.useUserStore()

const currentUser = computed(() => userStore.user)
const users = computed(() => SessionModel.getUsers(sessionStore))
const user = ref(currentUser.value.id)
const isPending = ref(false)

const change = () => {
    if (user.value === currentUser.value.id) {
        generalNotificationsStore.showWarning({
            type: 2,
            message: 'This is your account'
        })
        return
    }

    isPending.value = true
    usersStore
        .getTokenByUser(user.value)
        .then(() => {
            sessionStore.setPageState(false)
            modalStore.closeModal()
        })
        .finally(() => {
            isPending.value = false
        })
}

const cancel = () => {
    modalStore.closeModal()
}
</script>

<template>
    <div>
        <BaseModal :title="'Change Profile'" :action-buttons="'col'">
            <template #content>
                <div class="change-profile">
                    <p class="typo-heading-small-semi-bold-gray center">
                        Change your identity to Traffic Agent or provider
                    </p>
                    <BaseSelectBox
                        v-model="user"
                        :placeholder="users[user]"
                        :options="
                            Object.entries(users).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :width="250"
                    />
                </div>
            </template>
            <template #action-buttons>
                <BaseButton :text="'Change'" :load="isPending" @click="change()" />
                <BaseButton class="underline" :text="'Cancel'" :design="'transparent'" @click="cancel()" />
            </template>
        </BaseModal>
    </div>
</template>

<style scoped>
.select {
    margin: 50px auto 30px;
    display: block;
}
</style>
