import { markRaw } from 'vue'

import { TabBrands, TabEntities, TabPaymentPlan, TabRestrictions, TabSettings } from '../tabs'

export default {
    config: () => {
        return {
            tabs: markRaw([
                {
                    key: 'settings',
                    value: 'Settings',
                    component: TabSettings
                },
                {
                    key: 'entities',
                    value: 'Entitles',
                    hide: true,
                    component: TabEntities
                },
                {
                    key: 'brands',
                    value: 'Brands',
                    component: TabBrands
                },
                {
                    key: 'paymentPlan',
                    value: 'Payment Plan',
                    hide: true,
                    component: TabPaymentPlan
                },
                {
                    key: 'restrictions',
                    value: 'Restrictions',
                    hide: true,
                    component: TabRestrictions
                }
            ])
        }
    }
}
