/**
 * Creates a debounced function that delays invoking `func` until after `timeout` milliseconds have elapsed
 * since the last time the debounced function was invoked. The debounced function comes with a default timeout of 300 milliseconds.
 *
 * @param {Function} func The function to debounce.
 * @param {number} [timeout=300] The number of milliseconds to delay defaults to 300ms if not specified.
 * @returns {Function} Returns the new debounced function.
 */
export const debounce = (func, timeout = 300) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}
