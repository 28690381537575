<script setup>
import {usePageTitle} from "@/shared/lib/use/usePageTitle"

import {BasePage, PageTitle} from "@/shared/ui/Page"
import {DataTable, PaginationBox, TableActions, FiltersList} from "@/features/Datatable"

import {DatatableModel} from '@/entities/Datatable'

import {ModalModel} from '@/entities/Modal'
import {ReportsModel} from '@/entities/Reports'

import config from '../configs/reports.config'
import {countriesList} from "@/shared/data/countriesList"

const {tableId, path, cols, searchByList, filters, levels, levelsFields} = config.config()

const datatableStore = DatatableModel.useDatatableStore()

const title = usePageTitle('Reports')

const modalStore = ModalModel.useModalStore()
const store = ReportsModel.useReportsStore()

const openModal = (data) => {
    // todo remove in future
    switch (data.field) {
        case 'leads':
            if (data.time) {
                data.data = {
                    ...data.data,
                    timeCallStatus: data.time
                }
            }

            data.data = {
                ...data.data,
                ...(data.row._id.data || data.row._id)
            }

            datatableStore.isEditLoaded = true
            store.getReportInfo('brandId', data.data)
                .then(ans => {
                    modalStore.setModal('reportInfoModal')
                    modalStore.setModalData(ans)
                })
                .finally(() => {
                    datatableStore.isEditLoaded = false
                })
            break

        case 'ftds':
            if (data.time) {
                data.data = {
                    ...data.data,
                    timeDeposit: data.time
                }
            }

            data.data = {
                ...data.data,
                ...(data.row._id.data || data.row._id)
            }

            datatableStore.isEditLoaded = true
            store.getReportFtds('brandId', data.data)
                .then(ans => {
                    modalStore.setModal('reportFtdsModal')
                    modalStore.setModalData(ans)
                })
                .finally(() => {
                    datatableStore.isEditLoaded = false
                })
            break

        default:
            return
    }
}
</script>

<template>
    <BasePage class="reports">
        <PageTitle :title="'Reports'" :icon="'page-icons/reports.svg'"/>
        <DataTable
            :table-id="tableId"
            :path="path"
            :fields="cols"
            @open="openModal"
        >
            <template #top-left>
                <FiltersList
                    :id="'reports'"
                    :filters="filters"
                    :levels="levels"
                    :levels-fields="levelsFields"
                    :search-list="searchByList"
                    :button-text="'Filter'"
                />
            </template>
            <template #center-right>
                <TableActions :search-list="searchByList"/>
            </template>
            <template #bottom-left>
                <PaginationBox/>
            </template>
            <template #name="{elData}">
                <span class="name">
                    {{ countriesList()[elData] ? countriesList()[elData] : elData || 'Deleted' }}
                </span>
            </template>
            <template #level-name="{elData}">
                <span class="name">
                     {{ countriesList()[elData] ? countriesList()[elData] : elData || 'Deleted' }}
                </span>
            </template>
            <template #sublevel-name="{elData}">
                <span class="name">
                     {{ countriesList()[elData] ? countriesList()[elData] : elData || 'Deleted' }}
                </span>
            </template>
        </DataTable>
    </BasePage>
</template>

<style scoped>
.name {
    display: flex;
    align-items: center;
    gap: 8px;
}

img.open {
    transform: rotate(180deg);
}
</style>
