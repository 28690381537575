<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { AuthorizationModel } from '@/features/Authorization'
import { HeaderModel } from '@/entities/Header'

import { BaseInput } from '@/shared/ui/Inputs'
import { BaseButton } from '@/shared/ui/Buttons'
import { BaseWarningBox } from '@/shared/ui/Warnings'

onMounted(() => {
    if (localStorage.getItem('token')) {
        router.push({ name: viewStore.homeUrlName })
    }
})

const router = useRouter()
const authorizationStore = AuthorizationModel.useAuthorizationStore()
const viewStore = HeaderModel.useHeaderStore()

const userData = ref({
    login: '',
    password: '',
    otp: ''
})

const errorMessage = ref('')
const loading = ref(false)

const login = () => {
    if (loading.value) {
        return
    }

    if (!userData.value.login || !userData.value.password) {
        errorMessage.value = 'All fields are required'
        return
    }

    loading.value = true
    errorMessage.value = ''

    authorizationStore
        .login(userData.value)
        .then(() => {
            router.push({ name: viewStore.homeUrlName })
        })
        .catch((ans) => {
            if (ans.code === 200) {
                errorMessage.value = 'Invalid login or password. Please try again.'
            }
        })
        .finally(() => {
            loading.value = false
        })
}
</script>

<template>
    <div class="login-form" @keypress.enter="login">
        <div class="input-fields">
            <BaseInput v-model="userData.login" :padding="{top: '16px', bottom: '16px'}" :placeholder="'Enter your username/e-mail'">
                <template #label>
                    <label>Login</label>
                </template>
            </BaseInput>
            <BaseInput v-model="userData.password" :padding="{top: '16px', bottom: '16px'}" :placeholder="'Enter your password'" :type="'password'">
                <template #label>
                    <label>Password</label>
                </template>
            </BaseInput>
            <BaseInput v-model="userData.otp" :padding="{top: '16px', bottom: '16px'}" :placeholder="'Enter your OTP'" :type="'number'">
                <template #label>
                    <label>OTP (if enabled)</label>
                </template>
            </BaseInput>
        </div>
        <BaseWarningBox :type="'error'" :text="errorMessage" />
        <div class="forgot">
            <p>Forgot password?</p>
            <BaseButton :text="'Login'" :load="loading" :height="44" :width="100" @click="login" />
        </div>
    </div>
</template>

<style scoped>
.input-box {
    margin-bottom: 24px;
    width: 100%;
}

.input-box:last-child {
    margin-bottom: 0;
}

.input-fields {
    margin-bottom: 70px;
}

button {
    display: block;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 27px;
}

.warning-box {
    text-align: center;
    max-width: 210px;
    min-height: 30px;
    margin: -40px auto 8px;
    font-weight: 400;
}

.forgot {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

p {
    text-decoration: underline;
    font-size: var(--general-font-size);
    color: #AEB1BC;
    white-space: nowrap;
    font-weight: 500;
}

@media (max-width: 769px) {
    .input-fields {
        margin-bottom: 46px;
    }

    label {
        font-size: var(--general-font-size);
        margin-bottom: 4px;
    }
}
</style>
