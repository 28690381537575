import { computed } from 'vue'

export default {
    config: () => {
        return {
            tableId: 'partners-table',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'First Name',
                    key: 'firstname',
                    sortable: true
                },
                {
                    label: 'Last Name',
                    key: 'lastname',
                    sortable: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    type: 'func',
                    sortable: true,
                    func: (data, elData) => {
                        return `<a class="email" href="mailto:${elData}">${elData}</a>`
                    }
                },
                {
                    label: 'Connector',
                    key: 'apiConnector',
                    sortable: true
                },
                {
                    label: 'Skype',
                    key: 'skype',
                    sortable: true
                },
                {
                    label: 'Telegram',
                    key: 'telegram',
                    sortable: true
                },
                {
                    label: 'Vertical',
                    key: 'vertical',
                    sortable: true
                },
                {
                    label: 'Source',
                    key: 'source',
                    sortable: true
                },
                {
                    label: 'Created At',
                    key: 'created',
                    type: 'time',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    sortable: true
                },
                {
                    label: 'API Details',
                    key: 'apiDetails',
                    sortable: false
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                    type: 'boolean'
                }
            ]),
            filters: computed(() => [
                {
                    field: 'created',
                    placeholder: 'Data Range',
                    type: 'time',
                    multiselect: false,
                    search: false
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'edit',
                    value: 'Edit'
                },
                {
                    key: 'duplicate_partner',
                    value: 'Duplicate'
                },
                {
                    key: 'delete_partner',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [
                        { key: 'status', label: 'Change Status', length: 2 },
                        { key: 'block_duplicate_email', label: 'Block duplicate e-mail', length: 2 },
                        { key: 'block_duplicate_phone', label: 'Block duplicate phone', length: 2 },
                        { key: 'block_duplicate_ip', label: 'Block duplicate IP', length: 2 },
                        { key: 'delete_mass_partners', label: 'Delete' }
                    ]
                },
                {
                    key: 'status',
                    placeholder: 'Select Status',
                    options: [
                        { key: '0', label: 'Inactive' },
                        { key: '1', label: 'Active' }
                    ]
                },
                {
                    key: 'block_duplicate_email',
                    placeholder: 'Choose function',
                    options: [
                        { key: 0, label: 'Unblock' },
                        { key: 1, label: 'Block' }
                    ]
                },
                {
                    key: 'block_duplicate_phone',
                    placeholder: 'Choose function',
                    options: [
                        { key: '0', label: 'Unblock' },
                        { key: '1', label: 'Block' }
                    ]
                },
                {
                    key: 'block_duplicate_ip',
                    placeholder: 'Choose function',
                    options: [
                        { key: '0', label: 'Unblock' },
                        { key: '1', label: 'Block' }
                    ]
                }
            ]),
            searchByList: computed(() => [
                {
                    key: 'firstname',
                    value: 'Name'
                }
            ]),
            actions: computed(() => ['export', 'reset', 'saveTemplate', 'customize', 'upload'])
        }
    }
}
