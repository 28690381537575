import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const createOtp = () => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.post('otp')
            .then((ans) => {
                resolve(ans)
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const updateOtp = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.put(`otp`, {
            code: data.code,
            enable: data.enable
        })
            .then(() => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
                reject()
            })
    })
}

const disableOtp = (otp) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.post(`otp/remove`, {
            code: otp
        })
            .then((ans) => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const disableUserOtp = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.delete(`otp/${data.userId}`, {})
            .then(() => {
                resolve()
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

export const api = {
    createOtp,
    updateOtp,
    disableOtp,
    disableUserOtp
}
