export const errors = {
    101: "Field 'phone' is required",
    102: "Field 'email' is required",
    103: "Field 'country' is required",
    104: "Field 'fullname' (or both fields 'firstname' and 'lastname') is required",
    105: 'Invalid phone',
    106: 'Invalid email',
    107: 'Invalid country',
    108: 'Brand error',
    109: 'Partner is disabled',
    110: 'Lead with such email already exists',
    111: 'Lead with such phone already exists',
    112: 'Lead with such IP already exists',
    113: 'Source is not configured for this partner',
    114: 'Source configured for partner is not found',
    115: 'Country does not match to source configured countries list',
    116: 'No available rows',
    117: 'No available brands',
    200: 'Invalid login',
    201: 'Invalid user',
    202: 'Username is taken',
    203: 'Email is taken',
    204: 'Lead not found',
    205: "Field 'name' is required",
    206: "Field 'brand' is required",
    207: 'Brand not found',
    208: 'Source not found',
    209: 'Row not found',
    210: 'Invalid row capType',
    211: 'Invalid source type',
    212: "Field 'username' is required",
    213: "Field 'email' is required",
    214: 'Partner not found',
    215: 'Affiliate not found',
    216: 'Template with such name already exists',
    217: 'Template not found',
    218: 'User not found',
    219: 'Invalid permission',
    400: 'General validation error',
    401: 'Session expired',
    403: 'Forbidden',
    404: 'Method not found',
    500: 'Server error',
    501: 'Invalid data'
}
