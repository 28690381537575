import { defineStore } from 'pinia'
import { api } from '../api'

import { DatatableModel } from '@/entities/Datatable'

export const useTemplatesStore = defineStore('templates', {
    state: () => ({
        templates: {}
    }),
    actions: {
        saveTemplate(data) {
            const datatableStore = DatatableModel.useDatatableStore()

            if (!this.templates[datatableStore.tableId]) {
                this.templates[datatableStore.tableId] = []
            }

            this.templates[datatableStore.tableId].push({
                name: data.name,
                columns: datatableStore.columns[datatableStore.tableId].filter((col) => col.visible).map((el) => el.key)
            })

            return new Promise((resolve) => {
                api.saveTemplate({
                    name: data.name,
                    columns: datatableStore.columns[datatableStore.tableId]
                        .filter((col) => col.visible)
                        .map((el) => el.key),
                    table: datatableStore.tableId
                }).then(() => {
                    resolve()
                })
            })
        },

        updateTemplate(data) {
            const datatableStore = DatatableModel.useDatatableStore()

            const visibleColumns = datatableStore.columns[datatableStore.tableId]
                .filter((col) => col.visible)
                .map((el) => el.key)

            return new Promise((resolve, reject) => {
                api.updateTemplate({
                    columns: visibleColumns,
                    id: data.id
                }).then(() => resolve())
            })
        },

        loadTemplate(data) {
            const datatableStore = DatatableModel.useDatatableStore()
            const templateColumns = data.columns

            datatableStore.columns[datatableStore.tableId].sort(
                (a, b) => templateColumns.indexOf(a.key) - templateColumns.indexOf(b.key)
            )
            datatableStore.columns[datatableStore.tableId].forEach((column) => {
                column.visible = !!templateColumns.includes(column.key)
            })

            datatableStore.getDatatableData()
        },

        removeTemplate(id) {
            const datatableStore = DatatableModel.useDatatableStore()

            return new Promise((resolve, reject) => {
                api.removeTemplate(id).then(() => {
                    this.templates[datatableStore.tableId] = this.templates[datatableStore.tableId].filter(
                        (template) => template.id !== id
                    )
                })
            })
        },

        getTemplates() {
            const datatableStore = DatatableModel.useDatatableStore()

            return new Promise((resolve, reject) => {
                api.getTemplates().then((ans) => {
                    this.templates[datatableStore.tableId] = ans.data.data.filter(
                        (el) => el.table === datatableStore.tableId
                    )
                })
            })
        }
    }
})
