<script setup>
import leadsConfig from '@/pages/leads/configs/leads.config'
import {typesList} from '@/shared/data'

import {LeadsModel} from '@/entities/Leads'
import {useAppStore} from '@/app/providers'
import {ModalModel} from '@/entities/Modal'

import {DataTable} from '@/features/Datatable'
import {PaginationBox, FiltersList, SelectingFilterList, TableActions} from '@/features/Datatable'
import {PageTitle} from '@/shared/ui/Page'
import {BaseSelectBox} from '@/shared/ui/Inputs'
import {usePageTitle} from '@/shared/lib/use/usePageTitle'
import {BaseImage} from "@/shared/ui/Images"

const {cols, typesFilter, searchByList, actionList, tableActions, filters, tableId, pathDt} = leadsConfig.config()

const store = LeadsModel.usestore()
const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const title = usePageTitle('Leads')

const openLead = (data) => {
    store.setLead(data)
    modalStore.setModal('editLeads')
}

const update = (action, data, row) => {
    appStore.update(
        {
            [action]: data.value
        },
        row,
        tableId
    )
}
</script>

<template>
    <div class="leads-page container">
        <div class="content">
            <PageTitle :title="'Leads'" :icon="'page-icons/leads.svg'"/>
            <DataTable
                :fields="cols"
                :is-draggable="true"
                :path="pathDt"
                :table-id="tableId"
                :option-list="tableActions"
                :action-list="actionList"
                @open="openLead"
            >
                <template #top-left>
                    <FiltersList :filters="filters" :search-list="searchByList" :button-text="'Search'"/>
                </template>
                <template #center-right>
                    <TableActions :search-list="searchByList"/>
                </template>
                <template #center-left>
                    <SelectingFilterList :filters="typesFilter" :field="'type'"/>
                </template>
                <template #bottom-left>
                    <PaginationBox/>
                </template>

                <template #type="{ item, row, elData }">
                    <BaseSelectBox
                        v-if="item.data !== null"
                        v-model="item.data"
                        :options="
                            Object.entries(typesList).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                        :design="'table-select'"
                        :width="100"
                        @update="(value) => update('type', value, row)"
                    >
                        <template #option="{ option }">
                            {{ option.title }}
                        </template>
                        <template #placeholder>
                            <span class="type" :class="{ red: item.data == 2 }">
                                <BaseImage
                                    v-if="row.overrideFields.data && row.overrideFields.data.type != elData"
                                    :width="17"
                                    :height="17"
                                    :path="'target.svg'"
                                />
                                {{ typesList[elData] }}
                            </span>
                        </template>
                    </BaseSelectBox>
                </template>
                <template #callStatus="{ item, row }">
                    <span class="status" :class="{ new: item.data && item.data.toLowerCase() === 'new' }">
                        <BaseImage
                            v-if="row.overrideFields.data && row.overrideFields.data.callStatus && row.overrideFields.data.callStatus !== item.data"
                            :width="17"
                            :height="17"
                            :path="'target.svg'"
                        />
                        {{ item.data }}
                    </span>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<style scoped>
.status,
.type {
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>
