import { computed } from 'vue'

import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'

export default {
    config: () => {
        const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
        const sessionStore = SessionModel.useSessionStore()

        return {
            tableId: 'partners-brands-table',
            cols: computed(() => [
                {
                    label: '',
                    key: '-',
                    sortable: false
                },
                {
                    label: 'Name',
                    key: 'brand.brand',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'CI',
                    key: 'brand.numericId',
                    type: 'number',
                    sortable: true
                },
                {
                    label: 'Note',
                    key: 'note',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Status',
                    key: 'status',
                    type: 'boolean',
                    sortable: true
                }
            ]),
            filters: computed(() => [
                {
                    field: 'brandId',
                    placeholder: 'Select Brand',
                    type: 'multiselect',
                    data: sessionStore.brands || []
                }
            ]),
            tableActions: computed(() => [
                {
                    key: 'select',
                    value: 'Select'
                },
                {
                    key: 'delete_affiliate',
                    value: 'Delete'
                }
            ]),
            actionList: computed(() => [
                {
                    key: 'action',
                    placeholder: 'Select An Action',
                    options: [{ key: 'status', label: 'Change Status', length: 2, buttonText: 'Change' }]
                },
                {
                    key: 'status',
                    placeholder: 'Select Status',
                    options: [
                        { key: '0', label: 'Disable' },
                        { key: '1', label: 'Enable' }
                    ]
                }
            ]),
            searchByList: computed(() => [
                {
                    key: 'name',
                    value: 'Name'
                }
            ])
        }
    }
}
