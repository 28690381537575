<script setup>
import {PageTitle} from "@/shared/ui/Page"

import config from '@/pages/statuses/configs/statuses.config'
import {DataTable, PaginationBox, TableActions} from "@/features/Datatable"
import {usePageTitle} from "@/shared/lib/use/usePageTitle"
import {BaseSelectBox} from "@/shared/ui/Inputs"
import {computed} from "vue"
import {getStatuses} from "@/entities/Session/model"
import {SessionModel} from '@/entities/Session'
import {useAppStore} from "@/app/providers"

const {tableId, path, cols, searchByList, tableActions} = config.config()

const title = usePageTitle('Statuses')
const sessionStore = SessionModel.useSessionStore()
const appStore = useAppStore()

const statuses = computed(() => getStatuses(sessionStore))

const update = (action, data, row) => {
    appStore.update(
        {
            [action]: data.title,
        },
        row,
        tableId
    )
}
</script>

<template>
    <div class="statuses container">
        <PageTitle :title="'Statuses'" :icon="'page-icons/statuses.svg'"/>
        <DataTable
            :table-id="tableId"
            :path="path"
            :fields="cols"
            :option-list="tableActions"
            :order="'mappedStatus'"
            :order-type="1"
            :custom-class="'statuses'"
        >
            <template #top-right>
                <TableActions :search-list="searchByList" :actions="[]"/>
            </template>
            <template #top-left>
                <PaginationBox/>
            </template>

            <template #mappedStatus="{ item, row, elData }">
                <BaseSelectBox
                    v-model="item.data"
                    :options="
                            Object.entries(statuses).map(([key, value]) => ({
                                value: key,
                                title: value
                            }))
                        "
                    :design="'table-select'"
                    :width="150"
                    @update="(value) => update('mappedStatus', value, row)"
                >
                    <template #option="{ option }">
                        {{ option.title }}
                    </template>
                    <template #placeholder>
                            <span>
                                {{ statuses[elData] || elData || 'Nothing Selected' }}
                            </span>
                    </template>
                </BaseSelectBox>
            </template>
        </DataTable>
    </div>
</template>

<style scoped>

</style>
