import {defineStore} from 'pinia'

export const useModalStore = defineStore('modal', {
    state: () => ({
        modal: null,
        modalData: null,
        modalVisible: false,
        actionModalData: {},
        modalsStack: []
    }),
    actions: {
        setModal(data) {
            this.modalsStack.push(data)
            this.modal = data
            this.modalVisible = true
            document.body.classList.add('hidden')
        },

        setModalData(data) {
            this.modalData = data
        },

        closeModal() {
            return new Promise((resolve) => {
                if (this.modalsStack.length > 0) {
                    this.modalVisible = false
                    this.modalsStack.pop()

                    if (this.modalsStack.length > 0) {
                        this.modal = this.modalsStack[this.modalsStack.length - 1]
                        this.modalVisible = true
                    } else {
                        this.modal = null
                        document.body.classList.remove('hidden')
                    }
                } else {
                    this.modal = null
                    document.body.classList.remove('hidden')
                }
                resolve()
            })
        },

        setActionData(data) {
            this.actionModalData = data
        },

        clearActionData() {
            this.actionModalData = {}
        }
    }
})
