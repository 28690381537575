import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const getSources = (config) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.post(`sources/dt`, config)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

const getSource = (id) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.get(`source/${id}`)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

export const api = {
    getSources,
    getSource
}
