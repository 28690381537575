<script setup>
import {defineProps, ref, onMounted, computed, onUnmounted} from 'vue'
import {downloadDatatable} from '@/shared/lib/utils/table'
import {debounce} from '@/shared/lib/utils/debounce'

import {BaseImage} from '@/shared/ui/Images'
import {BaseButton} from '@/shared/ui/Buttons'
import {SearchInput} from '@/features/Search'
import {IconLoadTemplate, IconSaveTemplate, IconCustomizeTemplate} from '@/shared/ui/Icons'

import {DatatableModel} from '@/entities/Datatable'
import {ModalModel} from '@/entities/Modal'
import {TemplatesModel} from '@/entities/Templates'

import draggable from 'vuedraggable'

const datatableStore = DatatableModel.useDatatableStore()
const store = TemplatesModel.useTemplatesStore()
const modalStore = ModalModel.useModalStore()

const props = defineProps({
    filters: {
        type: Array
    },
    searchList: {
        type: Array
    },
    actions: {
        type: Array,
        default: () => ['export', 'reset', 'saveTemplate', 'customize']
    }
})

const tableId = computed(() => DatatableModel.getTableId(datatableStore))
const visibleColumns = computed(() => DatatableModel.getTableFields(datatableStore, tableId.value))
const savedTemplates = computed(() => TemplatesModel.getSavedTemplates(store, tableId.value))
const modal = computed(() => ModalModel.getCurrentModal(modalStore))
const tableData = computed(() => DatatableModel.getDatatableDataById(datatableStore, tableId.value))
const buttonIsDisable = computed(() => columns.value.length <= 1)

const columns = ref([])
const tempHiddenColumns = ref([])
const visibleAddColumnsModal = ref(false)

const resetTable = () => {
    datatableStore.resetTable()
    tempHiddenColumns.value = visibleColumns.value.filter((item) => !item.visible)
    columns.value = [...datatableStore.columns[tableId.value]]
}

const openCustomizeColumns = () => {
    tempHiddenColumns.value = [...datatableStore.columns[tableId.value].filter((col) => !col.visible)]
    columns.value = [...datatableStore.columns[tableId.value].filter((col) => col.visible)]
    visibleAddColumnsModal.value = !visibleAddColumnsModal.value
    document.body.classList.add('hidden')
}

const closeModals = () => {
    visibleAddColumnsModal.value = false
    document.body.classList.remove('hidden')
}

const apply = () => {
    columns.value.forEach((col) => (col.visible = true))
    tempHiddenColumns.value.forEach((column) => {
        column.visible = false
        columns.value.push(column)
    })
    datatableStore.setColumns(columns.value)
    datatableStore.getDatatableData()
    localStorage.setItem(`DataTables_${tableId.value}`, JSON.stringify(columns.value))
    closeModals()
}

const startSearch = (data) => {
    if (!data.value) {
        return
    }

    switch (data.key) {
        case 'email':
            data.value = data.value.trim()
            break
        default:
            break
    }

    datatableStore.setDataTableConfig([
        {
            field: data.key,
            data: data.value,
            type: 'globalFilter'
        }
    ])
}
const debouncedStartSearch = debounce(startSearch)

const addColumn = (data) => {
    const index = tempHiddenColumns.value.findIndex((column) => column === data)
    if (index !== -1) {
        tempHiddenColumns.value.splice(index, 1)
        columns.value.push(data)
    }
}

const removeColumn = (data) => {
    columns.value = columns.value.filter((item) => item.key !== data.key)
    tempHiddenColumns.value.push(data)
}

const openModal = (modal) => {
    modalStore.setModal(modal)
    visibleAddColumnsModal.value = false
}
</script>

<template>
    <div class="custom-filters-list">
        <div class="options">
            <div
                v-if="actions.includes('export') && tableData && Object.keys(tableData).length"
                class="icon"
                @click.stop="downloadDatatable(tableData, tableId)">
                <BaseImage
                    class="typo-cursor-pointer"
                    :width="16"
                    :height="18"
                    :path="'export.svg'"
                    :alt="'export'"
                    :title="'Export'"
                />
                <span>Export</span>
            </div>
            <div v-if="actions.includes('reset')" class="icon" @click.stop="resetTable()">
                <BaseImage
                    class="typo-cursor-pointer"
                    :width="16"
                    :height="19"
                    :path="'reset.svg'"
                    :alt="'reset'"
                    :title="'Reset'"
                />
                <span>Reset table default</span>
            </div>
            <div
                v-if="actions.includes('saveTemplate') && savedTemplates && Object.keys(savedTemplates).length"
                class="icon"
                @click.stop="openModal('loadTemplate')">
                <IconLoadTemplate
                    class="typo-cursor-pointer"
                    :is-active="modal === 'loadTemplate'"
                />
                <span>Load columns template</span>
            </div>
            <div
                v-if="actions.includes('saveTemplate')"
                class="icon" @click.stop="openModal('saveTemplate')">
                <IconSaveTemplate
                    class="typo-cursor-pointer"
                    :is-active="modal === 'saveTemplate'"
                />
                <span>Save columns template </span>
            </div>
            <div
                v-if="actions.includes('customize')"
                class="icon" @click.stop="openCustomizeColumns()">
                <IconCustomizeTemplate
                    class="typo-cursor-pointer"
                    :is-active="visibleAddColumnsModal"
                />
                <span>Customize columns</span>
            </div>

            <div v-if="visibleAddColumnsModal" class="add-columns-modal" @click.stop="closeModals">
                <div class="modal-content" @click.stop>
                    <div class="title">
                        <h2 class="typo-heading-h2">Customize columns</h2>
                        <BaseImage :width="11" :height="11" :path="'close-gray.svg'" @click="closeModals"/>
                    </div>
                    <div class="titles">
                        <h6 class="typo-heading-h6">Add a new column</h6>
                        <h6 class="typo-heading-h6">Drag to reorder</h6>
                    </div>
                    <div class="cols">
                        <draggable
                            v-model="tempHiddenColumns" class="drag-area" group="columns" :tag="'ul'"
                            item-key="id">
                            <template #item="{ element }">
                                <li v-if="element.label">
                                    <BaseImage :width="12" :height="17" :path="'drag.svg'"/>
                                    <span class="label">{{ element.label }}</span>
                                    <BaseImage
                                        :path="'close-gray.svg'"
                                        :width="11"
                                        :height="11"
                                        @click.stop="addColumn(element)"
                                    />
                                </li>
                            </template>
                        </draggable>
                        <draggable v-model="columns" class="drag-area" group="columns" :tag="'ul'" item-key="id">
                            <template #item="{ element }">
                                <li v-if="element.label">
                                    <BaseImage :width="12" :height="17" :path="'drag.svg'"/>
                                    <span class="label">{{ element.label }}</span>
                                    <BaseImage
                                        :path="'close-gray.svg'"
                                        :width="11"
                                        :height="11"
                                        @click.stop="removeColumn(element)"
                                    />
                                </li>
                            </template>
                        </draggable>
                    </div>
                    <BaseButton
                        :text="'Apply'"
                        :disable="buttonIsDisable"
                        :height="40"
                        :design="buttonIsDisable ? 'disable' : 'fill'"
                        @click.stop="apply()"
                    />
                </div>
            </div>
        </div>
        <SearchInput :search-list="searchList" @search="debouncedStartSearch"/>
    </div>
</template>

<style scoped>
.custom-filters-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    color: var(--white-086);
    font-size: 12px;
    cursor: pointer;
}

.icon span {
    max-width: 55px;
    margin: 0 auto;
    text-align: center;
    word-wrap: break-word;
}

.options {
    display: flex;
    align-items: baseline;
    gap: 20px;
    margin: 0 20px;
    position: relative;
}

.add-columns-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.modal-content {
    padding: 27px;
    background: var(--background);
    border: var(--general-border) solid var(--purple);
    border-radius: var(--general-border-radius);
    height: 650px;
    width: 550px;
    overflow: hidden;
    display: grid;
    grid-template-rows: max-content max-content 1fr max-content;
    z-index: 10;
    text-transform: capitalize;
}

.modal-content .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.modal-content .title img {
    cursor: pointer;
}

.modal-content h2 {
    text-align: center;
    padding: 9px 0;
    font-weight: 400;
}

.modal-content .cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    overflow: hidden;
}

.modal-content h6 {
    padding-bottom: 5px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--purple);
    text-align: center;
    color: var(--white-086);
}

.modal-content button {
    margin: 16px 0 0 auto;
    display: block;
}

.modal-content ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow-y: auto;
    padding: 0 5px 0 0;
}

.modal-content .label {
    width: 100%;
    margin-left: 10px;
}

.modal-content ul li {
    width: 100%;
    border: 0;
    background-color: var(--select-bg);
    color: var(--white-086);
    border-radius: var(--general-border-radius);
    padding: 12px 10px;
    display: grid;
    grid-template-columns: 12px 1fr 11px;
    place-items: center;
    cursor: pointer;
}

.drag-area {
    height: 100%;
}

.titles {
    display: flex;
    align-items: center;
    gap: 15px;
}

.titles h6 {
    width: 50%;
}

@media (max-width: 1000px) {
    .modal-content {
        width: auto;
        height: 500px;
    }

    .search-input {
        display: none;
    }

    .modal-content h2 {
        font-size: 18px;
    }

    .modal-content .label {
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .modal-content ul {
        padding: 0;
    }

    .modal-content .cols {
        gap: 8px;
    }

    .modal-content ul li {
        font-size: 12px;
    }

    .modal-content .button-box.fill {
        width: 100px !important;
    }
}
</style>
