export const verticalsList = {
    Adult: 'Adult',
    Automotive: 'Automotive',
    'Biz Opp': 'Biz Opp',
    Dating: 'Dating',
    'Download / Software': 'Download / Software',
    Education: 'Education',
    Entertainment: 'Entertainment',
    Fashion: 'Fashion',
    Forex: 'Forex',
    Games: 'Games',
    'Health & Beauty': 'Health & Beauty',
    'Home Improvements': 'Home Improvements',
    Insurance: 'Insurance',
    Legal: 'Legal',
    Lifestyle: 'Lifestyle',
    Music: 'Music',
    'Real Estate / Mortgage': 'Real Estate / Mortgage',
    Shopping: 'Shopping',
    Sports: 'Sports',
    Sweepstakes: 'Sweepstakes',
    Travel: 'Travel',
    Utilities: 'Utilities',
    Immigration: 'Immigration',
    'E-Commerce': 'E-Commerce',
    'E-Gaming': 'E-Gaming',
    'Dental Care': 'Dental Care',
    'Hair Transplants': 'Hair Transplants',
    'Plastic Surgery': 'Plastic Surgery',
    'Aesthetic Treatment': 'Aesthetic Treatment',
    'Affiliates Sign Ups': 'Affiliates Sign Ups',
    Banking: 'Banking',
    Cannabis: 'Cannabis',
    CBD: 'CBD',
    Claims: 'Claims',
    Crypto: 'Crypto',
    'Diet/Weight Loss': 'Diet/Weight Loss',
    Donations: 'Donations',
    Energy: 'Energy',
    Fin: 'Fin',
    'Finance/Financial': 'Finance/Financial',
    'Home Garden': 'Home Garden',
    Jobs: 'Jobs',
    'Loans & Mortgages': 'Loans & Mortgages',
    Lottery: 'Lottery',
    NFT: 'NFT',
    Nutra: 'Nutra',
    Pets: 'Pets',
    Survey: 'Survey'
}
