import {defineStore} from 'pinia'
import {DatatableModel} from '@/entities/Datatable'
import {SourcesModel} from '@/entities/Sources'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {SessionModel} from '@/entities/Session'
import {ModalModel} from '@/entities/Modal'

import RestService from '@/shared/api/rest.service'

import {getTableBySingleRequest, getTableByRequest, getIdsField} from '@/shared/lib/utils/table'

export const useAppStore = defineStore('app', {
    state: () => ({
        requestIsPending: false
    }),
    actions: {
        massUpdate(data) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const datatableStore = DatatableModel.useDatatableStore()

            const {action, option} = data
            const id = this.$router.currentRoute.value.params.id
            const table = getTableByRequest(data.table)
            const idsField = getIdsField(table)

            let url
            switch (table) {
                case 'partners-brands':
                    url = `brand/${data.rows[0].brandId.data}/affiliates`
                    break
                case 'affiliate':
                    url = `brand/${id}/affiliates`
                    break
                default:
                    url = `${table}`
                    break
            }

            datatableStore.isEditLoaded = true

            return new Promise((resolve, reject) => {
                RestService.put(url, {
                    [option]: action,
                    [idsField]: data.ids
                })
                    .then(() => {
                        for (const item in data.rows) {
                            if (data.rows[item][option]) {
                                data.rows[item][option].data = action
                            }
                        }
                        resolve()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                    })
                    .finally(() => {
                        datatableStore.isEditLoaded = false
                    })
            })
        },

        massDelete(data) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const datatableStore = DatatableModel.useDatatableStore()

            let table = getTableByRequest(data.table)
            let idsField = getIdsField(table)
            const id = this.$router.currentRoute.value.params.id

            let url

            switch (table) {
                case 'affiliate':
                case 'partners-brands':
                    url = `brand/${id}/affiliates/delete`
                    break
                default:
                    url = `${table}/delete`
                    break
            }

            datatableStore.isEditLoaded = true

            return new Promise((resolve, reject) => {
                RestService.post(url, {
                    [idsField]: data.ids
                })
                    .then(() => {
                        datatableStore.getDatatableData()
                        resolve()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                    })
                    .finally(() => {
                        datatableStore.isEditLoaded = false
                    })
            })
        },

        update(data, row, table) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            const datatableStore = DatatableModel.useDatatableStore()
            const pageId = this.$router.currentRoute.value.params.id
            const updatedData = {}

            let single = getTableBySingleRequest(table)

            return new Promise((resolve, reject) => {
                let updatePromise
                switch (single) {
                    case 'partners-brands':
                        for (const key in data) {
                            const dataArray = key.split('.')
                            const convertedKey = dataArray[dataArray.length - 1]

                            updatedData[convertedKey] = data[key]
                        }

                        datatableStore.isEditLoaded = true
                        updatePromise = RestService.put(
                            `brand/${row['brandId'].data}/affiliates/${row['id'].data}`,
                            updatedData
                        )
                            .then(() => {
                                for (const item in data) {
                                    if (row[item] !== undefined) {
                                        row[item].data = data[item]
                                    }
                                }
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                        break
                    case 'affiliate':
                        datatableStore.isEditLoaded = true
                        updatePromise = RestService.put(`brand/${row['brandId'].data}/affiliates/${row['id'].data}`, data)
                            .then(() => {
                                for (const item in data) {
                                    if (row[item] !== undefined) {
                                        row[item].data = data[item]
                                    }
                                }
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                        break
                    case 'source':
                        updatePromise = RestService.put(`${single}/${row['id']}`, data)
                            .then(() => {
                                for (const item in data) {
                                    if (row[item]) {
                                        row[item] = data[item]
                                    }
                                }
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    default:
                        datatableStore.isEditLoaded = true
                        updatePromise = RestService.put(`${single}/${row['id'].data}`, data)
                            .then(() => {
                                for (const item in data) {
                                    if (row[item]) {
                                        row[item].data = data[item]
                                    }
                                }
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                        break
                }
            })
        },

        delete(row, table) {
            const datatableStore = DatatableModel.useDatatableStore()
            const store = SourcesModel.useSourcesStore()

            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            let single = getTableBySingleRequest(table)

            return new Promise((resolve, reject) => {
                let deletePromise
                switch (single) {
                    case 'row':
                        datatableStore.isEditLoaded = true
                        deletePromise = RestService.delete(`${single}/${row.id.data}`)
                            .then(() => datatableStore.getDatatableData())
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                            .then(() => resolve())
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    case 'source':
                        deletePromise = RestService.delete(`${single}/${row.id}`)
                            .then(() => {
                                store.getSources().then(() => {
                                    let favorites = JSON.parse(localStorage.getItem('sources-favorite') || '[]')
                                    const index = favorites.findIndex(item => item.id === row.id)
                                    if (index !== -1) {
                                        store.removeFromFavorites(index)
                                    }
                                })
                            })
                            .then(() => resolve())
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    case 'affiliate':
                        datatableStore.isEditLoaded = true
                        deletePromise = RestService.delete(`brand/${row['brandId'].data}/affiliates/${row['id'].data}`)
                            .then(() => datatableStore.getDatatableData())
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                            .then(() => resolve())
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    case 'partners-brands':
                        datatableStore.isEditLoaded = true
                        deletePromise = RestService.delete(`brand/${row['brandId'].data}/affiliates/${row['id'].data}`)
                            .then(() => datatableStore.getDatatableData())
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                            .then(() => resolve())
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    default:
                        datatableStore.isEditLoaded = true
                        deletePromise = RestService.delete(`${single}/${row['id'].data}`)
                            .then(() => {
                                datatableStore.getDatatableData()
                                switch (single) {
                                    case 'user':
                                        this.getList("users").then()
                                        return
                                    case 'partner':
                                        this.getList("partners").then()
                                        return
                                    case 'brand':
                                        this.getList("brands").then()
                                        return
                                    default:
                                        return;
                                }
                            })
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                            .then(() => resolve())
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                }
            })
        },

        duplicate(id, table) {
            const datatableStore = DatatableModel.useDatatableStore()
            const store = SourcesModel.useSourcesStore()
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

            let single = getTableBySingleRequest(table)

            return new Promise((resolve, reject) => {
                let duplicatePromise
                switch (single) {
                    case 'source':
                        duplicatePromise = RestService.post(`${single}/${id}/duplicate`)
                            .then(() => {
                                store.getSources()
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                        break
                    default:
                        datatableStore.isEditLoaded = true
                        duplicatePromise = RestService.post(`${single}/${id}/duplicate`)
                            .then(() => {
                                datatableStore
                                    .getDatatableData()
                                    .then(() => {
                                        resolve()
                                    })
                                    .catch((errorObject) => {
                                        generalNotificationsStore.showError(errorObject)
                                    })
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                datatableStore.isEditLoaded = false
                            })
                        break
                }
            })
        },

        create(data, path, id = '') {
            const {closeModal} = ModalModel.useModalStore()
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sourcesStore = SourcesModel.useSourcesStore()

            const datatableStore = DatatableModel.useDatatableStore()

            if (!id) {
                id = path
            }

            return new Promise((resolve, reject) => {
                const postPromise = RestService.post(`${path}`, {...data})
                this.requestIsPending = true

                switch (id) {
                    case 'sources':
                        postPromise
                            .then(() => {
                                closeModal()
                                sourcesStore.getSources()
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                this.requestIsPending = false
                                this.getList('sources')
                            })
                        return
                    default:
                        postPromise
                            .then(() => {
                                closeModal()
                                datatableStore.isEditLoaded = true
                                datatableStore
                                    .getDatatableData()
                                    .then(() => resolve())
                                    .catch((errorObject) => {
                                        generalNotificationsStore.showError(errorObject)
                                        reject(errorObject)
                                    })
                                    .finally(() => {
                                        datatableStore.isEditLoaded = false
                                    })
                                resolve()
                            })
                            .catch((errorObject) => {
                                generalNotificationsStore.showError(errorObject)
                            })
                            .finally(() => {
                                this.requestIsPending = false
                            })
                        return
                }
            })
        },

        getList(from) {
            const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
            const sessionStore = SessionModel.useSessionStore()

            return new Promise((resolve, reject) => {
                RestService.get(`${from}/list`)
                    .then((ans) => {
                        if (ans) {
                            let data = ans.data.data
                            switch (from) {
                                case 'brands':
                                    sessionStore.setBrands(data)
                                    break
                                case 'sources':
                                    sessionStore.setSources(data)
                                    break
                                case 'partners':
                                    sessionStore.setPartners(data)
                                    break
                                case 'permissions':
                                    sessionStore.setPermissions(data)
                                    break
                                case 'connectors':
                                    sessionStore.setConnectors(data)
                                    break
                                case 'users':
                                    sessionStore.setUsers(data)
                                    break
                                case 'statuses':
                                    sessionStore.setStatuses(data)
                                    break
                                default:
                                    break
                            }
                        }
                        resolve()
                    })
                    .catch((errorObject) => {
                        generalNotificationsStore.showError(errorObject)
                        reject(errorObject)
                    })
            })
        }
    }
})
