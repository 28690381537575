<script setup>
import {ref, computed} from 'vue'
import {ModalModel} from '@/entities/Modal'
import {DatatableModel} from '@/entities/Datatable'
import {useAppStore} from '@/app/providers'
import {typesList} from '@/shared/data'

import {BaseButton} from '@/shared/ui/Buttons'
import {BaseInput} from '@/shared/ui/Inputs'
import {BaseWarningBox} from '@/shared/ui/Warnings'
import {TemplatesModel} from '@/entities/Templates'
import {BaseModal} from '@/shared/ui/Modal'

const confirmation = ref('')
const inputData = ref('')
const errorMessage = ref(false)

const modalStore = ModalModel.useModalStore()
const datatableStore = DatatableModel.useDatatableStore()
const appStore = useAppStore()
const store = TemplatesModel.useTemplatesStore()

const actionData = computed(() => modalStore.actionModalData)

const getConfirmationTitle = () => {
    switch (actionData.value.option) {
        case 'callStatus':
            return `Update Lead Status`
        case 'status':
            return `Update Status`
        case 'type':
            return `Mark as ${typesList[actionData.value.action]}`
        case 'note':
            return `Update Note`
        case 'delete_template':
            return `Delete Template`
        case 'delete_lead':
            return `Delete Lead`
        case 'delete_user':
            return `Delete User`
        case 'delete_row':
            return `Delete Row`
        case 'delete_mass_leads':
            return `Delete Lead(s)`
        case 'delete_brand':
            return 'Delete Brand'
        case 'delete_mass_brands':
            return 'Delete Brand(s)'
        case 'delete_source':
            return 'Delete Source'
        case 'delete_mass_sources':
            return 'Delete Source(s)'
        case 'delete_partner':
            return 'Delete Partner'
        case 'delete_mass_partners':
            return 'Delete Partner(s)'
        case 'delete_mass_users':
            return 'Delete User(s)'
        case 'delete_mass_rows':
            return 'Delete Row(s)'
        case 'duplicate_brand':
            return 'Duplicate brand'
        case 'Duplicate partners':
            return 'Duplicate brand'
        case 'duplicate_source':
            return 'Duplicate source'
        case 'block_duplicate_email':
            return 'Block duplicate e-mail'
        case 'block_duplicate_phone':
            return 'Block duplicate phone'
        case 'block_duplicate_ip':
            return 'Block duplicate IP'
        default:
            return ''
    }
}

const getConfirmation = () => {
    switch (actionData.value.option.split('_')[0]) {
        case 'callStatus':
            confirmation.value = 'UPDATE'
            return confirmation.value
        case 'type':
            confirmation.value = typesList[actionData.value.action].toUpperCase()
            return confirmation.value
        case 'note':
            confirmation.value = actionData.value.option.toUpperCase()
            return actionData.value.option.toUpperCase()
        case 'delete':
            confirmation.value = 'DELETE'
            return confirmation.value
        case 'duplicate_brand':
        case 'duplicate_source':
        case 'duplicate':
            confirmation.value = 'DUPLICATE'
            return confirmation.value
        default:
            confirmation.value = 'CHANGE'
            return confirmation.value
    }
}

const getButtonText = () => {
    if (!actionData.value.option) {
        return
    }

    switch (actionData.value.option.split('_')[0]) {
        case 'delete':
            return 'Delete'
        default:
            return 'Confirm'
    }
}

const sendRequest = () => {
    if (confirmation.value !== inputData.value) {
        errorMessage.value = true
        return
    }

    modalStore.closeModal()

    switch (actionData.value.option) {
        case 'delete_mass_leads':
        case 'delete_mass_brands':
        case 'delete_mass_sources':
        case 'delete_mass_partners':
        case 'delete_mass_affiliate':
        case 'delete_mass_users':
        case 'delete_mass_rows':
            appStore.massDelete(actionData.value)
                .then(() => {
                    showSuccessModal()
                })
            break
        case 'delete_source':
            appStore.delete(actionData.value, actionData.value.table)
                .then(() => {
                    showSuccessModal()
                })
            break
        case 'delete_lead':
        case 'delete_brand':
        case 'delete_affiliate':
        case 'delete_partner':
        case 'delete_status':
        case 'delete_user':
        case 'delete_row':
            appStore.delete(actionData.value.row, actionData.value.table)
                .then(() => {
                    showSuccessModal()
                })
            break
        case 'duplicate_brand':
        case 'duplicate_source':
        case 'duplicate':
            appStore.duplicate(actionData.value.id, actionData.value.table)
                .then(() => {
                    showSuccessModal()
                })
            break
        case 'delete_template':
            store.removeTemplate(actionData.value.data)
            break
        default:
            appStore.massUpdate(actionData.value)
                .then(() => {
                    showSuccessModal()
                })
            break
    }

    datatableStore.setSelectMode(false)
    datatableStore.canselSelectMode()
}

const showSuccessModal = () => {
    modalStore.setModal('successful')
    modalStore.setModalData(actionData.value)
    modalStore.clearActionData()
}

const close = () => {
    modalStore.clearActionData()
    modalStore.closeModal()
}

const getConfirmationDescription = () => {
    if (!actionData.value.table) {
        return false
    }

    switch (actionData.value.table) {
        case 'leads-table':
            switch (actionData.value.option) {
                default:
                    return `${actionData.value.ids ? `You are about to mark ${actionData.value.ids.length} Lead’s Status` : ''} `
            }
        case 'brands-table':
            switch (actionData.value.option) {
                default:
                    return `${actionData.value.ids ? `You are about to mark ${actionData.value.ids.length} Brands’s Status` : ''}`
            }
        default:
            return false
    }
}

const getConfirmationMessage = () => {
    switch (actionData.value.option) {
        case 'delete_template':
            return `Are you sure you want to delete this template?`
        case 'delete_partner':
            return `Type <span class="light-red">${getConfirmation()}</span> to perform this action`
        default:
            return `Type <span class="${getConfirmation().toLowerCase() === 'delete' ? 'light-red' : 'light-purple'}"> ${getConfirmation()} </span> to perform this action`
    }
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">{{ getConfirmationTitle() }}</h4>
        </template>
        <template #content>
            <div
                class="action-modal"
                :class="{ delete: actionData.option && actionData.option.split('_')[0] === 'delete' }">
                <div
                    v-if="getConfirmationDescription()"
                    class="confirmation-desc mob-tablet-h7"
                    v-html="getConfirmationDescription()"
                ></div>
                <div v-if="getConfirmationMessage" class="confirmation-message" v-html="getConfirmationMessage()"></div>
                <BaseInput
                    v-if="confirmation"
                    v-model="inputData"
                    class="mob-tablet-h7"
                    :placeholder="`Type ${getConfirmation()} here to confirm`"
                    @keypress="errorMessage = false"
                />
                <BaseWarningBox
                    v-if="errorMessage" class="error-message mob-tablet-h9"
                    :text="'Incorrect type to perform this action'" :type="'error'"/>
            </div>
        </template>
        <template #action-buttons>
            <BaseButton :width="100" :height="40" :text="'Cancel'" :design="'outline'" @click="close()"/>
            <BaseButton :width="100" :height="40" :text="getButtonText()" @click="sendRequest()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 0 24px;
}

.action-modal {
    width: 100%;
}

.confirmation-message {
    margin: 0 0 16px;
    max-width: 250px;
}

h4 {
    padding: 9px 0;
}

.options button:first-child {
    background: transparent;
    border: var(--general-border) solid var(--white-08);
}

p {
    color: var(--primary-gray);
}

.input-box {
    width: 100%;
}

.action-modal.delete {
    border: none;
}

.action-modal.delete h3 {
    color: var(--light-red);
}

.action-modal.delete button.fill {
    background: var(--light-red);
}

.action-modal.delete .accent {
    color: var(--light-red);
}

.sub-desc {
    padding: 8px 0;
}

.error-message {
    margin-top: 4px;
}
</style>
