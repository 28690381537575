<script setup>
import { DatatableModel } from '@/entities/Datatable'
import { SessionModel } from '@/entities/Session'

import { ref, defineProps, watch, onMounted } from 'vue'

const props = defineProps({
    filters: {
        type: Array
    },
    field: {
        type: String,
        default: '',
        require: true
    },
    type: {
        type: String,
        default: 'array'
    }
})

onMounted(() => {
    selectedValues.value = localStorage.getItem('leadTypes') ? JSON.parse(localStorage.getItem('leadTypes')) : []
})

const datatableStore = DatatableModel.useDatatableStore()
const sessionStore = SessionModel.useSessionStore()

const selectedValues = ref([])

const chooseElement = (value) => {
    sessionStore.setSelectToReset(props.field)
    const index = selectedValues.value.indexOf(value)

    if (index === -1 && selectedValues.value.length < 3) {
        selectedValues.value.push(value)
    } else {
        selectedValues.value.splice(index, 1)
    }

    datatableStore.setDataTableConfig([
        {
            data: selectedValues.value,
            field: props.field,
            type: props.type
        }
    ])
}

const isSelected = (value) => {
    if (!Object.keys(selectedValues.value).length) {
        return
    }

    return selectedValues.value.includes(value)
}

const clearFilters = () => {
    selectedValues.value = []
}

watch(
    () => sessionStore.clearFilters,
    () => {
        clearFilters()
    }
)

watch(
    () => sessionStore.resetSelectingFilterList,
    () => {
        clearFilters()
    }
)
</script>

<template>
    <div class="filter-list">
        <p>Filters:</p>
        <ul>
            <li
                v-for="(item, i) in filters"
                :key="i"
                :class="{ active: isSelected(item.field) }"
                @click.stop="chooseElement(item.field)"
            >
                {{ item.title }}
            </li>
        </ul>
    </div>
</template>

<style scoped>
.filter-list {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--white-086);
}

ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

ul li {
    padding: 8px 12px;
    border-radius: var(--general-border-radius);
    border: var(--general-border) solid var(--white-086);
    position: relative;
    cursor: pointer;
}

ul li.active {
    background: var(--gradient-button);
    color: var(--white);
    border: var(--general-border) solid var(--purple);
}

ul li.not-saved::after {
    content: '*';
    position: absolute;
    top: -7px;
    right: 0;
    font-size: 21px;
}

ul.not-saved li::after {
    content: '*';
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 1000px) {
    .filter-list {
        flex-direction: column;
        align-items: baseline;
        gap: 8px;
    }
}
</style>
