export const trafficSourceList = {
    Email: 'Email',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    Youtube: 'Youtube',
    'Social Media': 'Social Media',
    'SEO/Blogging': 'SEO/Blogging',
    Display: 'Display',
    Other: 'Other'
}
