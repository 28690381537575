<script setup>
import {computed, ref} from 'vue'

import {verticalsList} from '@/shared/data'

import {ModalModel} from '@/entities/Modal'
import {useAppStore} from '@/app/providers'

import {BaseCheckBox, BaseInput, BaseSelectBox, BaseTextarea} from '@/shared/ui/Inputs'
import {BaseButton} from '@/shared/ui/Buttons'
import {BaseWarningBox} from '@/shared/ui/Warnings'
import {checkOnRequiredFields, isValidEmail} from '@/shared/lib/utils/validation'
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'
import {BaseModal} from '@/shared/ui/Modal'
import {BaseBorderPanel} from '@/shared/ui/Panels'

const modalStore = ModalModel.useModalStore()
const appStore = useAppStore()
const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

const requestIsPending = computed(() => appStore.requestIsPending)

const email = ref('')
const firstname = ref('')
const lastname = ref('')
const phone = ref('')
const telegram = ref('')
const ip = ref('')
const vertical = ref('')
const note = ref('')
const password = ref('')
const confirmPassword = ref('')
const terms = ref(false)

const warningMessage = ref('')

const close = () => {
    modalStore.closeModal()
}

const requireFields = ref({
    email: {data: email, error: false, type: 'email'},
    firstname: {data: firstname, error: false},
    lastname: {data: lastname, error: false},
    password: {data: password, error: false},
    confirmPassword: {data: confirmPassword, error: false}
})

const create = () => {
    if (!checkOnRequiredFields(requireFields.value)) {
        generalNotificationsStore.showError({
            message: 'Fill all required fields.'
        })
        return
    }

    if (!isValidEmail(email.value)) {
        generalNotificationsStore.showError({
            message: 'Invalid Email'
        })
        requireFields.value['email'].error = true
        return
    }

    appStore.create(
        {
            email: email.value,
            firstname: firstname.value,
            lastname: lastname.value,
            phone: phone.value,
            telegram: telegram.value,
            ip: ip.value,
            vertical: vertical.value,
            password: password.value
        },
        'partners'
    ).then(() => {
        appStore.getList('partners')
    })
}
</script>

<template>
    <BaseModal>
        <template #title>
            <h4 class="typo-heading-h4">Create New Partner</h4>
        </template>
        <template #content>
            <div class="create-partner">
                <BaseBorderPanel>
                    <template #title>
                        <h5 class="mob-tablet">General information*</h5>
                    </template>
                    <template #content>
                        <div class="items">
                            <BaseInput
                                v-model="email" :error="requireFields['email'].error"
                                :placeholder="'Enter email'">
                                <template #label>
                                    <label>Email*</label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="firstname" :error="requireFields['firstname'].error"
                                :placeholder="'Enter first name'">
                                <template #label>
                                    <label>First name*</label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="lastname" :error="requireFields['lastname'].error"
                                :placeholder="'Enter last name'">
                                <template #label>
                                    <label>Last name*</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="phone" :type="'number'" :placeholder="'Enter phone number'">
                                <template #label>
                                    <label>Phone Number </label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="telegram" :placeholder="'Enter telegram'">
                                <template #label>
                                    <label>Telegram</label>
                                </template>
                            </BaseInput>
                            <BaseInput v-model="ip" :type="'number'" :placeholder="'Enter IP'">
                                <template #label>
                                    <label>IP </label>
                                </template>
                            </BaseInput>
                            <BaseSelectBox
                                v-model="vertical"
                                :options="
                                    Object.entries(verticalsList).map(([key, value]) => ({
                                        value: key,
                                        title: value
                                    }))
                                "
                                :placeholder="verticalsList[vertical] || 'Select vertical'"
                            >
                                <template #label>
                                    <label>Verticals</label>
                                </template>
                            </BaseSelectBox>
                            <BaseTextarea
                                v-model="note"
                                :height="90"
                                :label="'Note'"
                                :placeholder="'Enter the description'"
                            />
                        </div>
                    </template>
                </BaseBorderPanel>
                <BaseBorderPanel>
                    <template #title>
                        <h5 class="mob-tablet">Password</h5>
                    </template>
                    <template #content>
                        <div class="items">
                            <BaseInput
                                v-model="password"
                                :error="requireFields['password'].error"
                                :type="'password'"
                                :placeholder="'Enter your password'"
                            >
                                <template #label>
                                    <label>Password*</label>
                                </template>
                            </BaseInput>
                            <BaseInput
                                v-model="confirmPassword"
                                :error="requireFields['confirmPassword'].error"
                                :type="'password'"
                                :placeholder="'Confirm password'"
                            >
                                <template #label>
                                    <label>Confirm password*</label>
                                </template>
                            </BaseInput>
                        </div>
                    </template>
                </BaseBorderPanel>

                <BaseWarningBox :type="'error'" :text="warningMessage"/>
            </div>
            <div class="terms">
                <div class="check-box-container">
                    <BaseCheckBox
                        :value="terms"
                        :checked="terms"
                        @checked="value => terms = !value"
                    />
                </div>
                <p class="term-desc">
                    By clicking Sing up, you agree to our Terms of Service and Privacy policy
                </p>
            </div>
        </template>
        <template #action-buttons>
            <span class="empty"></span>
            <BaseButton :text="'Create'" :load="requestIsPending" @click="create()"/>
        </template>
    </BaseModal>
</template>

<style scoped>
.modal-box {
    padding: 130px 24px 0;
}

.create-partner {
    background-color: var(--background);
    border-radius: var(--general-border-radius);
    padding-top: 16px;
}

h3 {
    margin-bottom: 10px;
}

.general h3,
.partner h3 {
    margin-bottom: 25px;
}

.items {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(4, 255px);
    gap: 20px;
}

.check-box-container {
    padding: 8px;
}

.terms {
    display: flex;
    align-items: center;
}

.term-desc {
    font-size: 12px;
}

button {
    color: var(--white);
    font-size: 16px;
}

@media (max-width: 1100px) {
    .items {
        grid-template-columns: 1fr;
    }

    .create-brand {
        width: auto;
    }

    .empty {
        display: none;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    button {
        width: 100% !important;
    }
}
</style>
