import { SessionModel } from '@/entities/Session'
import { GeneralNotificationsModel } from '@/entities/GeneralNotifications'
import RestService from '@/shared/api/rest.service'

const getSelf = () => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.get(`user/self`)
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}
const selfUpdate = (data) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()
    const sessionStore = SessionModel.useSessionStore()

    return new Promise((resolve, reject) => {
        RestService.put(`user/self`, { ...data.data })
            .then((ans) => {
                if (ans) {
                    resolve(ans)
                }
            })
            .catch((errorObject) => {
                generalNotificationsStore.showError(errorObject)
            })
    })
}

export const api = {
    getSelf,
    selfUpdate
}
