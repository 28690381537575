import {markRaw} from "vue"
import {TabLeadCreationTest, TabPullTest} from "@/pages/intergration-test/tabs"

export default {
    config: () => {
        return {
            tabs: markRaw([{
                    key: 'leadCreationTest',
                    value: 'Lead Creation Test',
                    component: TabLeadCreationTest
                },
                {
                    key: 'pullTest',
                    value: 'Pull Test',
                    component: TabPullTest
                }])
        }
    }
}
