import { defineStore } from 'pinia'

export const useAffiliatesStore = defineStore('affiliates', {
    state: () => ({
        affiliate: null
    }),
    actions: {
        setAffiliate(data) {
            this.affiliate = data
        }
    }
})
